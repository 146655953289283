import constants from '@/constants'
import helpers from '@/logic/helpers'

function setScreenState(store) {
  const windowWidth = window.innerWidth
  const screenSize = {
    lt: {
      smartphone: windowWidth < constants.DEVICE_SIZES.SMARTPHONE,
      tabletMini: windowWidth < constants.DEVICE_SIZES.TABLET_MINI,
      tabletPortrait: windowWidth < constants.DEVICE_SIZES.TABLET_PORTRAIT,
      tableLandscape: windowWidth < constants.DEVICE_SIZES.TABLET_LANDSCAPE,
      desktopSmall: windowWidth < constants.DEVICE_SIZES.DESKTOP_SMALL,
      desktopLarge: windowWidth < constants.DEVICE_SIZES.DESKTOP_LARGE,
      desktopXlarge: windowWidth < constants.DEVICE_SIZES.DESKTOP_XLARGE,
      desktopXxlarge: windowWidth < constants.DEVICE_SIZES.DESKTOP_XXLARGE,
    },
    gt: {
      smartphone: windowWidth > constants.DEVICE_SIZES.SMARTPHONE,
      tabletMini: windowWidth > constants.DEVICE_SIZES.TABLET_MINI,
      tabletPortrait: windowWidth > constants.DEVICE_SIZES.TABLET_PORTRAIT,
      tableLandscape: windowWidth > constants.DEVICE_SIZES.TABLET_LANDSCAPE,
      desktopSmall: windowWidth > constants.DEVICE_SIZES.DESKTOP_SMALL,
      desktopLarge: windowWidth > constants.DEVICE_SIZES.DESKTOP_LARGE,
      desktopXlarge: windowWidth > constants.DEVICE_SIZES.DESKTOP_XLARGE,
      desktopXxlarge: windowWidth > constants.DEVICE_SIZES.DESKTOP_XXLARGE,
    },
  }

  store.commit('setScreenSize', screenSize)
}

export function updateScreenSize(store) {
  setScreenState(store)

  window.addEventListener('resize', helpers.debounce(() => {
    setScreenState(store)
  }, 100))
}
