import learningmaterials from '@/logic/learningmaterials'
import LearningmaterialsAPI from '@/modules/learningmaterials/api/learningmaterials'

export default {
  updateLearningmaterials({ commit }) {
    return LearningmaterialsAPI.getLearningmaterials().then((folders) => {
      folders.forEach((folder) => {
        folder.materials.map((material) => learningmaterials.mapMaterial(material))
      })
      commit('setLearningmaterialFolders', folders)
    })
  },
  findLearningmaterial({ commit }, id) {
    return LearningmaterialsAPI.findLearningmaterial(id).then((learningmaterial) => {
      commit('setLearningmaterial', learningmaterials.mapMaterial(learningmaterial))
    })
  },
  searchLearningmaterials({ commit }, { search, folderId = null }) {
    return LearningmaterialsAPI.searchLearningmaterials(search, folderId).then((materials) => {
      commit('setFoundLearningmaterials', materials)
    })
  },
  getWBTEvent({ commit }, id) {
    return LearningmaterialsAPI.getWBTEvent(id).then((data) => {
      commit('setWBTEvent', {
        learningmaterialId: id,
        events: data.events,
      })
    })
  },
  updateWBTEvents({ commit }) {
    return LearningmaterialsAPI.getAllWBTEvents().then((data) => {
      commit('setWBTEvents', data.events)
    })
  },
}
