<template>
  <div
    v-if="visibleUpcomingAppointments && visibleUpcomingAppointments.length"
    class="c-sidebarEntry"
    data-cy="AppointmentSidebar">
    <div
      class="t-body3 t-fontWeightMedium u-marginBottom4"
      data-cy="AppointmentSidebar__title">
      {{ $t('appointments.next_appointments') }}
    </div>
    <router-link
      v-for="appointment in visibleUpcomingAppointments"
      :key="appointment.id"
      :to="appointment.route || {
        name: 'appointments.details',
        params: {
          appointmentId: appointment.id,
        },
      }"
      class="s-appointmentEntry">
      <AppointmentEntry
        :appointment="appointment"
        minimal />
    </router-link>
    <router-link
      :to="{ name: 'appointments.index.upcoming' }"
      class="t-noLinkAppearance t-caption1 t-fontWeightMedium u-colorPrimary u-marginTop4"
      data-cy="AppointmentEntry__openAppointments">
      {{ $t('appointments.see_all') }}
      ({{ upcomingAppointmentCount }})
      <SvgIcon
        type="chevron_right"
        tiny />
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import AppointmentEntry from '@/modules/appointments/components/AppointmentEntry.vue'

export default {
  computed: {
    ...mapGetters({
      upcomingAppointments: 'appointments/upcomingAppointments',
    }),
    upcomingAppointmentCount() {
      if (!this.upcomingAppointments) {
        return null
      }
      return this.upcomingAppointments.length
    },
    visibleUpcomingAppointments() {
      // next appointments, up to 3
      if (!this.upcomingAppointments) {
        return null
      }
      return this.upcomingAppointments
        .filter((appointment) => !appointment?.resolved)
        .slice(0, 3)
    },
  },
  components: {
    AppointmentEntry,
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/config';

.s-appointmentEntry {
  border-bottom: 1px solid $color-soft_highlight;
  display: block;
  margin-bottom: $spacing-4;
  padding-bottom: $spacing-4;
  text-decoration: none;
}
</style>
