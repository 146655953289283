import Vue from 'vue'

export default {
  setConfig(state, config) {
    Vue.set(state, 'config', config)
  },
  login(state, userData) {
    state.token = userData.token
    const user = {
      avatar: userData.avatar,
      email: userData.email,
      displayname: userData.displayname,
      id: userData.id,
      is_admin: userData.is_admin,
      name: userData.name,
      tmpAccount: userData.isTmpAccount,
      tos_accepted: userData.tos_accepted,
    }
    if (typeof userData.active !== 'undefined' && !userData.active) {
      user.active = false
    } else {
      user.active = true
    }
    Vue.set(state, 'user', user)
  },
  setToken(state, token) {
    state.token = token
  },
  setProfileData(state, data) {
    if (typeof state.user === 'undefined' || !state.user) {
      Vue.set(state, 'user', {})
    }
    Vue.set(state.user, 'id', data.id)
    Vue.set(state.user, 'name', data.name)
    Vue.set(state.user, 'tos_accepted', data.tos_accepted)
    Vue.set(state.user, 'email', data.email)
    Vue.set(state.user, 'avatar', data.avatar)
    Vue.set(state.user, 'is_admin', data.is_admin)
  },
  setTmpAccount(state, isTmpAccount) {
    Vue.set(state.user, 'tmpAccount', isTmpAccount)
  },
  setAvatar(state, avatar) {
    Vue.set(state.user, 'avatar', avatar)
  },
  setEmail(state, email) {
    Vue.set(state.user, 'email', email)
  },
  setUsername(state, username) {
    Vue.set(state.user, 'username', username)
  },
  setLocale(state, locale) {
    state.locale = locale
  },
  setBrowserLocaleDetected(state, browserLocaleDetected) {
    state.browserLocaleDetected = browserLocaleDetected
  },
  setLocaleManuallySelected(state, localeManuallySelected) {
    state.localeManuallySelected = localeManuallySelected
  },
  setAuthPages(state, pages) {
    state.authPages = pages
  },
  activate(state) {
    Vue.set(state.user, 'active', true)
  },
  logout(state) {
    Vue.set(state, 'user', null)
    Vue.set(state, 'token', null)
    Vue.set(state, 'config', null)
    // Also reset the old api_token, because otherwise the user would be automatically logged in the next time they reload the page
    window.localStorage.removeItem('api_token')
  },
  hideInstallPrompt(state) {
    state.installPromptHidden = true
  },
  setFCMToken(state, token) {
    state.fcmToken = token
  },
}
