import constants from '@/constants'
import WebinarsAPI from '@/modules/webinars/api/webinars'

function getType(webinar) {
  if (webinar.duration_minutes === null) {
    return constants.WEBINARS.TYPE_LEARNINGROOM
  }
  return constants.WEBINARS.TYPE_WEBINAR
}

function mapWebinar(webinar) {
  webinar.type = getType(webinar)
  return webinar
}

function getRecordingType(recording) {
  if (recording.webinar_duration_minutes === null) {
    return constants.WEBINARS.TYPE_LEARNINGROOM
  }
  return constants.WEBINARS.TYPE_WEBINAR
}

function mapRecording(recording) {
  recording.type = getRecordingType(recording)
  return recording
}

export default {
  updateWebinars({ commit }) {
    return WebinarsAPI.getWebinars().then((data) => {
      const webinars = data.webinars.map(mapWebinar)
      commit('setWebinars', webinars)
    })
  },
  updateRecordings({ commit }) {
    return WebinarsAPI.getRecordings().then((data) => {
      const recordings = data.recordings.map(mapRecording)
      commit('setRecordings', recordings)
    })
  },
}
