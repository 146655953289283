<template>
  <div
    v-if="directMessages && directMessages.length"
    class="u-marginBottom6">
    <div
      v-for="message in directMessages"
      :key="message.id">
      <DirectMessage
        :message="message"
        class="u-marginBottom2" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import DirectMessage from '@/modules/directmessages/components/DirectMessage.vue'

export default {
  created() {
    this.$store.dispatch('directMessages/updateDirectMessages')
  },
  computed: {
    ...mapGetters({
      directMessages: 'directMessages/directMessages',
    }),
  },
  components: {
    DirectMessage,
  },
}
</script>
