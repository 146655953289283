import Vue from 'vue'

import constants from '@/constants'
import learningmaterials from '@/logic/learningmaterials'

export default {
  setAllCourses(state, courses) {
    state.courses = courses
  },
  setContentWBTEvents(state, { contentId, events }) {
    Vue.set(state.contentWBTEvents, contentId, events)
  },
  setCourseDetails(state, course) {
    if (state.courseDetails === null) {
      state.courseDetails = {}
    }
    course.chapters.forEach((chapter) => {
      chapter.contents.forEach((content) => {
        if (content.type === constants.COURSES.TYPE_LEARNINGMATERIAL && content.relatable) {
          content.relatable = learningmaterials.mapMaterial(content.relatable)
        }
      })
    })
    Vue.set(state.courseDetails, course.id, course)
  },
  setParticipation(state, participation) {
    Vue.set(state.participations, participation.id, participation)
  },
  updateParticipationData(state, participation) {
    Vue.set(state.participations, participation.id, { ...state.participations[participation.id], ...participation })
  },
  setContentAttempt(state, { participationId, contentAttempt }) {
    const participation = state.participations[participationId]
    if (!participation) {
      return
    }
    const existingContentAttemptIdx = participation.content_attempts.findIndex((attempt) => attempt.id === contentAttempt.id)
    if (existingContentAttemptIdx !== -1) {
      Vue.set(participation.content_attempts, existingContentAttemptIdx, contentAttempt)
    } else {
      Vue.set(participation.content_attempts, participation.content_attempts.length, contentAttempt)
    }
    Vue.set(state.participations, participation.id, participation)
  },
  setFilter(state, filter) {
    state.filter = filter
  },
  setSort(state, sort) {
    state.sort = sort
  },
  setSearchQuery(state, query) {
    state.searchQuery = query
  },
  setSearchOpen(state, value) {
    state.searchOpen = value
  },
  toggleSearchOpen(state) {
    state.searchOpen = !state.searchOpen
  },
  toggleCategoryFilter(state, categoryId) {
    const index = state.categoryFilters.findIndex((categoryFilter) => categoryFilter === categoryId)
    if (index === -1) {
      state.categoryFilters.push(categoryId)
    } else {
      Vue.delete(state.categoryFilters, index)
    }
  },
  resetFilters(state) {
    state.searchQuery = null
    state.searchOpen = false
    state.typeFilters = []
    state.categoryFilters = []
    state.sort = constants.COURSES.SORT_NAME_ASC
  },
}
