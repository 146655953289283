<template>
  <div
    v-if="latestAppointments && latestAppointments.length"
    data-cy="AppointmentsBlock">
    <div class="t-body3 u-marginBottom2 u-colorDarkLightEmphasis">{{ $tc('appointments.appointments', latestAppointments.length) }}</div>
    <SlideOnMobile class="u-marginTop">
      <div class="o-grid -equalHeight">
        <div
          v-for="appointment in latestAppointments"
          :key="appointment.id"
          class="o-grid__cell -c10 -c4@tabletPortrait">
          <AppointmentCard :appointment="appointment" />
        </div>
      </div>
    </SlideOnMobile>
    <router-link
      :to="{
        name: 'appointments.index.upcoming',
      }"
      class="t-body3 u-colorPrimary t-fontWeightMedium t-noLinkAppearance u-aboveCard u-inlineBlock u-marginTop1"
      data-cy="AppointmentsBlock__title">
      {{ $t('appointments.see_all') }}
      <SvgIcon
        type="chevron_right"
        tiny />
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import AppointmentCard from '@/modules/appointments/components/AppointmentCard.vue'

export default {
  computed: {
    ...mapGetters({
      upcomingAppointments: 'appointments/upcomingAppointments',
    }),
    latestAppointments() {
      if (!this.upcomingAppointments) {
        return null
      }
      return this.upcomingAppointments
        .filter((appointment) => !appointment?.resolved)
        .slice(0, 3)
    },
  },
  components: {
    AppointmentCard,
  },
}
</script>
