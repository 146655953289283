const analytics = require('./analytics')
const appointments = require('./appointments')
const auth = require('./auth')
const comments = require('./comments')
const competitions = require('./competitions')
const courses = require('./courses')
const deepstream = require('./deepstream')
const errors = require('./errors')
const feedback = require('./feedback')
const forms = require('./forms')
const generic = require('./generic')
const homeComponents = require('./homeComponents')
const learnCards = require('./learnCards')
const learningpath = require('./learningpath')
const nav = require('./nav')
const news = require('./news')
const notifications = require('./notifications')
const notificationSettings = require('./notificationSettings')
const pages = require('./pages')
const powerlearning = require('./powerlearning')
const quizapp = require('./quizapp')
const tests = require('./tests')
const todolists = require('./todolists')
const vouchers = require('./vouchers')
const webinars = require('./webinars')

module.exports = {
  analytics,
  appointments,
  auth,
  comments,
  competitions,
  courses,
  deepstream,
  errors,
  feedback,
  forms,
  generic,
  homeComponents,
  learnCards,
  learningpath,
  nav,
  news,
  notificationSettings,
  notifications,
  pages,
  powerlearning,
  quizapp,
  tests,
  todolists,
  vouchers,
  webinars,
}
