export default {
  LANGUAGES: [
    {
      id: 'al',
      label: 'shqip',
    },
    {
      id: 'bg',
      label: 'Български',
    },
    {
      id: 'cs',
      label: 'Čeština',
    },
    {
      id: 'de',
      label: 'Deutsch',
      localeCodes: ['de-DE'],
    },
    {
      id: 'de_formal',
      label: 'Deutsch (Sie)',
      localeCodes: ['de-DE'],
    },
    {
      id: 'en',
      label: 'English',
      localeCodes: ['en-US'],
    },
    {
      id: 'es',
      label: 'Español',
    },
    {
      id: 'fr',
      label: 'Français',
    },
    {
      id: 'hr',
      label: 'Hrvatski',
    },
    {
      id: 'hu',
      label: 'Magyar',
    },
    {
      id: 'it',
      label: 'Italiano',
    },
    {
      id: 'jp',
      label: '日本人',
    },
    {
      id: 'nl',
      label: 'Nederlands',
    },
    {
      id: 'no',
      label: 'Norsk',
    },
    {
      id: 'pl',
      label: 'Polski',
    },
    {
      id: 'pt',
      label: 'Português',
    },
    {
      id: 'ro',
      label: 'Română',
    },
    {
      id: 'ru',
      label: 'русский',
    },
    {
      id: 'sr',
      label: 'Srpski',
    },
    {
      id: 'tr',
      label: 'Türkçe',
    },
    {
      id: 'zh',
      label: '中文',
    },
  ],
  GAME: {
    STATUS_CANCELED: -1,
    STATUS_FINISHED: 0,
    STATUS_TURN_OF_PLAYER_1: 1,
    STATUS_TURN_OF_PLAYER_2: 2,
  },
  QUESTION: {
    TYPE_SINGLE_CHOICE: 0,
    TYPE_MULTIPLE_CHOICE: 1,
    TYPE_LEARN_CARD: 3,
  },
  QUESTION_ATTACHMENT: {
    TYPE_IMAGE: 0,
    TYPE_AUDIO: 1,
    TYPE_YOUTUBE: 2,
    TYPE_AZURE_VIDEO: 3,
  },
  LIKES: {
    TYPE_NEWS: 0,
    TYPE_COMPETITIONS: 1,
    TYPE_COURSES: 2,
    TYPE_LEARNINGMATERIAL: 3,
  },
  LEARNINGMATERIALS: {
    TYPE_WBT: 0,
    TYPE_VIDEO: 1,
    TYPE_AUDIO: 2,
    TYPE_DOCUMENT: 3,
    TYPE_DOCUMENT_WORD: 4,
    TYPE_DOCUMENT_IMAGE: 5,
    TYPE_DOCUMENT_PDF: 6,
    TYPE_DOCUMENT_POWERPOINT: 7,
    TYPE_DOCUMENT_SHEET: 8,
    TYPE_ZIP: 9,
    TYPE_NO_ATTACHMENT: 10,
    TYPE_LINK: 11,
    SORT_NAME_ASC: 0,
    SORT_NAME_DESC: 1,
    SORT_PUBLISHED_AT_DESC: 2,
    SORT_PUBLISHED_AT_ASC: 3,
    WBT_SUBTYPES: {
      XAPI: 0,
      SCORM: 1,
    },
  },
  TESTS: {
    FILTER_PASSED: 0,
    FILTER_NOT_PASSED: 1,
    FILTER_EXPIRES_SOON: 2,
    FILTER_HAS_CERTIFICATE: 3,
    SORT_NAME_ASC: 0,
    SORT_NAME_DESC: 1,
    SORT_PUBLISHED_AT_DESC: 2,
    SORT_PUBLISHED_AT_ASC: 3,
    FILTER_AVAILABLE: 0,
    FILTER_UNAVAILABLE: 1,
  },
  LEARN_CARDS: {
    TYPE_STANDARD: 'standard',
    TYPE_IMAGEMAP: 'imagemap',
  },
  WEBINARS: {
    SORT_TOPIC_ASC: 0,
    SORT_TOPIC_DESC: 1,
    SORT_STARTS_AT_DESC: 2,
    SORT_STARTS_AT_ASC: 3,
    FILTER_WEBINARS: 0,
    FILTER_LEARNINGROOMS: 1,
    TYPE_WEBINAR: 0,
    TYPE_LEARNINGROOM: 1,
  },
  COURSES: {
    FILTER_ACTIVE: 0,
    FILTER_FINISHED: 1,
    FILTER_PREVIEW: 2,
    FILTER_MANDATORY: 3,
    SORT_NAME_ASC: 0,
    SORT_NAME_DESC: 1,
    SORT_AVAILABLE_FROM_DESC: 2,
    SORT_AVAILABLE_FROM_ASC: 3,
    TYPE_CERTIFICATE: 4,
    TYPE_FORM: 19,
    TYPE_APPOINTMENT: 20,
    TYPE_LEARNINGMATERIAL: 1,
    TYPE_QUESTIONS: 1001,
    TYPE_TODOLIST: 21,
    TYPE_TEXT: 1008,
    DURATION_TYPE_FIXED: 0,
    DURATION_TYPE_DYNAMIC: 1,
    PARTICIPATION_DURATION_TYPE_DAYS: 0,
    PARTICIPATION_DURATION_TYPE_WEEKS: 1,
    PARTICIPATION_DURATION_TYPE_MONTHS: 2,
    TEXT_TYPE_TEXT: 0,
    TEXT_TYPE_IMAGE: 1,
    TEXT_TYPE_TEXT_IMAGE: 2,
    TYPE_COURSE_END: 'courseEnd',
  },
  ADVERTISEMENTS: {
    POSITION_LOGIN: 0,
    POSITION_HOME_MIDDLE: 1,
    POSITION_HOME_BOTTOM: 8,
    POSITION_NEWS: 2,
    POSITION_MEDIALIBRARY: 3,
    POSITION_POWERLEARNING: 4,
    POSITION_INDEXCARDS: 5,
    POSITION_QUIZ: 6,
    POSITION_TESTS: 7,
    POSITION_REDEEM_VOUCHER: 9,
  },
  CONTENT_TYPES: {
    TYPE_LEARNINGMATERIAL: 1,
    TYPE_NEWS: 2,
    TYPE_COMPETITION: 3,
    TYPE_CERTIFICATE: 4,
    TYPE_COURSE: 5,
    TYPE_QUESTION: 10,
    COURSE_CONTENT_QUESTIONS: 1001,
    TYPE_COURSE_CONTENT_ATTEMPT: 1002,
    TYPE_COURSE_CONTENT_TEXT: 1008,
  },
  REPORT_REASONS: {
    REASON_OFFENSIVE: 1,
    REASON_ADVERTISEMENT: 2,
    REASON_PERSONAL_RIGHTS: 3,
    REASON_MISC: 0,
  },
  FORMS: {
    FOREIGN_TYPES: {
      TYPE_COURSE_CONTENT_ATTEMPT: 1002,
    },
    FIELDS: {
      TYPE_TEXTAREA: 1,
      TYPE_RATING: 2,
      TYPE_HEADER: 3,
      TYPE_SEPARATOR: 4,
    },
  },
  COMMENTS: {
    ATTACHMENT_COUNT_LIMIT: 10,
    // 10 MB in bytes
    ATTACHMENT_FILESIZE_LIMIT: 10485760,
  },
  HOMEPAGE: {
    COURSES: {
      DEFAULT_ROWS: 2,
      ITEMS_PER_ROW: 4,
    },
  },
  DEVICE_SIZES: {
    SMARTPHONE: 1,
    TABLET_MINI: 576,
    TABLET_PORTRAIT: 768,
    TABLET_LANDSCAPE: 1024,
    DESKTOP_SMALL: 1260,
    DESKTOP_LARGE: 1380,
    DESKTOP_XLARGE: 1680,
    DESKTOP_XXLARGE: 1920,
  },
  VERSION: GIT_COMMIT_SHORT,
  WBTS: {
    STARTED_EVENTS: ['attempted', 'experienced'],
    FINISHED_EVENTS: ['passed', 'failed', 'completed'],
  },
  TODOLISTS: {
    FILTER_ACTIVE: 'active',
    FILTER_DONE: 'done',
  },
  FILE_TYPES: {
    TYPE_WBT: 0,
    TYPE_VIDEO: 1,
    TYPE_AUDIO: 2,
    TYPE_DOCUMENT: 3,
    TYPE_DOCUMENT_WORD: 4,
    TYPE_DOCUMENT_IMAGE: 5,
    TYPE_DOCUMENT_PDF: 6,
    TYPE_DOCUMENT_POWERPOINT: 7,
    TYPE_DOCUMENT_SHEET: 8,
    TYPE_ZIP: 9,
    TYPE_UNKNOWN: 10,
  },
  MORPH_TYPES: {
    TYPE_LEARNINGMATERIAL: 1,
    TYPE_NEWS: 2,
    TYPE_COMPETITION: 3,
    TYPE_CERTIFICATE: 4,
    TYPE_COURSE: 5,
    TYPE_TEST: 6,
    TYPE_QUESTION: 10,
    TYPE_LEARNINGMATERIAL_FOLDER: 12,
    TYPE_CATEGORY: 13,
    TYPE_CATEGORYGROUP: 14,
    TYPE_FORM: 19,
    TYPE_APPOINTMENT: 20,
    TYPE_TODOLIST: 21,
    TYPE_PAGE: 22,
    TYPE_ADVERTISEMENT: 24,
    TYPE_CONTENT_CATEGORY: 25,
    TYPE_COURSE_CHAPTER: 1000,
    TYPE_COURSE_CONTENT_QUESTION: 1001,
    TYPE_COURSE_CONTENT_ATTEMPT: 1002,
    TYPE_COURSE_CONTENT: 1003,
    TYPE_KEYWORD: 1300,
  },
}
