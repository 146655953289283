import Buffer from 'buffer'

import AuthAPI from '@/modules/auth/api/auth'

window.Buffer = Buffer.Buffer

const options = {
  // Reconnect after 5, 10, ... seconds
  reconnectIntervalIncrement: 5000,
  // Try reconnecting at least every thirty seconds
  maxReconnectInterval: 30000,
  // We never want to stop trying to reconnect
  maxReconnectAttempts: Infinity,
}

export default {
  client: null,
  store: null,
  areSubscriptionsSetup: false,
  updateProfileTimeout: null,
  /**
   * Initializes deepstream so we can connect later on
   *
   * @param {*} store
   * @param {*} i18n
   * @param {*} router
   */
  init(store, i18n, router) {
    this.store = store
    this.i18n = i18n
    this.router = router

    this.connect()
  },
  /**
   * Connects to the deepstream server and sets up listeners
   */
  async connect() {
    if (!this.store.getters['auth/isLoggedIn']) {
      return
    }
    await this.setupClient()
    this.setupSubscriptions()
  },
  async setupClient() {
    if (this.client !== null) {
      return
    }

    const { DeepstreamClient } = await import('@deepstream/client')

    if (process.env.NODE_ENV === 'development') {
      this.client = new DeepstreamClient('ws://localhost:6040/deepstream', options)
    } else {
      const baseUrl = process.env.VUE_APP_API_URL.split('/')[2]
      this.client = new DeepstreamClient(`wss://${baseUrl}:6020/deepstream4`, options)
    }
    const userid = this.store.getters['auth/userId']
    const token = this.store.getters['auth/token']
    this.client.login({
      type: 'webhook',
      username: userid,
      password: token,
    })

    this.client.on('error', () => {
      // console.log(a)
      // console.log(b)
    })
  },
  setupSubscriptions() {
    const userid = this.store.getters['auth/userId']
    const appId = this.store.getters['auth/config'].id

    if (this.areSubscriptionsSetup) {
      this.client.event.unsubscribe(`users/${userid}/gameinvitation`)
      this.client.event.unsubscribe(`users/${userid}/gameupdate`)
    }

    this.client.event.subscribe(`users/${userid}/gameinvitation`, (...args) => this.showGameInvitation(...args))
    this.client.event.subscribe(`users/${userid}/gameupdate`, (...args) => this.showGameUpdate(...args))

    this.client.event.subscribe(`appProfile/${appId}/update`, (...args) => this.updateProfile(...args))

    this.areSubscriptionsSetup = true
  },
  close() {
    if (this.client) {
      this.client.close()
    }
  },
  showNotification(title, route, avatar) {
    const createNotification = () => {
      this.store.commit('notifications/addNotification', {
        title,
        route,
        avatar,
      })
    }
    if (typeof Notification !== 'undefined' && Notification.permission === 'granted') {
      try {
        const n = new Notification(title, {
          icon: '/static/favicons/android-chrome-144x144.png',
        })
        n.addEventListener('click', () => {
          this.router.push(route)
        })
      } catch (e) {
        createNotification.apply(this)
      }
    } else {
      createNotification.apply(this)
    }
  },
  showGameInvitation(data) {
    this.store.dispatch('quizzes/updateActiveQuizzes')
    if (!window.Capacitor) {
      const title = this.i18n.t('deepstream.you_have_been_challenged', { playerName: data.player1_name })
      this.showNotification(
        title,
        {
          name: 'quizzes.details',
          params: {
            id: parseInt(data.game_id, 10),
          },
        },
        data.opponent_avatar,
      )
    }
  },
  showGameUpdate(data) {
    const gameId = parseInt(data.game_id, 10)
    this.store.dispatch('quizzes/getGame', { id: gameId })

    // Show a notification if the game is not yet over
    if (!window.Capacitor && data.status > 0) {
      const title = this.i18n.t('deepstream.its_your_turn', { playerName: data.opponent_name })
      this.showNotification(
        title,
        {
          name: 'quizzes.details',
          params: {
            id: gameId,
          },
        },
        data.opponent_avatar,
      )
    }
  },
  updateProfile() {
    if (this.updateProfileTimeout) {
      window.clearTimeout(this.updateProfileTimeout)
      this.updateProfileTimeout = null
    }
    // Wait for some random time before updating the profile config
    // so we don't have lots of clients requesting it at the exact same time
    let wait = Math.random() * 10 * 1000
    if (this.store.getters['auth/isAdmin']) {
      // Admins want to see the update immediately
      wait = 0
    }
    this.updateProfileTimeout = window.setTimeout(() => {
      AuthAPI.getProfileConfig(`id:${this.store.getters['auth/config'].id}`).then((config) => {
        this.store.dispatch('auth/setConfig', config)
      })
    }, wait)
  },
}
