export default [
  {
    component: () => import('@/modules/viewers/views/WBTViewer.vue'),
    name: 'wbt',
    path: '/viewers/wbt/:id',
    meta: {
      layout: 'Blank',
    },
  },
  {
    component: () => import('@/modules/viewers/views/CourseWBTViewer.vue'),
    name: 'course-wbt',
    path: '/viewers/course/wbt/:courseId/:contentId',
    meta: {
      layout: 'Blank',
    },
  },
  {
    component: () => import('@/modules/viewers/views/PDFViewer.vue'),
    name: 'pdf',
    path: '/viewers/pdf/:id',
    meta: {
      layout: 'Blank',
    },
  },
  {
    component: () => import('@/modules/viewers/views/CoursePDFViewer.vue'),
    name: 'course-pdf',
    path: '/viewers/course/pdf/:courseId/:contentId',
    meta: {
      layout: 'Blank',
    },
  },
]
