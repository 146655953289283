import CompetitionsAPI from '@/modules/competitions/api/competitions'

export default {
  updateCompetitions({ commit }) {
    return CompetitionsAPI.getCompetitions().then((competitions) => {
      const keyedCompetitions = {}
      competitions.forEach((competitionsEntry) => {
        keyedCompetitions[competitionsEntry.id] = competitionsEntry
      })
      commit('setAllCompetitions', keyedCompetitions)
    })
  },
  updateRequiredCredentials({ commit }) {
    CompetitionsAPI.hasRequiredCredentials().then((credentials) => {
      commit('setRequiredCredentials', credentials)
    })
  },
  findCompetition({ commit }, id) {
    return CompetitionsAPI.findCompetition(id).then((data) => {
      commit('setCompetition', data)
    })
  },
}
