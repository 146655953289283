import api from '@/logic/api'

export default {
  getProfile: () => api.get('/profile'),
  getNotificationSettings: () => api.get('/notification-user-settings'),
  getTags: () => api.get('/user/tagsWithGroups'),
  saveNotificationSettings: (notifications) => api.post('/notification-user-settings', { notifications }),
  uploadAvatar: (image) => api.post('/profile/avatar', { image }),
  getVouchers: () => api.get('/vouchers').then((data) => data.data),
  redeemVoucher: (code) => api.post('/vouchers/redeem', { code }),
  setLanguage: (lang) => api.post('/user/language', { lang }),
  changePassword(oldPassword, newPassword) {
    return api.post('/password/set', {
      oldpw: oldPassword,
      newpw: newPassword,
    })
  },
  forcePasswordReset(payload) {
    return api.post('/password/force-password-reset', payload)
  },
  updateAccount(userId, firstname, lastname, email) {
    return api.post(`/users/${userId}`, {
      firstname,
      lastname,
      email,
    })
  },
  convertAccount(username, email, password, firstname, lastname) {
    return api.post('/convertaccount', {
      username,
      email,
      password,
      firstname,
      lastname,
    })
  },
  getAvatarBlobs() {
    async function getImageBlob(avatarImageUrl) {
      const imageBlob = await api.getFile(avatarImageUrl)
      return { avatarImageUrl, imageBlob }
    }

    return api.get('/profile/default-avatars')
      .then((result) => {
        const avatarUrls = result.avatars
        return Promise.all(avatarUrls.map((url) => getImageBlob(url)))
      })
  },
  logout(token) {
    return api.post('/logout', {
      token,
    })
  },
  changeProfileSettings(userId, username, email, password) {
    return api.post(`/users/${userId}`, {
      username,
      email,
      password,
    })
  },
  requestUserDeletion() {
    return api.post('/users/request-deletion')
  },
}
