import api from '@/logic/api'

export default {
  getActiveQuizzes: () => api.get('/games/active'),
  getHistory: () => api.get('/games/history'),
  getBots: () => api.get('/users/bots'),
  getGame: (id) => api.get(`/games/${id}`),
  getGameIntro: (id) => api.get(`/games/${id}/intro`),
  getNextQuestion: (id) => api.get(`/games/${id}/question`),
  getRandomOpponent: () => api.get('/users/random'),
  getRoundCategories: (id) => api.get(`/games/${id}/categories`),
  getTopPlayers: () => api.get('/games/active'),
  saveAnswer: (gameId, answerId) => api.post(`/games/${gameId}/question`, { question_answer_id: answerId }),
  setNextCategory: (gameId, categoryId) => api.post(`/games/${gameId}/categories`, { category_id: categoryId }),
  startGame: (opponentId) => api.post('/games', { opponent_id: opponentId }),
  useJoker: (gameId, answerIds) => api.post(`/games/${gameId}/joker`, { answer_ids: answerIds }),
}
