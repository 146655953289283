module.exports = {
  attachments: {
    size_limit: 'Die Maximale Dateigröße beträgt 10MB',
    count_limit: 'Du kannst maximal 10 Dateien bei deinem Kommentar hochladen',
    load_more: 'Ein weiteres Bild laden | {count} weitere Bilder laden',
  },
  comments: 'Kommentare',
  comment_is_sending: 'Dateien werden hochgeladen...',
  comment_is_added: 'Kommentar erfolgreich gepostet.',
  delete_comment: 'Kommentar löschen',
  delete_comment_question: 'Kommentar löschen?',
  deleted_by_admin: 'Kommentar durch Administrator entfernt.',
  deleted_by_user: 'Kommentar vom User gelöscht.',
  deletion_failed: 'Kommentar konnte nicht gelöscht werden, bitte versuche es später erneut.',
  deletion_is_irreversible: 'Diese Aktion kann nicht rückgängig gemacht werden.',
  deletion_success: 'Dein Kommentar wurde gelöscht.',
  messages_to_course_management: 'Nachrichten an die Kursleitung',
  more_replies: 'weitere Antwort | weitere Antworten',
  no_comments_yet: 'Es gibt noch keine Kommentare.',
  no_messages_yet: 'Es gibt noch keine Nachrichten.',
  posting_failed: 'Dein Kommentar konnte nicht gepostet werden, bitte versuche es später erneut.',
  read_more: 'weiterlesen',
  reply_to_x: 'Antwort an %{username} verfassen',
  report_comment: 'Kommentar melden',
  reporting_failed: 'Kommentar konnte nicht gemeldet werden, bitte versuche es später erneut.',
  reporting_success: 'Der Kommentar wurde gemeldet.',
  report_reasons: {
    reason_offensive: 'Beleidigende Inhalte',
    reason_advertisement: 'Unerlaubte Werbung',
    reason_personal_rights: 'Verletzung von Persönlichkeitsrechten',
    reason_misc: 'Anderer Grund',
  },
  upload_file: 'Datei hochladen',
  vips: {
    coursemanager: 'Kursleitung',
  },
  write_comment: 'Kommentar verfassen',
  write_comment_placeholder: 'Kommentar verfassen…',
  write_message: 'Nachricht verfassen',
  write_reply: 'Antwort verfassen',
}
