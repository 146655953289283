import HomeView from '@/modules/global/views/HomeView.vue'
import TrainingView from '@/modules/global/views/TrainingView.vue'

export default [
  {
    component: HomeView,
    name: 'home',
    path: '/',
  },
  {
    component: TrainingView,
    name: 'training',
    path: '/training',
  },
  {
    component: () => import('@/modules/global/views/QuestionPreviewView.vue'),
    name: 'question-preview',
    path: '/questions/:id/preview',
  },
  {
    component: () => import('@/modules/global/views/SuggestQuestionView.vue'),
    name: 'suggest-question',
    path: '/questions/suggest',
    meta: {
      requiresModule: 'suggested_questions',
    },
  },
  {
    component: () => import('@/modules/global/views/MissingModuleView.vue'),
    name: 'missing-module',
    path: '/missing-module/:module',
    meta: {
      noMobileBottomPadding: true,
    },
  },
  {
    component: () => import('@/modules/global/views/GoBack.vue'),
    name: 'back',
    path: '/back',
    meta: {
      layout: 'Blank',
    },
  },
]
