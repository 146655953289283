<template>
  <AdCards
    v-if="hasModule.advertisements"
    :position="settings.position"
    data-cy="AdvertismentsBlock" />
</template>

<script>
import { mapGetters } from 'vuex'

import AdCards from '@/modules/advertisements/components/AdCards.vue'

export default {
  props: {
    settings: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapGetters({
      hasModule: 'auth/hasModule',
    }),
  },
  components: {
    AdCards,
  },
}
</script>
