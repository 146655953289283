export default {
  myTeams(state) {
    return state.myTeams
  },
  findTeam(state) {
    if (Object.keys(state.teams).length === 0) {
      return () => null
    }
    return (id) => state.teams[id]
  },
}
