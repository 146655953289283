module.exports = {
  add_to_calendar: 'Termin zum Kalender hinzufügen',
  all_appointments: 'Alle Termine',
  appointment_details: 'Termin-Details',
  appointment_location: 'Veranstaltungsort',
  appointments: 'Termin|Termine',
  cancelled: 'Abgesagt',
  elapsed: 'Vergangen',
  last_updated_at: 'Zuletzt aktualisiert am %{date}',
  next_appointments: 'Anstehende Termine',
  see_all: 'Alle Termine ansehen',
  upcoming: 'Anstehend',
  openSlots: 'offene Plätze',
  nOpenSlots: '1 offener Platz|%{openSlots} offene Plätze',
  noOpenSlots: 'keine offenen Plätze',
  register: 'Teilnehmen',
  unregister: 'Abmelden',
  nUsersAlreadyRegistered: '<strong>1 Person</strong> nimmt bereits teil|<strong>%{participantsCount} Personen</strong> nehmen bereits teil',
  nUsersAlsoRegistered: '<strong>1 Person</strong> nimmt ebenfalls teil|<strong>%{participantsCount} Personen</strong> nehmen ebenfalls teil',
  isFullyBooked: 'Termin ist ausgebucht',
  youAreRegistered: 'Du nimmst teil',
  registeredSuccessfully: 'Du bist nun zu diesem Termin angemeldet',
  unregisteredSuccessfully: 'Du nimmst nicht mehr an diesem Termin teil',
}
