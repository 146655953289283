export default {
  activeCompetitions(state, getters) {
    if (!getters.competitions) {
      return null
    }
    return getters.competitions.filter((competition) => !competition.hasEnded)
  },
  competitions(state) {
    if (!state.competitions) {
      return null
    }
    return Object.values(state.competitions).sort((competitionA, competitionB) => {
      if (competitionA.hasEnded && !competitionB.hasEnded) {
        return 1
      }
      if (competitionB.hasEnded && !competitionA.hasEnded) {
        return -1
      }
      return competitionB.id > competitionA.id ? 1 : -1
    })
  },
  competitionCategories(state, getters) {
    if (!getters.activeCompetitions) {
      return null
    }
    return getters
      .activeCompetitions
      .map((competition) => competition.category_id)
      .filter((value, index, self) => self.indexOf(value) === index)
  },
  findCompetition(state) {
    if (!state.competitions) {
      return () => null
    }
    return (id) => state.competitions[id]
  },
  hasMissingData(state, getters, rootData, rootGetters) {
    const settings = rootGetters['auth/config'].settings
    if (!settings.competitions_need_email && !settings.competitions_need_realname) {
      return false
    }
    if (!state.requiredCredentials) {
      return false
    }
    if (settings.competitions_need_email && (state.requiredCredentials.temporaryAccount || state.requiredCredentials.maillessAccount)) {
      return true
    }
    if (settings.competitions_need_realname && !state.requiredCredentials.hasRequiredUserCredentials) {
      return true
    }
    return false
  },
  isCategoryInCompetition(state, getters) {
    if (!getters.activeCompetitions) {
      return () => null
    }
    return (categoryId) => {
      if (getters.activeCompetitions.some((competition) => competition.category_id === null)) {
        return true
      }
      return getters.competitionCategories.includes(categoryId)
    }
  },
}
