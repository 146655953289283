import QuizzesAPI from '@/modules/quizzes/api/quizzes'

export default {
  updateActiveQuizzes({ commit }) {
    return QuizzesAPI.getActiveQuizzes().then((activeQuizzes) => {
      commit('setActiveQuizzes', activeQuizzes)
    })
  },
  getGame({ commit, getters }, { id, ifNotPresent = false }) {
    if (ifNotPresent && getters.getGame(id)) {
      return Promise.resolve()
    }
    return QuizzesAPI.getGame(id).then((data) => {
      commit('setGame', data)
    })
  },
  updateBots({ commit }) {
    return QuizzesAPI.getBots().then((bots) => {
      commit('setBots', bots)
    })
  },
  updateQuizHistory({ commit }) {
    return QuizzesAPI.getHistory().then((history) => {
      commit('setHistory', history)
    })
  },
}
