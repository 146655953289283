<template>
  <div
    v-if="isActive"
    class="c-loading__wrapper">
    <div
      v-if="!isOffline"
      class="c-loading">
      <p>{{ $t('generic.loading') }}</p>
      <span />
    </div>
    <template v-if="isOffline">
      <div class="c-loading__offline">
        <strong>{{ $t('generic.offline') }}</strong><br />
        {{ $t('generic.offline_message') }}
      </div>
      <img
        class="c-loading__offlineImage"
        src="@/assets/images/login/testen.svg" />
    </template>
  </div>
</template>

<script>
import { VueOnline } from 'vue-online'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      isActive: false,
      activationTimeout: null,
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'isLoading',
    }),
    isOffline() {
      return !VueOnline.isOnline
    },
  },
  watch: {
    isOffline() {
      if (!this.isOffline && this.isActive) {
        window.location.reload()
      }
    },
    isLoading() {
      if (!this.isLoading) {
        if (this.activationTimeout) {
          window.clearTimeout(this.activationTimeout)
        }
        this.activationTimeout = null
        this.isActive = false
      } else if (!this.activationTimeout) {
        this.activationTimeout = window.setTimeout(() => {
          this.isActive = true
          this.timeout = null
        }, 500)
      }
    },
  },
}
</script>
