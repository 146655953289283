import LikesAPI from '@/modules/likes/api/likes'

export default {
  updateLikeStatus({ commit }, { foreignType, foreignId }) {
    return LikesAPI.getLikeStatus(foreignType, foreignId).then((data) => {
      commit('setLikesIt', {
        foreignType,
        foreignId,
        likesIt: data.likes_it,
      })
    })
  },
}
