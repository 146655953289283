<template>
  <SlideOnMobile class="u-marginTop">
    <div class="o-grid -equalHeight">
      <div
        v-for="category in categories"
        :key="category.id"
        class="s-newsCell o-grid__cell -c6 -c3@tabletPortrait -c2@desktopSmall">
        <CategoryCard
          :category="category"
          :categorygroup="category.group" />
      </div>
    </div>
  </SlideOnMobile>
</template>

<script>
import LocalPowerlearning from '@/modules/powerlearning/api/localPowerlearning'
import CategoryCard from '@/modules/powerlearning/components/CategoryCard.vue'

export default {
  data() {
    return {
      categoryGroups: null,
    }
  },
  created() {
    this.loadData()
  },
  computed: {
    categories() {
      if (!this.categoryGroups) {
        return []
      }
      const allCategories = []
      this.categoryGroups.forEach((group) => {
        if (group.categories) {
          group.categories.forEach((category) => {
            category.group = group
            allCategories.push(category)
          })
        }
      })
      allCategories.sort((a, b) => {
        const aLastAnswered = a.last_answered_at || 0
        const bLastAnswered = b.last_answered_at || 0
        if (!aLastAnswered && !bLastAnswered) {
          return b.created_at.localeCompare(a.created_at)
        }
        return bLastAnswered - aLastAnswered
      })
      return allCategories.slice(0, 6)
    },
  },
  methods: {
    loadData() {
      LocalPowerlearning.getCategoriesStatistics().then((groups) => {
        this.categoryGroups = groups
      })
    },
  },
  components: {
    CategoryCard,
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/config';

// only show 4 on certain screen sizes
.s-newsCell:nth-child(n + 5) {
  @media (min-width: #{$mq-tablet-landscape-size}) and (max-width: #{$mq-desktop-small-size - 1}) {
    display: none !important;
  }
}
</style>
