<template>
  <div
    v-if="hasModule.powerlearning && powerlearningStats"
    data-cy="PowerlearningBlock">
    <h1
      v-if="index > 0"
      class="t-h2 u-colorDark u-marginBottom3 u-marginTop0">
      {{ $t('generic.what_do_you_want_to_learn_today') }}
    </h1>
    <div
      class="t-body3 u-colorDarkLightEmphasis u-marginBottom3"
      data-cy="PowerlearningBlock__title">
      {{ $t('generic.powerlearning') }}
    </div>
    <RecentPowerlearningCategories />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import LocalPowerlearning from '@/modules/powerlearning/api/localPowerlearning'
import RecentPowerlearningCategories from '@/modules/powerlearning/components/RecentPowerlearningCategories.vue'

export default {
  props: {
    index: {
      default: null,
      required: false,
      type: Number,
    },
  },
  data() {
    return {
      powerlearningStats: null,
    }
  },
  created() {
    if (this.hasModule.powerlearning) {
      LocalPowerlearning.getCategoriesStatistics().then((groups) => {
        this.powerlearningStats = LocalPowerlearning.getCategorygroupBoxStatistics(groups)
      })
    }
  },
  computed: {
    ...mapGetters({
      hasModule: 'auth/hasModule',
    }),
  },
  components: {
    RecentPowerlearningCategories,
  },
}
</script>
