export default {
  /**
   * Returns a function, that, as long as it continues to be invoked, will not
   * be triggered. The function will be called after it stops being called for
   * N milliseconds. If `immediate` is passed, trigger the function on the
   * leading edge, instead of the trailing.
   * From: https://davidwalsh.name/javascript-debounce-function
   * @param {*} func
   * @param {*} wait
   * @param {*} immediate
   */
  debounce(func, wait = 100, immediate = false) {
    let timeout
    return (...args) => {
      const context = this
      const later = () => {
        timeout = null
        if (!immediate) func.apply(context, args)
      }
      const callNow = immediate && !timeout
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
      if (callNow) {
        func.apply(context, args)
      }
    }
  },
  /**
   * Decodes base64 into an unicode string
   * @param {string} base64
   * @returns string
   */
  base64ToUnicode(base64) {
    // jfc javascript does not play well with base64 unicode
    // https://stackoverflow.com/a/30106551
    return decodeURIComponent(atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''))
  },
  /**
   * Shuffles array in place.
   * @param {Array} a items An array containing the items.
   */
  shuffle(a) {
    let j
    let x
    let i
    for (i = a.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1))
      x = a[i]
      a[i] = a[j]
      a[j] = x
    }
    return a
  },
  /**
   * Capitalize first letter of string
   * @param {String} input String to capitalize
   */
  capitalize(input) {
    return input.charAt(0).toUpperCase() + input.slice(1)
  },
  /**
   * Returns the OS
   * @returns {string}
   */
  getOS() {
    if (/android/i.test(navigator.userAgent)) {
      return 'android'
    }
    // newer versions of iPad will identify as `MacIntel` with multitouch instead of as iPad
    if (/iPad|iPhone|iPod/.test(navigator.userAgent)
      || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
      return 'ios'
    }
    return 'other'
  },
}
