import Vue from 'vue'

export default {
  setActiveQuizzes(state, quizzes) {
    state.activeQuizzes = quizzes
  },
  setGame(state, game) {
    if (state.games === null) {
      state.games = {}
    }
    Vue.set(state.games, game.id, game)

    // Update the quiz in the active quizzes array
    if (state.activeQuizzes) {
      const activeQuizIdx = state.activeQuizzes.findIndex((quiz) => quiz.id === game.id)
      if (activeQuizIdx > -1) {
        const newActiveQuiz = JSON.parse(JSON.stringify(state.activeQuizzes[activeQuizIdx]))
        newActiveQuiz.round_info = game.results.roundInfo
        newActiveQuiz.status = game.status_string
        Vue.set(state.activeQuizzes, activeQuizIdx, newActiveQuiz)
      }
    }
  },
  updateGameFromDS(state, { id, data }) {
    if (state.games === null) {
      state.games = {}
    }
    id = parseInt(id, 10)
    const game = state.games[id]
    if (!game) {
      return
    }
    game.rounds = data.rounds
    game.status = data.status
    game.results = data.results

    Vue.set(state.games, game.id, game)
  },
  setBots(state, bots) {
    state.bots = bots
  },
  setHistory(state, history) {
    Vue.set(state, 'history', history)
  },
}
