import Vue from 'vue'

export default {
  setItemAnswer(state, { isDone, itemId }) {
    Vue.set(state.itemAnswers, itemId, isDone)
  },
  setItemAnswers(state, itemAnswers) {
    state.itemAnswers = itemAnswers
  },
  setTodolists(state, todolists) {
    state.todolists = todolists
  },
}
