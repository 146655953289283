<template>
  <div
    v-if="message"
    class="u-bgSecondary o-flex -row -spaceBetween t-caption1 c-directMessage -fullWidth">
    <div class="u-marginRight2 c-directMessage__info">
      <SvgIcon
        type="info"
        class="u-colorWhite"
        small />
    </div>
    <div
      v-internallinks
      v-highlightKeywords
      class="u-colorWhite o-textbody o-flex__item -grow c-directMessage__body"
      v-html="message.body" />
    <div>
      <button
        class="o-unstyled -inlineBlock c-directMessage__close"
        @click="close">
        <SvgIcon
          type="close"
          class="u-colorWhiteMediumEmphasis"
          tiny />
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import DirectMessagesAPI from '@/modules/directmessages/api/directmessages'

export default {
  props: {
    message: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapGetters({
      directMessages: 'directMessages/directMessages',
    }),
  },
  methods: {
    close() {
      this.$store.commit('directMessages/deleteDirectMessage', this.message.id)
      DirectMessagesAPI.markAsRead(this.message.id)
    },
  },
}
</script>
