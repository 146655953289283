module.exports = {
  search_webinar: 'Webinar suchen',
  webinars: 'Webinare',
  learningrooms: 'Lernräume',
  webinar: 'Webinar',
  learningroom: 'Lernraum',
  currently_no_webinars_visible: 'Hier gibt es aktuell keine Webinare.',
  currently_no_learningrooms_visible: 'Hier gibt es aktuell keine Lernräume.',
  sortLabels: {
    topic_asc: 'Alphabetisch (A-Z)',
    topic_desc: 'Alphabetisch (Z-A)',
    starts_at_desc: 'Datum (neuste zuerst)',
    starts_at_asc: 'Datum (älteste zuerst)',
  },
  sortShortLabels: {
    topic_asc: 'A-Z',
    topic_desc: 'Z-A',
    starts_at_desc: 'Neuste',
    starts_at_asc: 'Älteste',
  },
  participants: '1 Teilnehmer | %{n} Teilnehmer',
  live: 'Jetzt live',
  open: 'Geöffnet',
  recordings: 'Aufzeichnungen',
  leave_title: 'Webinar verlassen',
  sure_to_leave: 'Möchtest du das Webinar verlassen?',
  leave: 'Webinar verlassen',
  open_app_new_window: 'Lernsoftware in neuem Fenster öffnen',
  join_webinar: 'Webinar beitreten',
  join_learningroom: 'Lernraum betreten',
  active_webinars: 'Aktuell',
  recordings_title: 'Aufzeichnungen älterer Webinare',
  date_range: '%{date} - %{startTime} bis %{endTime} Uhr',
}
