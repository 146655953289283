<template>
  <div
    v-if="isVisible"
    class="o-flex -alignCenter t-noWrap">
    <SvgIcon
      type="info"
      medium />
    <div class="t-caption1 t-fontWeightMedium u-marginLeft1">
      {{ daysLeft == 1 ? $tc('generic.ending_today') : $tc('generic.x_days_left', daysLeft, { days: daysLeft }) }}
    </div>
  </div>
</template>

<script>
import { differenceInCalendarDays } from 'date-fns'

import DateManager from '@/logic/DateManager'

export default {
  props: {
    endDate: {
      type: [Date, String],
      default: null,
    },
    maxDays: {
      type: Number,
      default: 6,
    },
  },
  computed: {
    daysLeft() {
      const parsedEndDate = typeof this.endDate === 'string' ? DateManager.parseModelDate(this.endDate) : this.endDate
      return differenceInCalendarDays(parsedEndDate, new Date()) + 1
    },
    isVisible() {
      if (this.daysLeft <= 0) {
        return false
      }
      if (this.maxDays && this.daysLeft > this.maxDays) {
        return false
      }
      return true
    },
  },
}
</script>
