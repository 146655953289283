<template>
  <div
    v-if="blockIsVisible"
    class="c-card -smallShadow">
    <div class="c-card__content">
      <div class="o-flex -column -alignCenter">
        <img
          src="/img/appicon-keelearning-app-with-shadow@1x.png"
          srcset="/img/appicon-keelearning-app-with-shadow@1x.png 1x, /img/appicon-keelearning-app-with-shadow@2x.png 2x"
          alt="Keelearning" />
        <div class="t-body2 t-fontWeightMedium u-marginBottom5">
          {{ $t('homeComponents.appMobileInstallation.title') }}
        </div>
        <div class="t-body2 t-fontWeightMedium u-marginBottom5">{{ $t('homeComponents.appMobileInstallation.slug_title') }}: {{ config.slug }}</div>
        <a
          v-if="isIos"
          href="https://apps.apple.com/de/app/keelearning/id1542349036"
          target="_blank"
          rel="noopener noreferrer">
          <img
            src="@/assets/images/buttons/apple-app-store-large-englisch.svg"
            alt="Get it from the App Store" />
        </a>
        <a
          v-if="isAndroid"
          href="https://play.google.com/store/apps/details?id=de.sopamo.keeunit.keelearning"
          target="_blank"
          rel="noopener noreferrer">
          <img
            src="@/assets/images/buttons/google-play-large-englisch.svg"
            alt="Get it from the Google Play Store" />
        </a>
        <button
          class="o-unstyled o-flex -center u-marginTop5 t-caption1 u-colorDarkLightEmphasis"
          @click="hideBlock">
          <span>
            {{ $t('homeComponents.appMobileInstallation.hide') }}
          </span>
          <SvgIcon
            class="u-marginLeft1"
            type="close"
            small />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Helpers from '@/logic/helpers'

export default {
  computed: {
    ...mapGetters({
      config: 'auth/config',
    }),
    isAndroid() {
      return Helpers.getOS() === 'android'
    },
    isIos() {
      return Helpers.getOS() === 'ios'
    },
    blockIsVisible() {
      if (this.$store.state.internal.appMobileInstallationBlockHiddenAt) {
        return false
      }
      if (typeof window.Capacitor !== 'undefined' || (!this.isAndroid && !this.isIos)) {
        return false
      }
      return true
    },
  },
  methods: {
    hideBlock() {
      this.$store.commit('internal/setAppMobileInstallationBlockHiddenAt', Date.now())
    },
  },
}
</script>
