<template>
  <div
    class="c-sidebarEntry o-flex -alignCenter"
    data-cy="SidebarProfile">
    <div
      class="c-avatar -medium u-marginRight3"
      data-cy="SidebarProfile__avatar">
      <CurrentUserAvatar
        class="c-avatar__photo" />
    </div>
    <div class="u-overflowXHidden">
      <div class="t-body3 t-fontWeightMedium t-overflowEllipsis">{{ username }}</div>
      <div class="t-caption1 u-colorDarkMediumEmphasis t-overflowEllipsis">{{ email }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      avatar: 'auth/avatar',
      username: 'auth/username',
      email: 'auth/email',
    }),
  },
}
</script>
