<template>
  <div v-if="advertisements">
    <AdCard
      v-for="advertisement in advertisements"
      :key="advertisement.id"
      :advertisement="advertisement"
      :centered="centered"
      :only-rectangle="onlyRectangle"
      :desktop-row="desktopRow" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import AdCard from '@/modules/advertisements/components/AdCard.vue'

export default {
  props: {
    position: {
      required: true,
      type: Number,
    },
    onlyRectangle: {
      required: false,
      type: Boolean,
      default: false,
    },
    centered: {
      required: false,
      type: Boolean,
      default: false,
    },
    textOnly: {
      required: false,
      type: Boolean,
      default: false,
    },
    desktopRow: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      hasModule: 'auth/hasModule',
      getAdvertisements: 'advertisements/advertisementsForPosition',
    }),
    advertisements() {
      if (!this.hasModule.advertisements) {
        return []
      }
      let ads = this.getAdvertisements(this.position)
      if (this.textOnly) {
        ads = ads.filter((ad) => !!ad.description)
      }
      return ads
    },
  },
  components: {
    AdCard,
  },
}
</script>
