<template>
  <div>
    <div
      :class="colorClass"
      class="o-flex -alignCenter t-caption1 t-fontWeightMedium"
      data-cy="AppointmentEntry">
      <AppointmentIcon
        :appointment="appointment"
        class="u-marginRight2" />
      <div class="o-flex -wrap s-metaWrapper">
        <div
          :class="{
            't-linethrough': appointment.is_cancelled,
          }">
          {{ appointment.start_date | date(null, 'eeeeee. dd.MM.yyyy · HH:mm') }}
        </div>
        <div
          v-if="appointment.badge"
          class="c-badge -text t-caption2 t-fontWeightMedium"
          :class="{
            '-important': appointment.badgeType !== 'success',
            '-success': appointment.badgeType === 'success',
            '-neutral': appointment.badgeType === 'neutral',
          }">
          <SvgIcon
            v-if="appointment.badgeType === 'success'"
            type="checkmark"
            tiny />
          {{ appointment.badge }}
        </div>
      </div>
    </div>
    <div
      :class="{
        's-pullRight': !minimal,
        'u-marginTop1': minimal,
        'u-colorDarkMediumEmphasis': appointment.is_cancelled,
        'u-colorDark': !appointment.is_cancelled,
      }"
      class="t-body3 t-fontWeightMedium t-breakWords"
      data-cy="AppointmentEntry__title">
      <template v-if="appointment.is_cancelled">
        {{ $t('appointments.cancelled') }}:
      </template>
      {{ appointment.name }}
    </div>
    <DaysLeft
      v-if="hasDayCountdown"
      :end-date="appointment.start_date"
      :class="{
        's-pullRight': !minimal,
      }"
      class="u-colorImportant u-marginTop2" />
    <div
      v-if="appointment.location && !appointment.is_cancelled"
      :class="{
        's-pullRight': !minimal,
      }"
      class="t-longLink t-caption1 t-clamp2 u-colorDarkMediumEmphasis u-marginTop1">
      {{ appointment.location }}
    </div>
  </div>
</template>

<script>
import DaysLeft from '@/components/DaysLeft.vue'
import AppointmentIcon from '@/modules/appointments/components/AppointmentIcon.vue'

export default {
  props: {
    appointment: {
      type: Object,
      required: true,
    },
    minimal: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    colorClass() {
      if (this.appointment.is_cancelled) {
        return 'u-colorError'
      }
      if (this.appointment.important) {
        return 'u-colorImportant'
      }
      return 'u-colorPrimary'
    },
    hasDayCountdown() {
      return (
        !this.minimal
        && (this.appointment.type === 'COURSE' || this.appointment.type === 'TEST')
        && this.appointment.important
      )
    },
  },
  components: {
    AppointmentIcon,
    DaysLeft,
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/config';

$iconWidth: 25px;

// offset so the icon stands of itself
.s-pullRight {
  margin-left: #{$iconWidth + $spacing-2};
}
.s-metaWrapper {
  column-gap: $spacing-2;
}
</style>
