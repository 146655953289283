export default [
  {
    component: () => import('@/modules/pages/views/PageDetails.vue'),
    name: 'details',
    path: '/page/:id',
  },
  {
    component: () => import('@/modules/pages/views/PageDetailsPublic.vue'),
    name: 'public',
    path: '/public/page/:id',
    meta: {
      divided: false,
      layout: 'Authentication',
      requiresAuth: false,
    },
  },
]
