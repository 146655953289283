var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-progress",class:{
    '-slim': _vm.slim,
    '-noTitle': !_vm.title,
  },attrs:{"data-cy":"ProgressBar"}},[(_vm.title)?_c('div',{staticClass:"c-progress__title"},[_vm._v(" "+_vm._s(_vm.title)+" ("+_vm._s(_vm.finished)+"/"+_vm._s(_vm.total)+") ")]):_vm._e(),_c('div',{staticClass:"o-flex -alignCenter"},[_c('div',{staticClass:"o-flex__item -grow"},[_c('div',{staticClass:"c-progress__bar"},_vm._l((_vm.parts),function(amount,index){return _c('div',{key:index,staticClass:"c-progress__determinate",style:({
            width: `${amount / _vm.total * 100}%`,
          })})}),0)]),(!_vm.noLabel)?_c('div',{staticClass:"c-progress__percentage o-flex__item",class:{
        'u-colorPrimary': _vm.finishedPercentage,
        'u-colorDarkLightEmphasis': !_vm.finishedPercentage,
      }},[_vm._v(" "+_vm._s(_vm.finishedPercentage)+"% ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }