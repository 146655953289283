export default {
  getNotificationSetting(state) {
    if (!state.notificationSettings) {
      return () => null
    }
    return (notification, setting) => {
      const notificationSetting = state.notificationSettings.find((notificationSettingEntry) => notificationSettingEntry.notification === notification)
      if (!notificationSetting) {
        return null
      }
      return notificationSetting[setting]
    }
  },
  notificationSettings: (state) => state.notificationSettings,
  availableNotificationSettings(state) {
    if (!state.notificationSettings) {
      return null
    }
    return state.notificationSettings.filter((n) => n.enabled && n.allowedToDeactivate)
  },
  tags: (state) => state.tags,
  vouchers: (state) => state.vouchers,
}
