import constants from '@/constants'

const defaultState = {
  webinars: [],
  recordings: [],
  filter: constants.WEBINARS.FILTER_LEARNINGROOMS,
  sort: constants.WEBINARS.SORT_TOPIC_ASC,
  searchQuery: null,
  searchOpen: false,
}

export default defaultState
