import Vue from 'vue'

export default {
  setAllNews(state, news) {
    state.news = news
  },
  setNewsEntry(state, newsEntry) {
    if (state.news === null) {
      state.news = {}
    }
    Vue.set(state.news, newsEntry.id, newsEntry)
  },
}
