export default async function (store) {
  if (!store.getters['auth/isLoggedIn']) {
    return
  }
  if ('requestIdleCallback' in window) {
    window.requestIdleCallback(() => {
      store.dispatch('pages/updatePages')
    }, {
      timeout: 5000,
    })
  } else {
    store.dispatch('pages/updatePages')
  }
}
