import ProfileAPI from '@/modules/profile/api/profile'

export default {
  updateNotificationSettings({ commit }) {
    return ProfileAPI.getNotificationSettings().then((response) => {
      commit('setNotificationSettings', response.notifications)
    })
  },
  updateTags({ commit }) {
    return ProfileAPI.getTags().then((tags) => {
      commit('setTags', tags)
    })
  },
  updateVouchers({ commit }) {
    return ProfileAPI.getVouchers().then((vouchers) => {
      commit('setVouchers', vouchers)
    })
  },
}
