import constants from '@/constants'

const defaultState = {
  tests: [],
  testDetails: {},
  submissions: {},
  filter: constants.TESTS.FILTER_AVAILABLE,
  subFilters: [],
  sort: constants.TESTS.SORT_NAME_ASC,
  searchQuery: null,
  searchOpen: false,
}

export default defaultState
