import Vue from 'vue'

import getI18n from '@/logic/i18nInstance'

function getID() {
  return `${(new Date()).getTime()}-${Math.random()}`
}

export default {
  addNotification(state, notification) {
    if (!notification.title && !notification.actions) {
      return
    }
    notification.id = getID()
    state.notifications.push(notification)
  },
  addError(state, notification) {
    notification.id = getID()
    notification.topic = getI18n().t('notifications.error')
    state.notifications.push(notification)
  },
  removeNotification(state, notification) {
    const idx = state.notifications.findIndex((n) => n.id === notification.id)
    Vue.delete(state.notifications, idx)
  },
}
