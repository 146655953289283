import { differenceInDays, parse } from 'date-fns'

import DateManager from '@/logic/DateManager'

export default {
  computed: {
    endsSoon() {
      return this.daysLeft <= 5
    },
    daysLeft() {
      if (!this.test.active_until) {
        return null
      }
      const activeUntil = parse(this.test.active_until, 'yyyy-MM-dd HH:mm:ss', new Date())
      return differenceInDays(activeUntil, DateManager.now())
    },
  },
}
