module.exports = {
  challenging_questions: {
    failrate: '%{failrate}% lagen falsch',
    title: 'Schwerste Fragen der Woche',
    answered_correctly: 'Du hast diese Frage richtig beantwortet',
    test_my_knowledge: 'Hättest du es gewusst?',
  },
  rank: 'Rang',
  wins: 'Siege',
  by_wins: 'nach Siegen',
  answers: 'Antworten',
  answers_correct_percentage: '%{percentage}% richtig',
  win_percentage: '%{percentage}% gewonnen',
  correct_answers_14_days: 'Richtige Antworten letzte 14 Tage',
  correct_answers: '%{answers} richtige Antwort | %{answers} richtige Antworten',
  answers_by_category: 'Antworten nach Kategorie',
}
