import constants from '@/constants'

const defaultState = {
  courses: null,
  contentWBTEvents: {},
  courseDetails: null,
  participations: {},
  categoryFilters: [],
  filter: constants.COURSES.FILTER_ACTIVE,
  sort: constants.TESTS.SORT_NAME_ASC,
  searchQuery: null,
  searchOpen: false,
}

export default defaultState
