export default {
  setChallengingQuestions(state, challengingQuestions) {
    state.challengingQuestions = challengingQuestions
  },
  setTopPlayers(state, topPlayers) {
    state.topPlayers = topPlayers
  },
  setTopTeams(state, topTeams) {
    state.topTeams = topTeams
  },
  setMyQuizStats(state, myQuizStats) {
    state.myQuizStats = myQuizStats
  },
}
