module.exports = {
  no_pass: 'Nicht bestanden',
  not_passed_percentage: 'Noch nicht bestanden ({percentage}%)',
  pass: 'Bestanden',
  passed_percentage: 'Bestanden mit {percentage}%',
  start_test: 'Test starten',
  continue_test: 'Test fortsetzen',
  repeat_test: 'Test wiederholen',
  only_x_days_left: 'Nur noch einen Tag verfügbar | Nur noch {x} Tage verfügbar',
  download_certificate: 'Zertifikat herunterladen',
  with_certificate: 'Mit Zertifikat',
  attempts_left: '1 Versuch übrig | {attemptsLeft} Versuche übrig',
  attempts_left_of: '{remaining} von {total} übrig',
  points_needed: '{needed} von {total} Punkten',
  show_all_tests: 'Alle Tests anzeigen',
  sortLabels: {
    name_asc: 'Alphabetisch (A-Z)',
    name_desc: 'Alphabetisch (Z-A)',
    published_at_desc: 'Datum (neuste zuerst)',
    published_at_asc: 'Datum (älteste zuerst)',
  },
  sortShortLabels: {
    name_asc: 'A-Z',
    name_desc: 'Z-A',
    published_at_desc: 'Neuste',
    published_at_asc: 'Älteste',
  },
  filters: {
    passed: 'Bestanden',
    not_passed: 'Nicht bestanden',
    expires_soon: 'Nur noch kurz verfügbar',
    has_certificate: 'Mit Zertifikat',
  },
  submissions: {
    date: 'Datum',
    status: 'Status',
    result: 'Ergebnis',
    no_results: 'Noch keine Versuche abgelegt.',
    passed: 'Bestanden',
    not_passed: 'Nicht bestanden',
    question_x_of_y: 'Frage {x} von {y}',
  },
  n_points: '1 Punkt | {points} Punkte',
  duration: '1 Minute | {minutes} Minuten',
  tests_todo: 'Ausstehende Tests',
  earned_certificate: 'Zertifikat erworben',
  your_result: 'Dein Ergebnis',
  nice_you_passed: 'Yeah! Du hast bestanden',
  sadly_not_passed: 'Leider nicht bestanden',
  answers: 'Antworten',
  your_answers: 'Deine Antworten',
  test: 'Test',
  available_until: 'Deadline:',
  available_tries: 'Versuche:',
  questions: 'Fragen:',
  necessary_percentage: 'Bestanden ab:',
  search_test: 'Test suchen',
  currently_no_tests_visible: 'Hier gibt es aktuell keine Tests.',
  test_from: 'Testergebnis vom %{date}',
  will_receive_certificate: 'Für das Bestehen dieses Tests erhältst du ein Zertifikat.',
  unlimited_repetitions: 'Unbegrenzt',
}
