import AppointmentsAPI from '@/modules/appointments/api/appointments'

export default {
  updateAppointments({ commit, dispatch, rootGetters }) {
    if (!rootGetters['auth/hasModule'].appointments) {
      return
    }
    if (rootGetters['auth/hasModule'].competitions) {
      dispatch('competitions/updateCompetitions', null, { root: true })
    }
    if (rootGetters['auth/hasModule'].courses) {
      dispatch('courses/updateCourses', null, { root: true })
    }
    if (rootGetters['auth/hasModule'].tests) {
      dispatch('tests/updateTests', null, { root: true })
    }
    if (rootGetters['auth/hasModule'].webinars) {
      dispatch('webinars/updateWebinars', null, { root: true })
    }
    AppointmentsAPI.getAppointments().then((data) => {
      const keyedAppointments = {}
      data.appointments.forEach((appointment) => {
        keyedAppointments[appointment.id] = appointment
      })
      commit('setAppointments', keyedAppointments)
    })
  },
}
