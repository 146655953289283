<template>
  <a
    v-if="href"
    :href="href"
    :target="target"
    @click="handleClick">
    <slot />
  </a>
</template>

<script>
export default {
  props: {
    href: {
      type: String,
      required: true,
    },
    target: {
      type: String,
      required: false,
      default: '_blank',
    },
  },
  methods: {
    handleClick(e) {
      if (typeof window.Capacitor !== 'undefined') {
        e.preventDefault()
        window.Capacitor.Plugins.Browser.open({
          url: this.href,
        })
        return false
      }
      return true
    },
  },
}
</script>
