let localStore = null

function getReplacedSVGContent(src) {
  return fetch(src).then((response) => response.text()).then((svg) => {
    if (!localStore || !localStore.getters['auth/config']) {
      return svg
    }
    const replacements = localStore.getters['auth/config'].colors
    const colors = {
      '#FF4C81': 'secondary',
      '#19BFD4': 'primary',
      '#00AEC8': 'primary',
    }
    Object.keys(colors).forEach((color) => {
      if (typeof replacements[colors[color]] !== 'undefined') {
        const newColor = replacements[colors[color]]
        const regex = new RegExp(color, 'gi')
        svg = svg.replace(regex, `rgb(${newColor.r},${newColor.g},${newColor.b})`)
      }
    })
    return svg
  })
}

function handleSVGs(node) {
  if (node.tagName === 'IMG' && node.src.indexOf('.svg') !== -1) {
    getReplacedSVGContent(node.src).then((data) => {
      node.src = `data:image/svg+xml;base64,${btoa(data)}`
    })
  } else if (typeof node.getElementsByTagName !== 'undefined') {
    const childImages = node.getElementsByTagName('img')
    if (childImages.length) {
      // Disabling eslint because it whines about generator runtime, but we actually want this for loop for iterating over a nodeList
      /* eslint-disable */
        for (const child of childImages) {
          handleSVGs(child)
        }
        /* eslint-enable */
    }
  }
}

export default async function (store) {
  if (typeof window.Capacitor === 'undefined') {
    return
  }
  localStore = store

  const targetNode = document.body
  const config = { childList: true, subtree: true }
  const callback = function (mutationsList) {
    mutationsList.forEach((mutation) => {
      if (mutation.type === 'childList') {
        mutation.addedNodes.forEach((node) => {
          handleSVGs(node)
        })
      } else if (mutation.type === 'attributes') {
        handleSVGs(mutation.target)
      }
    })
  }
  const observer = new MutationObserver(callback)
  observer.observe(targetNode, config)
}
