<template>
  <div class="o-flex">
    <div class="c-avatar -medium">
      <img
        :src="item.avatar_url"
        class="c-avatar__photo" />
    </div>
    <div class="t-body3 u-marginLeft2">
      {{ item.displayname }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: { required: true },
    searchText: { required: true },
  },
}
</script>
