var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{staticClass:"c-card -clickable",class:{
    '-important': !!_vm.appointment.important,
  },attrs:{"to":_vm.appointment.route || {
    name: 'appointments.details',
    params: {
      appointmentId: _vm.appointment.id,
    },
  },"data-cy":"AppointmentCard"}},[_c('div',{staticClass:"c-card__content"},[_c('AppointmentEntry',{attrs:{"appointment":_vm.appointment}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }