import api from '@/logic/api'
import store from '@/store'

export default {
  login(email, password) {
    const appId = store.getters['auth/appId']
    const profileId = store.getters['auth/profileId']
    return api.post('/login', {
      email,
      password,
      appId,
      profileId,
    })
  },
  signup({
    username, email, password, voucher, tags, firstname, lastname, meta,
  }) {
    const appId = store.getters['auth/appId']
    const profileId = store.getters['auth/profileId']
    return api.post('/signup', {
      appId,
      profileId,
      email,
      password,
      username,
      tags,
      voucher,
      firstname,
      lastname,
      meta,
    })
  },
  checkSlug(slug) {
    return api.get(`/check-slug?slug=${encodeURIComponent(slug)}`)
  },
  testaccount(username) {
    const appId = store.getters['auth/appId']
    const profileId = store.getters['auth/profileId']
    return api.post('/tmpaccount', { username, appId, profileId })
  },
  getAuthPages() {
    const appId = store.getters['auth/appId']
    const profileId = store.getters['auth/profileId']
    return api.get(`/public/pages/auth/${appId}/${profileId}`)
  },
  resetPassword(email) {
    const appId = store.getters['auth/appId']
    return api.post('/reset-password', { email, appId })
  },
  getSignupData() {
    const appId = store.getters['auth/appId']
    const profileId = store.getters['auth/profileId']
    return api.get(`/signup/${appId}/${profileId}`)
  },
  getAdminContact() {
    const appId = store.getters['auth/appId']
    const profileId = store.getters['auth/profileId']
    return api.get(`/login/contact/${appId}/${profileId}`)
  },
  acceptTos() {
    return api.post('/accept-tos')
  },
  getProfileConfig(identifier = null) {
    if (!identifier) {
      identifier = window.location.hostname
    }
    return api.get(`/appconfig/${encodeURIComponent(identifier)}`)
  },
  async setFCMToken(token) {
    let appStoreId = null
    let platform = null
    let model = null
    try {
      const appInfo = await window.Capacitor.Plugins.App.getInfo()
      const deviceInfo = await window.Capacitor.Plugins.Device.getInfo()
      if (appInfo && deviceInfo) {
        appStoreId = appInfo.id
        platform = deviceInfo.platform
        model = deviceInfo.model
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }

    return api.post('/fcm/token', {
      token,
      appStoreId,
      platform,
      model,
    })
  },
  getOpenIdAuthUrl(nativeAppId) {
    const profileId = store.getters['auth/profileId']
    let url = `/openid/getAuthUrl/${profileId}`
    if (nativeAppId) {
      url += `/${nativeAppId}`
    }
    return api.get(url)
  },
  loginViaToken(token) {
    const appId = store.getters['auth/appId']
    const profileId = store.getters['auth/profileId']
    return api.post('/openid/tokenLogin', {
      appId,
      profileId,
      token,
    })
  },
  loginViaInternalToken(token) {
    const appId = store.getters['auth/appId']
    const profileId = store.getters['auth/profileId']
    return api.post('/internal-sso/tokenLogin', {
      appId,
      profileId,
      token,
    })
  },
}
