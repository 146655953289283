<template>
  <div id="app">
    <router-view />
    <LoadingIndicator />
    <NotificationsList />
    <KeywordModal />
    <portal-target
      multiple
      name="modal" />
  </div>
</template>

<script>
import LoadingIndicator from '@/components/LoadingIndicator.vue'
import NotificationsList from '@/layouts/partials/NotificationsList.vue'
import KeywordModal from '@/modules/keywords/components/KeywordModal.vue'

export default {
  created() {
    if (window.Capacitor) {
      window.Capacitor.Plugins.SplashScreen.hide()
    }
  },
  components: {
    NotificationsList,
    LoadingIndicator,
    KeywordModal,
  },
}
</script>
