export default [
  {
    component: () => import('@/modules/news/views/AllNews.vue'),
    name: 'news',
    path: '/news',
    meta: {
      requiresModule: 'news',
    },
  },
  {
    component: () => import('@/modules/news/views/NewsEntry.vue'),
    name: 'details',
    path: '/news/:id',
    meta: {
      requiresModule: 'news',
    },
  },
]
