<template>
  <div>
    <SlideOnMobile class="u-marginTop3 u-marginBottom3 u-marginBottom7@tabletPortrait">
      <div class="s-quizContainer">
        <StartQuizSmall />
        <template v-if="activeQuizzes">
          <div
            v-for="quiz in activeQuizzes"
            :key="quiz.id">
            <QuizCard :quiz="quiz" />
          </div>
        </template>
      </div>
    </SlideOnMobile>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import QuizCard from '@/modules/quizzes/components/QuizCard.vue'
import StartQuizSmall from '@/modules/quizzes/components/StartQuizSmall.vue'

export default {
  created() {
    this.$store.dispatch('quizzes/updateActiveQuizzes')
  },
  computed: {
    ...mapGetters({
      activeQuizzes: 'quizzes/activeQuizzes',
    }),
  },
  components: {
    QuizCard,
    StartQuizSmall,
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/config';

.s-quizContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: $grid-gutter;
  grid-row-gap: $grid-gutter;
}

@media #{$mq-tablet-portrait} {
  .s-quizContainer {
    grid-template-columns: 1fr 1fr;
  }
  .s-callToAction {
    grid-row-start: 1;
    grid-row-end: 1;
  }
}

@media #{$mq-desktop-small} {
  .s-quizContainer {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
</style>
