import { differenceInSeconds } from 'date-fns'

import { getServiceworkerRegistration } from '@/logic/init/registerServiceWorker'

export default {
  updateServiceworker({ commit, getters }) {
    const registration = getServiceworkerRegistration()

    if (!registration || typeof window.capacitor !== 'undefined') {
      return
    }
    const maxUpdateInterval = 60 * 5
    if (differenceInSeconds(new Date(), getters.lastServiceWorkerUpdate) > maxUpdateInterval) {
      registration.update()
      commit('setLastServiceWorkerUpdate', Date.now())
    }
  },
}
