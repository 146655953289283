import Vue from 'vue'

export default {
  setNotificationSettings(state, settings) {
    Vue.set(state, 'notificationSettings', settings)
  },
  setNotificationSetting(state, { notification, setting, value }) {
    const idx = state.notificationSettings.findIndex((notificationSettingEntry) => notificationSettingEntry.notification === notification)
    Vue.set(state.notificationSettings, idx, {
      ...state.notificationSettings[idx],
      [setting]: value,
    })
  },
  setTags(state, tags) {
    Vue.set(state, 'tags', tags)
  },
  setVouchers(state, vouchers) {
    Vue.set(state, 'vouchers', vouchers)
  },
}
