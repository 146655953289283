import Vue from 'vue'

export default {
  setMyTeams(state, teams) {
    state.myTeams = teams
  },
  setTeam(state, team) {
    Vue.set(state.teams, team.id, team)
  },
}
