import deepmerge from 'deepmerge'

import { getPersistedState } from '@/logic/statePersisting'

const defaultState = {
  authPages: null,
  config: null,
  fcmToken: null,
  installPromptHidden: false,
  locale: 'de',
  browserLocaleDetected: false,
  localeManuallySelected: false,
  token: null,
  user: null,
}

const persistedState = getPersistedState('auth')

export default deepmerge(defaultState, persistedState)
