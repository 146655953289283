<template>
  <div
    v-if="challengingQuestions !== null && challengingQuestions.length > 0"
    class="u-marginBottom3 u-marginBottom7@tabletPortrait">
    <div class="t-body3 u-marginBottom3 u-colorDarkLightEmphasis">{{ $t('analytics.challenging_questions.test_my_knowledge') }}</div>
    <div class="o-grid u-marginBottom2">
      <div class="o-grid__cell -c12 -c6@tabletPortrait -c4@desktopSmall">
        <div class="c-card">
          <div class="c-card__header -smallHeight -primaryBg -absoluteImage">
            <img
              src="/img/no-connection.svg"
              style="right: -50px" />
          </div>
          <div class="c-card__content">
            <div class="t-h3 t-fontWeightSemibold">{{ $t('analytics.challenging_questions.title') }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="o-grid -equalHeight">
      <template v-if="challengingQuestions !== null">
        <div
          v-for="question in challengingQuestions"
          :key="question.id"
          class="o-grid__cell -c12 -c6@tabletPortrait -c4@desktopSmall">
          <ChallengingQuestionCard :question="question" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import ChallengingQuestionCard from '@/modules/analytics/components/ChallengingQuestionCard.vue'

export default {
  created() {
    this.$store.dispatch('analytics/updateChallengingQuestions')
  },
  computed: {
    ...mapGetters({
      challengingQuestions: 'analytics/challengingQuestions',
    }),
  },
  components: {
    ChallengingQuestionCard,
  },
}
</script>
