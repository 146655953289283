<template>
  <div
    v-if="powerlearningStats"
    class="c-sidebarEntry"
    data-cy="SidebarPowerlearning">
    <div
      class="t-body3 t-fontWeightMedium u-marginBottom3"
      data-cy="SidebarPowerlearning__title">
      {{ $t('generic.powerlearning') }}
    </div>
    <ProgressBar
      class="u-marginTop1"
      :current="[powerlearningStats[3], powerlearningStats[2], powerlearningStats[1]]"
      :total="powerlearningStats.total" />
    <div
      class="t-caption1"
      data-cy="SidebarPowerlearning__questionsLearned">
      {{ $t('powerlearning.x_of_y_questions_learned', { x: powerlearningStats[3], y: powerlearningStats.total }) }}
    </div>

    <router-link
      :to="{
        name: 'powerlearning.dashboard',
      }"
      class="u-colorPrimary t-body3 t-fontWeightMedium t-noLinkAppearance u-inlineBlock u-marginTop2"
      data-cy="SidebarPowerlearning__openPowerlearning">
      {{ $t('powerlearning.go_to_powerlearning') }}
      <SvgIcon
        type="chevron_right"
        tiny />
    </router-link>
  </div>
</template>

<script>
import ProgressBar from '@/components/ProgressBar.vue'
import LocalPowerlearning from '@/modules/powerlearning/api/localPowerlearning'

export default {
  data() {
    return {
      powerlearningStats: null,
    }
  },
  created() {
    LocalPowerlearning.getCategoriesStatistics().then((groups) => {
      this.powerlearningStats = LocalPowerlearning.getCategorygroupBoxStatistics(groups)
    })
  },
  components: {
    ProgressBar,
  },
}
</script>
