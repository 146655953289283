import Vue from 'vue'
import Vuex from 'vuex'

import actions from './actions'
import advertisements from './advertisements/index'
import analytics from './analytics/index'
import appointments from './appointments/index'
import auth from './auth/index'
import competitions from './competitions/index'
import courses from './courses/index'
import directMessages from './directmessages/index'
import feedback from './feedback/index'
import forms from './forms/index'
import getters from './getters'
import internal from './internal/index'
import keywords from './keywords/index'
import learningmaterials from './learningmaterials/index'
import likes from './likes/index'
import mutations from './mutations'
import news from './news/index'
import notifications from './notifications/index'
import pages from './pages/index'
import powerlearning from './powerlearning/index'
import profile from './profile/index'
import quizzes from './quizzes/index'
import state from './state'
import teams from './teams/index'
import tests from './tests/index'
import todolists from './todolists/index'
import webinars from './webinars/index'

import { statePersistVuexPlugin } from '@/logic/statePersisting'

Vue.use(Vuex)
export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
    advertisements,
    analytics,
    appointments,
    auth,
    competitions,
    courses,
    feedback,
    forms,
    internal,
    learningmaterials,
    likes,
    news,
    notifications,
    pages,
    powerlearning,
    profile,
    quizzes,
    teams,
    tests,
    todolists,
    webinars,
    keywords,
    directMessages,
  },
  plugins: [statePersistVuexPlugin],
})
