<template>
  <div
    class="u-marginTop3 u-marginTop0@tabletLandscape"
    data-cy="HomeView">
    <h1 class="t-h2 u-colorDark u-marginBottom3 u-marginTop0">
      {{ $t('generic.hi_name', { name: displayname }) }}
      {{ $t('generic.see_whats_new') }}
    </h1>

    <DirectMessages />
    <component
      :is="getHomeComponent(component.type)"
      v-for="(component, index) in homeComponents"
      :key="component.id"
      :index="index"
      :settings="component.settings"
      class="u-marginBottom6 u-marginTop6" />

    <portal to="sidebar">
      <SidebarProfile />
      <SidebarPowerlearning v-if="hasModule.powerlearning" />
      <AppointmentsSidebar />
      <SidebarLastViewed />
    </portal>
    <portal to="footer">
      <DefaultFooter />
    </portal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import DefaultFooter from '@/layouts/partials/DefaultFooter.vue'
import SidebarLastViewed from '@/layouts/partials/SidebarLastViewed.vue'
import SidebarPowerlearning from '@/layouts/partials/SidebarPowerlearning.vue'
import Helpers from '@/logic/helpers'
import AnalyticsAPI from '@/modules/analytics/api/analytics'
import AppointmentsSidebar from '@/modules/appointments/components/AppointmentsSidebar.vue'
import DirectMessages from '@/modules/directmessages/components/DirectMessages.vue'
import AdvertisementsBlock from '@/modules/global/components/home/AdvertisementsBlock.vue'
import AppmobileinstallationBlock from '@/modules/global/components/home/AppmobileinstallationBlock.vue'
import AppointmentsBlock from '@/modules/global/components/home/AppointmentsBlock.vue'
import ChallengingquestionsBlock from '@/modules/global/components/home/ChallengingquestionsBlock.vue'
import CompetitionsBlock from '@/modules/global/components/home/CompetitionsBlock.vue'
import CoursesBlock from '@/modules/global/components/home/CoursesBlock.vue'
import LearningmaterialsBlock from '@/modules/global/components/home/LearningmaterialsBlock.vue'
import NewsBlock from '@/modules/global/components/home/NewsBlock.vue'
import PowerlearningBlock from '@/modules/global/components/home/PowerlearningBlock.vue'
import QuizBlock from '@/modules/global/components/home/QuizBlock.vue'
import TestsBlock from '@/modules/global/components/home/TestsBlock.vue'

export default {
  created() {
    this.checkForApplicationUpdate()
    AnalyticsAPI.viewHome()
    this.$store.dispatch('appointments/updateAppointments')
  },
  computed: {
    ...mapGetters({
      config: 'auth/config',
      hasModule: 'auth/hasModule',
      displayname: 'auth/displayname',
    }),
    homeComponents() {
      if (!this.config.home_components) {
        return []
      }

      return [...this.config.home_components]
    },
  },
  methods: {
    checkForApplicationUpdate() {
      this.$store.dispatch('internal/updateServiceworker')
    },
    getHomeComponent(type) {
      return `${Helpers.capitalize(type)}Block`
    },
  },
  components: {
    AdvertisementsBlock,
    AppmobileinstallationBlock,
    AppointmentsBlock,
    AppointmentsSidebar,
    ChallengingquestionsBlock,
    CompetitionsBlock,
    CoursesBlock,
    DefaultFooter,
    DirectMessages,
    LearningmaterialsBlock,
    NewsBlock,
    PowerlearningBlock,
    QuizBlock,
    SidebarLastViewed,
    SidebarPowerlearning,
    TestsBlock,
  },
}
</script>
