import api from '@/logic/api'

export default {
  getPages: () => api.get('/pages'),
  findPage: (id) => api.get(`/pages/${id}`),
  findPublicPage(id, profileId) {
    return api.get(`/public/pages/${id}/${profileId}`)
  },
  getTos: () => api.get('/tos'),
}
