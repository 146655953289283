import { render, staticRenderFns } from "./CommentCount.vue?vue&type=template&id=7197050e&scoped=true"
import script from "./CommentCount.vue?vue&type=script&lang=js"
export * from "./CommentCount.vue?vue&type=script&lang=js"
import style0 from "./CommentCount.vue?vue&type=style&index=0&id=7197050e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7197050e",
  null
  
)

export default component.exports