import { getSentryInstance } from '@/logic/init/sentry'

let localStore = null

function updateOnlineStatus() {
  let offline = false
  if (typeof navigator.onLine === 'boolean') {
    offline = !navigator.onLine
  }
  const Sentry = getSentryInstance()
  if (Sentry) {
    Sentry.addBreadcrumb({
      category: 'online',
      message: offline ? 'no' : 'yes',
    })
  }
  localStore.commit('setOffline', offline)
}
function setup(store) {
  localStore = store
  updateOnlineStatus()
  window.addEventListener('online', updateOnlineStatus)
  window.addEventListener('offline', updateOnlineStatus)
}

export const offlineHandlerSetup = setup
