export default [
  {
    component: () => import('@/modules/webinars/views/WebinarDashboard.vue'),
    name: 'dashboard',
    path: '/webinars',
    meta: {
      requiresModule: 'webinars',
    },
  },
  {
    component: () => import('@/modules/webinars/views/WebinarDisplay.vue'),
    name: 'details',
    path: '/webinars/:id',
    meta: {
      requiresModule: 'webinars',
    },
  },
  {
    component: () => import('@/modules/webinars/views/WebinarInternal.vue'),
    name: 'viewer',
    path: '/webinars/room/:id',
    meta: {
      contentBackgroundColor: '#2E3745',
      requiresModule: 'webinars',
    },
  },
  {
    component: () => import('@/modules/webinars/views/WebinarExternal.vue'),
    name: 'publicviewer',
    path: '/public/webinars/room/:token',
    meta: {
      layout: 'Authentication',
      requiresAuth: false,
      divided: false,
    },
  },
]
