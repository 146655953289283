var render = function render(_c,_vm){return _c('router-link',{staticClass:"c-card -clickable",attrs:{"tag":"a","to":{
    name: 'news.details',
    params: {
      id: _vm.props.news.id,
    },
  }}},[_c('div',{staticClass:"c-card__header -imageFill -primaryBg"},[(_vm.props.news.cover_image_url)?_c('img',{attrs:{"src":_vm.props.news.cover_image_url}}):(_vm.parent.$store.getters['auth/config'].settings.news_default_image)?_c('img',{attrs:{"src":_vm.parent.$store.getters['auth/config'].settings.news_default_image}}):_c('img',{staticStyle:{"object-fit":"contain"},attrs:{"src":"/img/no-connection.svg"}})]),_c('div',{staticClass:"c-card__content o-flex -spaceBetween -stretch"},[_c('div',[_c('div',{staticClass:"t-caption1 t-uppercase t-fontWeightMedium u-colorPrimary"},[_vm._v(" "+_vm._s(_vm.parent.$t('news.news'))+" ")]),_c('div',{staticClass:"t-body3 t-fontWeightMedium t-breakWords"},[_vm._v(" "+_vm._s(_vm.props.news.title)+" ")])]),_c('div',{staticClass:"o-flex -alignCenter -spaceBetween u-colorDarkLightEmphasis u-marginTop2"},[_c('div',{staticClass:"t-caption1"},[_vm._v(" "+_vm._s(_vm._f("date")(_vm.props.news.published_at))+" ")]),_c('div',{staticClass:"o-flex"},[_c('router-link',{staticClass:"t-noLinkAppearance",attrs:{"tag":"a","to":{
            name: 'news.details',
            params: {
              id: _vm.props.news.id,
            },
            hash: '#comments',
          }}},[_c(_vm.$options.components.CommentCount,{tag:"component",attrs:{"count":_vm.props.news.comment_count,"small":"","light":""}})],1),(_vm.parent.$store.getters['auth/config'].settings.enable_social_features)?_c(_vm.$options.components.LikeCount,{tag:"component",staticClass:"u-marginLeft3",attrs:{"count":_vm.props.news.likes_count,"liked":_vm.props.news.likes_it}}):_vm._e()],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }