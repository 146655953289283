<template>
  <div>
    <h1 class="t-h2 u-colorDark u-marginBottom3 u-marginTop0">
      {{ $t('nav.training') }}
    </h1>

    <div class="o-grid u-marginBottom6 u-marginBottom7@tabletPortrait">
      <router-link
        v-for="module in learningModules"
        :key="module.name"
        :to="{
          name: module.route,
        }"
        class="o-grid__cell -c3 u-colorDark t-noLinkAppearance">
        <div class="c-card -fullHeight">
          <div class="c-card__header -imageFill s-moduleImage__wrapper">
            <img
              :src="module.desktopImage"
              class="s-moduleImage" />
          </div>
          <div class="c-card__content">
            <div class="t-body3 t-fontWeightMedium">
              {{ module.name }}
            </div>
          </div>
        </div>
      </router-link>
    </div>

    <template v-if="hasModule.powerlearning">
      <div class="t-body3 u-colorDarkLightEmphasis u-marginBottom3 u-marginTop3">{{ $t('nav.powerlearning') }}</div>
      <RecentPowerlearningCategories
        class="u-marginBottom4 u-marginBottom7@tabletPortrait" />
    </template>

    <template v-if="hasModule.courses && activeCourses && activeCourses.length">
      <div class="t-body3 u-colorDarkLightEmphasis u-marginBottom3 u-marginTop3">{{ $t('courses.courses_todo') }}</div>
      <ActiveCourses class="u-marginBottom7" />
    </template>

    <div class="o-backdrop u-hide@tabletMiniDown">
      <img
        src="@/assets/images/learning/images-training-uebersicht.svg"
        class="o-backdrop__image"
        alt="Person exploring the universe" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import coursesImageDesktop from '@/assets/images/learning/kurse-desktop.svg'
import coursesImage from '@/assets/images/learning/kurse.svg'
import powerlearningImageDesktop from '@/assets/images/learning/powerlearning-desktop.svg'
import powerlearningImage from '@/assets/images/learning/powerlearning.svg'
import ActiveCourses from '@/modules/courses/components/ActiveCourses.vue'
import LocalPowerlearning from '@/modules/powerlearning/api/localPowerlearning'
import RecentPowerlearningCategories from '@/modules/powerlearning/components/RecentPowerlearningCategories.vue'

export default {
  data() {
    return {
      powerlearningStats: null,
    }
  },
  created() {
    if (this.hasModule.powerlearning) {
      this.loadPowerlearningStats()
    }
    if (this.hasModule.courses) {
      this.$store.dispatch('courses/updateCourses')
    }
  },
  computed: {
    ...mapGetters({
      hasModule: 'auth/hasModule',
      activeCourses: 'courses/activeCourses',
    }),
    learningModules() {
      const allModules = [
        {
          route: 'courses.dashboard',
          name: this.$t('generic.courses'),
          image: coursesImage,
          desktopImage: coursesImageDesktop,
          module: 'courses',
        },
        {
          route: 'powerlearning.dashboard',
          name: this.$t('generic.powerlearning'),
          image: powerlearningImage,
          desktopImage: powerlearningImageDesktop,
          module: 'powerlearning',
        },
      ]
      return allModules.filter((module) => this.hasModule[module.module])
    },
  },
  methods: {
    loadPowerlearningStats() {
      LocalPowerlearning.getCategoriesStatistics().then((groups) => {
        this.powerlearningStats = LocalPowerlearning.getCategorygroupBoxStatistics(groups)
      })
    },
  },
  components: {
    RecentPowerlearningCategories,
    ActiveCourses,
  },
}
</script>

<style lang="scss" scoped>
.s-moduleImage {
  object-position: top;
}
.s-moduleImage__wrapper {
  padding-top: 50%;
}
</style>
