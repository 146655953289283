import api from '@/logic/api'

export default {
  getLearningmaterials: () => api.get('/learning-materials'),
  searchLearningmaterials: (search, folderId) => api.post('/learning-materials/search', {
    search,
    folderId,
  }),
  findLearningmaterial: (id) => api.get(`/learning-materials/${id}`),
  storeLearningmaterialView: (id) => api.post('/view/learning-material', { id }),
  getAllWBTEvents: () => api.get('/learning-materials/wbt-events'),
  getWBTEvent: (id) => api.get(`/learning-materials/${id}/wbt-events`),
}
