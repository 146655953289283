<template>
  <transition name="slideup">
    <div
      v-show="!isOnline"
      class="o-flex -alignCenter c-offlineIndicator">
      <div class="o-flex__item -grow u-colorWhite">
        <SvgIcon
          type="offline_bolt"
          medium />
      </div>
      <div class="t-caption1 t-fontWeightMedium u-marginLeft2">
        {{ $t('generic.you_are_offline') }}
      </div>
    </div>
  </transition>
</template>

<script>
import { VueOnline } from 'vue-online'

export default {
  computed: {
    isOnline() {
      return VueOnline.isOnline
    },
  },
}
</script>
