export default [
  {
    component: () => import('@/modules/quizzes/views/QuizOverview.vue'),
    name: 'overview',
    path: '/quiz',
    meta: {
      requiresModule: 'quiz',
    },
  },
  {
    component: () => import('@/modules/quizzes/views/QuizStatistics.vue'),
    name: 'statistics',
    path: '/quizzes/statistics',
    children: [
      {
        path: '',
        name: 'mine',
        component: () => import('@/modules/quizzes/views/QuizStatistics/MyStats.vue'),
        meta: {
          requiresModule: 'quiz',
        },
      },
      {
        path: 'players',
        name: 'players',
        component: () => import('@/modules/quizzes/views/QuizStatistics/PlayerStats.vue'),
        meta: {
          requiresModule: 'quiz',
        },
      },
      {
        path: 'teams',
        name: 'teams',
        component: () => import('@/modules/quizzes/views/QuizStatistics/TeamStats.vue'),
        meta: {
          requiresModule: 'teams',
        },
      },
    ],
  },
  {
    component: () => import('@/modules/quizzes/views/QuizHistory.vue'),
    name: 'history',
    path: '/quizzes/history',
    meta: {
      requiresModule: 'quiz',
    },
  },
  {
    component: () => import('@/modules/quizzes/views/QuizCreate.vue'),
    name: 'create',
    path: '/quizzes/new',
    meta: {
      requiresModule: 'quiz',
    },
  },
  {
    component: () => import('@/modules/quizzes/views/QuizDetails.vue'),
    name: 'details',
    path: '/quizzes/:id',
    meta: {
      noMobileFooter: true,
      requiresModule: 'quiz',
      noMobileBottomPadding: true,
    },
  },
  {
    component: () => import('@/modules/quizzes/views/QuizRoundIntro.vue'),
    name: 'intro',
    path: '/quizzes/:id/intro',
    meta: {
      noMobileMenus: true,
      requiresModule: 'quiz',
    },
  },
  {
    component: () => import('@/modules/quizzes/views/QuizPlay.vue'),
    name: 'play',
    path: '/quizzes/:id/play',
    meta: {
      noMobileMenus: true,
      requiresModule: 'quiz',
    },
  },
  {
    component: () => import('@/modules/quizzes/views/QuizCategorySelect.vue'),
    name: 'categories',
    path: '/quizzes/:id/category',
    meta: {
      noMobileMenus: true,
      requiresModule: 'quiz',
    },
  },
]
