import { setColors, setName } from '@/logic/appConfig'

export default function () {
  setColors({
    primary: { r: 25, g: 191, b: 212 },
    secondary: { r: 255, g: 76, b: 129 },
    success: { r: 0, g: 202, b: 145 },
    medium_success: { r: 148, g: 202, b: 0 },
    error: { r: 247, g: 79, b: 79 },
    gold: { r: 254, g: 204, b: 0 },
    silver: { r: 175, g: 185, b: 191 },
    bronze: { r: 222, g: 144, b: 111 },
    highlight: { r: 253, g: 237, b: 183 },
    dark: { r: 61, g: 61, b: 83 },
    dark_medium_emphasis: { r: 121, g: 126, b: 139 },
    dark_light_emphasis: { r: 159, g: 169, b: 177 },
    white: { r: 255, g: 255, b: 255 },
    soft_highlight: { r: 244, g: 246, b: 250 },
    divider: { r: 228, g: 232, b: 235 },
    text_highlight: { r: 253, g: 237, b: 183 },
  })
  setName('keelearning')
}
