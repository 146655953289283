import { add } from 'date-fns'

import constants from '@/constants'
import api from '@/logic/api'
import DateManager from '@/logic/DateManager'

export const calculateParticipationEnd = (course, participation) => {
  if (!course || !participation) {
    return null
  }
  const startedAt = DateManager.parseModelDate(participation.created_at)
  const duration = {}
  switch (course.participation_duration_type) {
    case constants.COURSES.PARTICIPATION_DURATION_TYPE_DAYS:
      duration.days = course.participation_duration
      break
    case constants.COURSES.PARTICIPATION_DURATION_TYPE_WEEKS:
      duration.weeks = course.participation_duration
      break
    case constants.COURSES.PARTICIPATION_DURATION_TYPE_MONTHS:
      duration.months = course.participation_duration
      break
    default:
      break
  }
  return add(startedAt, duration)
}

const currentApiVersion = api.getAPIVersion()

export const compareVersion = (version) => {
  return currentApiVersion.localeCompare(version, undefined, {
    numeric: true,
  })
}
