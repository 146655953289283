module.exports = {
  back: 'Zurück',
  configure_notifications: 'Benachrichtigungen konfigurieren',
  correct: 'Richtig',
  date_until: 'Bis %{date}',
  days_left: 'Noch 1 Tag|Noch %{days} Tage',
  ending_today: 'Nur noch heute',
  forename: 'Vorname',
  powerlearning: 'Powerlearning',
  learningmaterials: 'Mediathek',
  tests: 'Tests',
  courses: 'Kurse',
  lastname: 'Nachname',
  loading: 'loading',
  mail: 'E-Mail',
  member: 'Mitglied | Mitglieder',
  multiple_choice: 'Multiple Choice',
  not_found_404: 'Diese Seite wurde leider nicht gefunden',
  notifications_title: 'Benachrichtigungen konfigurieren',
  notifications_confirm: 'Bitte bestätige die Nachfrage des Browsers',
  notification_successful_body: 'Du erhältst eine Benachrichtigung wenn du an der Reihe bist',
  notification_successful_title: 'Benachrichtigungen aktiviert',
  optional: '(optional)',
  password: 'Passwort',
  current_password: 'Bestehendes Passwort',
  current_password_placeholder: 'Dein aktuelles Passwort',
  confirm_password: 'Bitte bestätigen Sie die Änderung durch Eingabe Ihres Passworts',
  player: 'Spieler | Spieler',
  quiz_battle: 'Quiz-Battle',
  self: 'Ich',
  settings_saved: 'Die Einstellungen wurden erfolgreich gespeichert.',
  sort: 'Sortieren',
  started_at_date: 'Gestartet %{date}',
  starts_at_date: 'Startet am %{date}',
  to_home: 'Zur Startseite',
  username: 'Benutzername',
  realname: 'Realname',
  want_notifications: 'Möchtest du benachrichtigt werden wenn du wieder an der Reihe bist?',
  wrong: 'Falsch',
  user_search_placeholder: 'Name oder E-Mail...',
  user_search_placeholder_wo_email: 'Name...',
  offline: 'Offline',
  offline_message: 'Diese Funktion steht dir zur Verfügung sobald du wieder online bist.',
  close: 'Schließen',
  voucher_code: 'Voucher-Code',
  app_by_keeunit: 'Powered by <a href="https://www.keelearning.de" target="_blank">keeunit GmbH</a>',
  see_whats_new: 'Sieh dir an, was es Neues gibt:',
  what_do_you_want_to_learn_today: 'Was möchtest du heute lernen?',
  last_learned: 'Zuletzt gelernt',
  missing_module_title: 'Leider hast du auf diesen Inhalt keinen Zugriff.',
  missing_module_description: 'Bitte wende dich an deinen Administrator um Zugriff zu erhalten.',
  question_preview: 'Fragenvorschau',
  question_preview_unavailable: 'Diese Frage kann leider nicht angezeigt werden.',
  enter_query_to_start_search: 'Gib einen Suchbegriff ein um mit der Suche zu starten.',
  no_search_results: 'Keine Ergebnisse für deine Suche',
  no_results_for_filters: 'Keine Einträge für die gewählten Filter',
  results: 'Ergebnis | Ergebnisse',
  reset_search_filters: 'Alle Filter löschen',
  back_to_overview: 'Zurück zur Übersicht',
  filter: 'Filter:',
  filter_title: 'Filter',
  file_type: 'Dateityp',
  set_filters: 'Filter anwenden',
  new_content: 'Neue Inhalte',
  new: 'Neu',
  no_audio_support: 'Your browser does not support the audio element.',
  download: 'Herunterladen',
  filter_all: 'Alle',
  filter_unseen: 'Ungesehen',
  filter_seen: 'Gesehen',
  want_more: 'Du hast Lust auf mehr?',
  want_more_explanation: 'Hilf dabei, den Wissenschatz in deinem Unternehmen aufzubauen und das Quiz und Powerlearning zu erweitern.',
  total_progress: 'Gesamtfortschritt',
  back_to_home: 'Zurück zur Startseite',
  save: 'Speichern',
  abort: 'Abbrechen',
  dofilter: 'Filtern:',
  available: 'Verfügbar',
  not_available_anymore: 'Nicht mehr verfügbar',
  webinars: 'Webinare',
  minutes: '1 Minute | %{n} Minuten',
  from: 'Ab',
  you_are_offline: 'Du bist offline. Einige Funktionen sind nur verfügbar, wenn du online bist.',
  show_more: 'Mehr anzeigen',
  show_less: 'Weniger anzeigen',
  open_link: 'Link öffnen',
  please_choose: 'Bitte wählen',
  suggest_question: {
    suggest_new_question: 'Neue Frage vorschlagen',
    question_label: 'Frage',
    category_label: 'Kategorie',
    cant_load_suggestion_categories: 'Es konnten leider nicht alle nötigen Daten geladen werden. Bitte versuchen Sie es später erneut.',
    answers_explanation: 'Bitte gib eine richtige und drei falsche Antworten für deine Frage ein.',
    correct_answer_label: 'Richtige Antwort',
    wrong_answer_x_label: 'Falsche Antwort %{x}',
    submit_question: 'Frage einreichen',
    couldnt_suggest_question: 'Die Frage konnte leider nicht eingereicht werden',
    question_suggested_successfully: 'Vielen Dank! Wir prüfen deine Frage in Kürze.',
  },
  ad: 'Anzeige',
  submit: 'Absenden',
  page_x: 'Seite %{page}',
  page_x_of_y: 'Seite %{currentPage} von %{totalPages}',
  delete: 'Löschen',
  reply: 'Antworten',
  hi_name: 'Hi %{name}.',
  or: 'oder',
  x_days_left: 'Noch 1 Tag | Noch %{days} Tage',
  required: 'Pflichtfeld',
  x_days_from_start: '%{x} Tag ab Start | %{x} Tage ab Start',
  x_weeks_from_start: '%{x} Woche ab Start | %{x} Wochen ab Start',
  x_months_from_start: '%{x} Monat ab Start | %{x} Monate ab Start',
}
