import Vue from 'vue'

import constants from '@/constants'

export default {
  setWebinars(state, webinars) {
    Vue.set(state, 'webinars', webinars)
  },
  setRecordings(state, recordings) {
    Vue.set(state, 'recordings', recordings)
  },
  setFilter(state, filter) {
    state.filter = filter
  },
  setSort(state, sort) {
    state.sort = sort
  },
  setSearchQuery(state, query) {
    state.searchQuery = query
  },
  setSearchOpen(state, value) {
    state.searchOpen = value
  },
  toggleSearchOpen(state) {
    state.searchOpen = !state.searchOpen
  },
  resetFilters(state) {
    state.searchQuery = null
    state.searchOpen = false
    state.sort = constants.WEBINARS.SORT_TOPIC_ASC
  },
}
