import constants from '@/constants'

export default {
  allLearningmaterials(state, getters) {
    return getters.folders.reduce((allMaterials, folder) => allMaterials.concat(folder.materials), [])
  },
  latestLearningmaterials(state, getters) {
    return [...getters.allLearningmaterials].sort((a, b) => {
      if (a.published_at > b.published_at) {
        return -1
      }
      if (a.published_at < b.published_at) {
        return 1
      }
      return 0
    }).slice(0, 3)
  },
  lastViewedLearningmaterials(state, getters) {
    return [...getters.allLearningmaterials]
      .filter((x) => x.last_viewed_at !== null)
      .sort((a, b) => b.last_viewed_at.localeCompare(a.last_viewed_at))
      .slice(0, 5)
  },
  findLearningmaterial(state, getters) {
    if (!getters.folders.length) {
      return () => null
    }
    return (id) => getters.allLearningmaterials.find((learningmaterial) => learningmaterial.id === parseInt(id, 10))
  },
  findLearningmaterialFolder(state, getters) {
    if (!getters.folders.length) {
      return () => null
    }
    return (id) => getters.folders.find((folder) => folder.id === parseInt(id, 10))
  },
  findSubfolders(state, getters) {
    if (!getters.folders.length) {
      return () => []
    }
    return (id) => {
      const subfolders = getters.folders.filter((folder) => folder.parent_id === parseInt(id, 10))
      return subfolders.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }))
    }
  },
  folders(state) {
    return state.learningmaterialFolders
  },
  filteredLearningmaterials(state, getters) {
    if (!getters.allLearningmaterials) {
      return getters.allLearningmaterials
    }
    let materials = [...getters.allLearningmaterials]
    if (state.userSelectionFunction) {
      materials = materials.filter(state.userSelectionFunction)
    }
    if (state.typeFilters.length) {
      materials = materials.filter((material) => state.typeFilters.includes(material.type))
    }
    materials.sort((a, b) => {
      let descMultiplicator = 1
      if ([constants.LEARNINGMATERIALS.SORT_NAME_DESC, constants.LEARNINGMATERIALS.SORT_PUBLISHED_AT_DESC].includes(state.sort)) {
        descMultiplicator = -1
      }
      let field = 'title'
      if ([constants.LEARNINGMATERIALS.SORT_PUBLISHED_AT_DESC, constants.LEARNINGMATERIALS.SORT_PUBLISHED_AT_ASC].includes(state.sort)) {
        field = 'published_at'
      }
      return a[field].localeCompare(b[field], undefined, { numeric: true }) * descMultiplicator
    })

    return materials
  },
  findWBTEvents(state) {
    return (id) => (state.wbtEvents[parseInt(id, 10)] || [])
  },
  typeFilters(state) {
    return state.typeFilters
  },
  sort(state) {
    return state.sort
  },
  searchQuery(state) {
    return state.searchQuery
  },
  searchOpen(state) {
    return state.searchOpen
  },
  foundLearningmaterials(state) {
    return state.foundLearningmaterials
  },
}
