<template>
  <div
    class="o-backLink__wrapper"
    :class="{
      '-inline': inline,
    }"
    data-cy="BackLink">
    <div class="o-backLink__titleWrapper">
      <a
        :href="sanitizedPreviousRoute"
        class="o-backLink"
        @click="goBack">
        <SvgIcon
          type="chevron_left"
          class="o-backLink__icon" />
        <span
          class="o-backLink__desktop"
          data-cy="BackLink__desktopTitle">
          {{ $t('generic.back') }}
        </span>

        <span
          class="o-backLink__mobile"
          data-cy="BackLink__mobileTitle">
          {{ text }}
        </span>
      </a>
      <div class="u-hide@tabletMiniDown">
        <h1
          v-if="!hasTitleDesktopSlot && text"
          class="o-backLink__titleDesktop"
          data-cy="BackLink__titleDesktop">
          {{ text }}
        </h1>
        <slot name="title-desktop" />
      </div>
    </div>
    <slot name="action-desktop" />
  </div>
</template>

<script>
export default {
  props: [
    'fallback',
    'previous',
    'text',
    'inline',
  ],
  computed: {
    sanitizedPreviousRoute() {
      const route = this.previous || this.fallback
      return this.$router.resolve(route).resolved.fullPath
    },
    hasTitleDesktopSlot() {
      return typeof this.$slots['title-desktop'] !== 'undefined'
    },
  },
  methods: {
    goBack($event) {
      if (!this.previous) {
        // we have no previous history entry, use as real link
        return true
      }
      $event.preventDefault()
      $event.stopPropagation()
      this.$router.back()
      return false
    },
  },
}
</script>
