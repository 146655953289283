<template>
  <div
    v-if="hasCompetitions"
    data-cy="ActiveCompetitions">
    <div
      class="t-body3 u-colorDarkLightEmphasis"
      data-cy="ActiveCompetitions__title">
      {{ $t('competitions.title') }}
    </div>
    <SlideOnMobile class="u-marginTop3">
      <div class="o-grid -equalHeight">
        <div
          v-for="competition in competitions"
          :key="competition.id"
          class="o-grid__cell -c12 -c6@tabletPortrait -c4@desktopSmall">
          <CompetitionCard :competition="competition" />
        </div>
      </div>
    </SlideOnMobile>
    <div
      v-if="hasMissingData"
      class="o-grid u-marginTop2">
      <div class="o-grid__cell -c12 -c6@tabletPortrait -c4@desktopSmall">
        <AccountDataMissing />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import AccountDataMissing from '@/modules/competitions/components/AccountDataMissing.vue'
import CompetitionCard from '@/modules/competitions/components/CompetitionCard.vue'

export default {
  created() {
    this.$store.dispatch('competitions/updateCompetitions')
    this.$store.dispatch('competitions/updateRequiredCredentials')
  },
  computed: {
    ...mapGetters({
      competitions: 'competitions/competitions',
      hasMissingData: 'competitions/hasMissingData',
      hasModule: 'auth/hasModule',
    }),
    hasCompetitions() {
      return this.hasModule.competitions && this.competitions !== null && Object.keys(this.competitions).length > 0
    },
  },
  components: {
    CompetitionCard,
    AccountDataMissing,
  },
}
</script>
