import deepmerge from 'deepmerge'

import { getPersistedState } from '@/logic/statePersisting'

const defaultState = {
  challengingQuestions: null,
  topPlayers: null,
  topTeams: null,
  myQuizStats: null,
}

const persistedState = getPersistedState('analytics')

export default deepmerge(defaultState, persistedState)
