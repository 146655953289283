export default {
  setAllKeywords(state, keywords) {
    state.keywords = keywords
  },
  setSelectedKeywordId(state, keywordId) {
    state.selectedKeywordId = keywordId
  },
  setKeywordDescription(state, { keywordId, description }) {
    state.keywords.find((keyword) => keyword.id === keywordId).description = description
  },
}
