<template functional>
  <router-link
    tag="a"
    :to="{
      name: 'news.details',
      params: {
        id: props.news.id,
      },
    }"
    class="c-card -clickable">
    <div class="c-card__header -imageFill -primaryBg">
      <img
        v-if="props.news.cover_image_url"
        :src="props.news.cover_image_url" />
      <img
        v-else-if="parent.$store.getters['auth/config'].settings.news_default_image"
        :src="parent.$store.getters['auth/config'].settings.news_default_image" />
      <img
        v-else
        src="/img/no-connection.svg"
        style="object-fit: contain" />
    </div>
    <div class="c-card__content o-flex -spaceBetween -stretch">
      <div>
        <div class="t-caption1 t-uppercase t-fontWeightMedium u-colorPrimary">
          {{ parent.$t('news.news') }}
        </div>
        <div class="t-body3 t-fontWeightMedium t-breakWords">
          {{ props.news.title }}
        </div>
      </div>
      <div class="o-flex -alignCenter -spaceBetween u-colorDarkLightEmphasis u-marginTop2">
        <div class="t-caption1">
          {{ props.news.published_at | date }}
        </div>
        <div class="o-flex">
          <router-link
            tag="a"
            :to="{
              name: 'news.details',
              params: {
                id: props.news.id,
              },
              hash: '#comments',
            }"
            class="t-noLinkAppearance">
            <component
              :is="$options.components.CommentCount"
              :count="props.news.comment_count"
              small
              light />
          </router-link>

          <component
            :is="$options.components.LikeCount"
            v-if="parent.$store.getters['auth/config'].settings.enable_social_features"
            :count="props.news.likes_count"
            :liked="props.news.likes_it"
            class="u-marginLeft3" />
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import CommentCount from '@/modules/comments/components/CommentCount.vue'
import LikeCount from '@/modules/likes/components/LikeCount.vue'

export default {
  components: {
    CommentCount,
    LikeCount,
  },
}
</script>
