import PagesAPI from '@/modules/pages/api/pages'

export default {
  updatePages({ commit }) {
    return PagesAPI.getPages().then((data) => {
      const keyedPages = {}
      data.pages.forEach((page) => {
        keyedPages[page.id] = page
      })
      commit('setPagesList', keyedPages)
    })
  },
  findPage({ commit }, id) {
    return PagesAPI.findPage(id).then((data) => {
      commit('setPage', { data, id })
    })
  },
  findPublicPage({ commit, rootGetters }, id) {
    const profileId = rootGetters['auth/profileId']
    return PagesAPI.findPublicPage(id, profileId).then((data) => {
      commit('setPublicPage', { data, id })
    })
  },
  loadTos({ commit }) {
    return PagesAPI.getTos().then((data) => {
      commit('setTos', { data })
    })
  },
}
