import FeedbackAPI from '@/modules/feedback/api/feedback'

export default {
  updateCurrentFeedback({ commit }) {
    return FeedbackAPI.getFeedback().then((data) => {
      commit('setFeedback', data)
    })
  },
  updateFeedback({ dispatch }, { rating, message, type }) {
    return FeedbackAPI.sendFeedback(rating, message, type, null).then(() => dispatch('updateCurrentFeedback'))
  },
  updateItemFeedback(store, { message, type, id }) {
    return FeedbackAPI.sendFeedback(null, message, type, id)
  },
}
