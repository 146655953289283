export default [
  {
    component: () => import('@/modules/todolists/views/TodolistsDashboard.vue'),
    name: 'dashboard',
    path: '/todolists/:filter?',
    meta: {
      requiresModule: 'courses',
    },
    children: [
      {
        component: () => import('@/modules/todolists/views/TodolistDetails.vue'),
        name: 'details',
        path: '/todolists/:filter/:id',
        meta: {
          requiresModule: 'courses',
        },
      },
    ],
  },
]
