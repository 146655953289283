import deepmerge from 'deepmerge'

import { getPersistedState } from '@/logic/statePersisting'

const defaultState = {
  pagesList: null,
  pages: null,
  publicPages: null,
  tos: null,
}

const persistedState = getPersistedState('pages')

export default deepmerge(defaultState, persistedState)
