<template>
  <div
    v-if="hasMissingData"
    class="c-infoBox">
    <div class="c-infoBox__title">{{ $t('competitions.note') }}</div>
    <div class="t-caption1 u-marginBottom2">
      {{ $t('competitions.note_description') }}<br />
    </div>
    <router-link
      tag="a"
      :to="{ name: 'profile.completeAccount' }"
      class="t-body3 u-colorPrimary t-noLinkAppearance u-fontWeightMedium">
      {{ $t('competitions.complete_account_now') }}
      <SvgIcon
        type="chevron_right"
        tiny />
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      hasMissingData: 'competitions/hasMissingData',
    }),
  },
}
</script>
