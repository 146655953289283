import { applyConfig } from '../appConfig'

import observeSVGs from './observeSVGs'
import registerDirectives from './registerDirectives'
import { updateScreenSize } from './screenSize'

import deepstream from '@/logic/deepstream'
import { languageSetup } from '@/logic/i18n'
import adsSetup from '@/logic/init/advertisements'
import appConfigSetup from '@/logic/init/appConfigSetup'
import catchInstallPrompt from '@/logic/init/catchInstallPrompt'
import fcmSetup from '@/logic/init/fcm'
import migrateOldAuthToken from '@/logic/init/migrateOldAuthToken'
import { offlineHandlerSetup } from '@/logic/init/offlineHandler'
import pagesSetup from '@/logic/init/pages'
import registerFilters from '@/logic/init/registerFilters'
import registerGlobalComponents from '@/logic/init/registerGlobalComponents'
import registerPlugins from '@/logic/init/registerPlugins'
import registerPowerlearning from '@/logic/init/registerPowerlearning'
import registerServiceWorker from '@/logic/init/registerServiceWorker'
import { setContext as setSentryContext, setupSentry } from '@/logic/init/sentry'
import setup100vh from '@/logic/init/setup100vh'
import todolistsSetup from '@/logic/init/todolists'

export async function init(store, i18n, router) {
  await setupSentry(store)
  offlineHandlerSetup(store)
  await languageSetup(store)
  await migrateOldAuthToken(store)
  registerFilters()
  registerDirectives(store, router)
  registerGlobalComponents()
  registerPlugins()
  catchInstallPrompt(store)
  setup100vh()
  appConfigSetup()
  observeSVGs(store)
  updateScreenSize(store)
}

export async function appSetup(store, i18n, router) {
  setSentryContext(store)
  await languageSetup(store)
  registerPowerlearning(store)
  registerServiceWorker(store, i18n)
  applyConfig(store.getters['auth/config'])
  deepstream.init(store, i18n, router)
  fcmSetup(store, i18n, router)
  adsSetup(store, i18n, router)
  pagesSetup(store, i18n, router)
  todolistsSetup(store, i18n, router)
}
