import Vue from 'vue'

let SentryInstance = null

export async function setupSentry() {
  if (process.env.NODE_ENV === 'development') {
    return
  }
  const [Sentry, Tracing] = await Promise.all([
    import(/* webpackChunkName: "sentry/vue" */ '@sentry/vue'),
    import(/* webpackChunkName: "sentry/tracing" */ '@sentry/tracing'),
  ])
  let environment = 'production'
  let sampleRate = 0.01
  if (process.env.VUE_APP_API_URL.indexOf('https://admin.staging.keelearning.de') === 0) {
    environment = 'staging'
    sampleRate = 1
  }
  SentryInstance = Sentry
  Sentry.init({
    dsn: 'https://55244620821247dda8519359b8ecc555@sentry.keelearning.de/6',
    environment,
    Vue,
    integrations: [
      new Tracing.Integrations.BrowserTracing({
        tracingOrigins: [process.env.VUE_APP_API_URL, process.env.VUE_APP_XAPI_URL, process.env.VUE_APP_RELAUNCH_API_URL],
      }),
    ],
    tracesSampler: (samplingContext) => (samplingContext.sampleRate || sampleRate),
    logErrors: true,
    attachProps: true,
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      // We don't really care about duplicated vue navigation
      'NavigationDuplicated',
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
  })
  Sentry.setTag('hostname', window.location.hostname)
}

export async function setContext(store) {
  if (process.env.NODE_ENV === 'development') {
    return
  }
  SentryInstance.setTag('app_id', store.getters['auth/appId'])
  if (store.getters['auth/isLoggedIn']) {
    SentryInstance.setUser({ id: store.getters['auth/userId'] })
  }
}

export function getSentryInstance() {
  return SentryInstance
}
