<template>
  <img
    :src="avatar"
    @error="handleLoadError" />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      reloadedAvatar: false,
    }
  },
  computed: {
    ...mapGetters({
      avatar: 'auth/avatar',
    }),
  },
  methods: {
    handleLoadError() {
      if (this.reloadedAvatar) {
        return
      }
      this.reloadedAvatar = true
      this.$store.dispatch('auth/updateProfile')
    },
  },
}
</script>
