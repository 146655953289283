<template>
  <div
    v-if="learningMaterials && learningMaterials.length"
    class="o-grid -equalHeight"
    data-cy="LearningMaterial__slider">
    <div class="u-marginTop o-grid__cell -c12 -c9@tabletPortrait">
      <div class="u-hide@tabletMiniDown">
        <div class="c-card -smallShadow">
          <div class="c-card__content -noPadding">
            <LearningmaterialListEntry
              v-for="learningmaterial in learningMaterials"
              :key="learningmaterial.id"
              :learningmaterial="learningmaterial" />
          </div>
        </div>
      </div>
      <div class="u-hide@tabletPortrait">
        <SlideOnMobile v-if="learningMaterials && learningMaterials.length">
          <div class="o-grid -alignItemsStart">
            <div
              v-for="learningmaterial in learningMaterials"
              :key="learningmaterial.id"
              class="o-grid__cell -c10 -c7@tabletMini">
              <div
                class="c-card -smallShadow">
                <div class="c-card__content -noPadding">
                  <LearningmaterialListEntry
                    class="s-slideOnMobile__listEntry"
                    :learningmaterial="learningmaterial" />
                </div>
              </div>
            </div>
          </div>
        </SlideOnMobile>
      </div>
    </div>
  </div>
</template>

<script>
import LearningmaterialListEntry from '@/modules/learningmaterials/components/LearningmaterialListEntry.vue'

export default {
  props: {
    learningMaterials: {
      type: Array,
      required: true,
    },
  },
  components: {
    LearningmaterialListEntry,
  },
}
</script>

<style lang="scss">
.s-slideOnMobile__listEntry .c-learningMaterialListEntry__image {
  width: auto;
  max-width: 100px;
}
</style>
