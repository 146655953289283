<template>
  <div
    v-click-outside="close"
    class="c-dropdown__wrapper"
    data-cy="DropdownSelect">
    <button
      class="o-unstyled"
      type="button"
      @click="toggle">
      <slot />
    </button>
    <div
      v-show="isOpen"
      class="c-dropdown"
      :class="{ '-upwards': upwards }">
      <button
        v-for="option in options"
        :key="option.id"
        class="o-unstyled c-dropdown__option"
        data-cy="DropdownSelect__entry"
        :class="{
          '-active': !menu && value === option.id,
        }"
        type="button"
        @click="select(option)">
        {{ option.label }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    menu: {
      default: false,
      required: false,
      type: Boolean,
    },
    options: {
      required: true,
      type: Array,
    },
    upwards: {
      default: false,
      required: false,
      type: Boolean,
    },
    value: {
      default: null,
      required: false,
    },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    select(option) {
      this.$emit('input', option.id)
      this.close()
    },
    toggle() {
      this.isOpen = !this.isOpen
    },
    open() {
      this.isOpen = true
    },
    close() {
      this.isOpen = false
    },
  },
}
</script>
