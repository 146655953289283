<template>
  <div
    id="app"
    class="o-authLayout__wrapper"
    :class="{ '-divided': $route.meta.divided }"
    data-cy="AuthenticationLayout">
    <div class="o-authLayout">
      <OfflineIndicator />
      <router-view />
      <LoadingIndicator />
    </div>
    <portal-target
      multiple
      name="modal" />
    <NotificationsList />
    <KeywordModal />
  </div>
</template>

<script>
import LoadingIndicator from '@/components/LoadingIndicator.vue'
import OfflineIndicator from '@/components/OfflineIndicator.vue'
import NotificationsList from '@/layouts/partials/NotificationsList.vue'
import KeywordModal from '@/modules/keywords/components/KeywordModal.vue'

export default {
  created() {
    if (window.Capacitor) {
      window.Capacitor.Plugins.SplashScreen.hide()
    }
  },
  components: {
    NotificationsList,
    LoadingIndicator,
    OfflineIndicator,
    KeywordModal,
  },
}
</script>
