import deepmerge from 'deepmerge'

import { getPersistedState } from '@/logic/statePersisting'

const defaultState = {
  myTeams: null,
  teams: {},
}

const persistedState = getPersistedState('teams')

export default deepmerge(defaultState, persistedState)
