<template>
  <div
    v-if="hasModule.learningmaterials && latestLearningmaterials && latestLearningmaterials.length"
    data-cy="LearningmaterialsBlock">
    <div
      class="t-body3 u-colorDarkLightEmphasis u-marginBottom3"
      data-cy="LearningmaterialsBlock__title">
      {{ $t('learningpath.latest_learningmaterials') }}
    </div>
    <LearningMaterialSlider :learning-materials="latestLearningmaterials" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import LearningMaterialSlider from '@/modules/learningmaterials/components/LearningMaterialSlider.vue'

export default {
  created() {
    if (this.hasModule.learningmaterials) {
      this.$store.dispatch('learningmaterials/updateLearningmaterials')
    }
  },
  computed: {
    ...mapGetters({
      hasModule: 'auth/hasModule',
      latestLearningmaterials: 'learningmaterials/latestLearningmaterials',
    }),
  },
  components: {
    LearningMaterialSlider,
  },
}
</script>
