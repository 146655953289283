import api from '@/logic/api'

export default {
  getCourses: () => api.get('/courses/'),
  getCourseDetails: (id) => api.get(`/courses/${id}/contents`),
  getContentWBTEvents: (courseId, contentId) => api.get(`/courses/${courseId}/contents/${contentId}/wbt-events`),
  startParticipation: (id) => api.post(`/courses/${id}/participations`),
  getParticipation: (courseId, participationId) => api.post(`/courses/${courseId}/participations/${participationId}`),
  markContentAsPassed: (courseId, participationId, contentId) => api.post(`/courses/${courseId}/participations/${participationId}/passed/${contentId}`),
  answerQuestion: (courseId, participationId, contentId, questionId, answerId) => api.post(`/courses/${courseId}/participations/${participationId}/answer-question/${contentId}/${questionId}`, {
    question_answer_id: answerId,
  }),
  submitForm: (courseId, participationId, contentId, formId, answerFields) => api.post(`/courses/${courseId}/participations/${participationId}/submit-form/${contentId}/${formId}`, {
    answerFields,
  }),
  repeatAttempt: (courseId, participationId, contentId) => api.post(`/courses/${courseId}/participations/${participationId}/repeat/${contentId}`),
  getCorrectAnswers: (courseId, participationId, contentId, questionId) => api.get(`/courses/${courseId}/participations/${participationId}/correct-answers/${contentId}/${questionId}`),
  requestAccess: (courseId) => api.post(`/courses/${courseId}/access`),
  storeCourseView: (id) => api.post('/view/courses', { id }),
}
