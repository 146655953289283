export default [
  {
    component: () => import('@/modules/appointments/views/AppointmentsIndex.vue'),
    name: 'index',
    path: '/appointments',
    meta: {
      requiresModule: 'appointments',
    },
    children: [
      {
        component: () => import('@/modules/appointments/views/AppointmentsUpcoming.vue'),
        name: 'upcoming',
        path: '',
        meta: {
          requiresModule: 'appointments',
        },
      },
      {
        component: () => import('@/modules/appointments/views/AppointmentsElapsed.vue'),
        name: 'elapsed',
        path: '/appointments/elapsed',
        meta: {
          requiresModule: 'appointments',
        },
      },
    ],
  },
  {
    component: () => import('@/modules/appointments/views/AppointmentDetails.vue'),
    name: 'details',
    path: '/appointments/:appointmentId',
    meta: {
      requiresModule: 'appointments',
    },
  },
]
