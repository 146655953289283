export default [
  {
    component: () => import('@/modules/powerlearning/views/PowerlearningDashboard.vue'),
    name: 'dashboard',
    path: '/powerlearning',
    meta: {
      requiresModule: 'powerlearning',
    },
  },
  {
    component: () => import('@/modules/powerlearning/views/PowerlearningCategory.vue'),
    name: 'category',
    path: '/powerlearning/category/:id',
    meta: {
      requiresModule: 'powerlearning',
    },
  },
  {
    component: () => import('@/modules/powerlearning/views/PowerlearningLearn.vue'),
    name: 'learn',
    path: '/powerlearning/learn/:id/:box',
    meta: {
      noMobileBottomPadding: true,
      noMobileFooter: true,
      noMobileMenus: true,
      requiresModule: 'powerlearning',
    },
  },
]
