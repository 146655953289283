module.exports = {
  complete_account_now: 'Jetzt Account vervollständigen',
  ended: 'Beendet',
  title: 'Gewinnspiele',
  sign_up: {
    password: 'Passwort',
    repeat_password: 'Passwort wiederholen',
  },
  note: 'Hinweis:',
  note_description: 'Um an Challenges & Gewinnspielen teilnehmen zu können (und damit wir Dich im Gewinnfall benachrichtigen können) musst du noch deinen Account vervollständigen.',
  game_counts_for_competition: 'Deine Ergebnisse in dieser Kategorie werden im aktuellen Gewinnspiel „%{competition}“ gewertet. Alle Infos findest du auf der Detailseite des jeweiligen Gewinnspiels unter dem Menüpunkt %{menu}.',
}
