<template>
  <div class="c-iconWrapper">
    <SvgIcon
      :type="iconType"
      medium />
  </div>
</template>

<script>
export default {
  props: {
    appointment: {
      required: true,
      type: Object,
    },
  },
  computed: {
    iconType() {
      if (this.appointment.type === 'COMPETITION') {
        return 'flag-checkered'
      }
      if (this.appointment.type === 'COURSE') {
        return 'flag-checkered'
      }
      if (this.appointment.type === 'TEST') {
        return 'flag-checkered'
      }
      if (this.appointment.type === 'WEBINAR') {
        return 'webinar'
      }
      if (this.appointment.is_cancelled) {
        return 'calendar-cancelled'
      }
      return 'calendar'
    },
  },
}
</script>
