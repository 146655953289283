import Vue from 'vue'
import VueI18n from 'vue-i18n'

import constants from '@/constants'
import { loadDateFnsLocaleAsync } from '@/logic/dateFnsLocale'
import { setI18n } from '@/logic/i18nInstance'
import store from '@/store'
import de from '@/translations/de/index'

Vue.use(VueI18n)

const defaultLocale = 'de'

export const i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
  messages: { de },
})
setI18n(i18n)

const loadedLanguages = [defaultLocale] // our default language that is preloaded
const config = store.getters['auth/config']
if (config && config.translations_language === defaultLocale) {
  i18n.mergeLocaleMessage(config.translations_language, config.translations)
}

// This should not be called by itself,
// but via loadLanguageAsync.
function setI18nLanguage(lang) {
  i18n.locale = lang
  store.commit('auth/setLocale', lang)
  return lang
}

// This method not only loads the language,
// but also persists the selection to the state / localStorage
// by calling setI18nLanguage.
export function loadLanguageAsync(lang) {
  loadDateFnsLocaleAsync(lang)
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language hasn't been loaded yet
  return import(/* webpackChunkName: "lang-[request]" */ `@/translations/${lang}/index.js`).then(
    (messages) => {
      i18n.setLocaleMessage(lang, messages.default)
      if (store.getters['auth/hasConfig']) {
        const authConfig = store.getters['auth/config']
        if (authConfig.translations_language === lang) {
          i18n.mergeLocaleMessage(authConfig.translations_language, authConfig.translations)
        }
      }
      loadedLanguages.push(lang)
      return setI18nLanguage(lang)
    },
  )
}

// This returns the language we want the app to be in,
// not necessarly the one it currently is.
// `languageSetup` and `loadLanguageAsync` then take care of persisting
// the choice & loading the language + reloading the app, if necessary.
function getCurrentLanguage(storeInstance) {
  // run language auto detection if we've not run it before
  if (!storeInstance.getters['auth/browserLocaleDetected']) {
    // this is an array of strings sorted by preference
    const preferredLanguages = navigator.languages
      // transforms eg. `en-US` to `en`
      .map((localeCode) => localeCode.split('-').shift())
      // inject `de_formal` after `de`
      .map((language) => {
        if (language === 'de') {
          return ['de', 'de_formal']
        }
        return language
      })
      .reduce((acc, language) => {
        if (Array.isArray(language)) {
          acc.push(...language)
        } else {
          acc.push(language)
        }
        return acc
      }, [])
      // filter to available languages
      .filter((language) => {
        // if app profile config is available, choose only from the app's languages
        if (storeInstance.getters['auth/hasConfig']) {
          return storeInstance.getters['auth/config'].languages.includes(language)
        }
        return constants.LANGUAGES.map((languageEntry) => languageEntry.id).includes(language)
      })
    storeInstance.commit('auth/setBrowserLocaleDetected', true)

    // is there an available preferred language?
    if (preferredLanguages.length) {
      return preferredLanguages[0]
    }

    // is there a set default language?
    if (storeInstance.getters['auth/hasConfig'] && storeInstance.getters['auth/config'].settings.signup_default_language) {
      return storeInstance.getters['auth/config'].settings.signup_default_language
    }
  }

  // use previously stored language
  const language = storeInstance.getters['auth/locale']
  if (storeInstance.getters['auth/hasConfig']) {
    const availableLanguages = storeInstance.getters['auth/config'].languages
    if (!availableLanguages.includes(language)) {
      return storeInstance.getters['auth/config'].defaultLanguage || availableLanguages[0]
    }
  }
  return language
}

// This happens on app launch (browser opens the app):
// - read stored config from localStorage
// - init the basic vue app
// - initial languageSetup
// - check if we have an app config
// - if yes, run languageSetup again
// The languageSetup method takes into account user preferences,
// browser language and which languages are available
// in the current app profile.
// If at any point we see that the language we're supposed
// to be in is different from the current language,
// we store the desired language selection to localStorage
// and reload the whole app.
export async function languageSetup(storeInstance) {
  const lang = getCurrentLanguage(storeInstance)
  storeInstance.commit('auth/setLocale', lang)
  loadDateFnsLocaleAsync(lang)
  if (lang !== defaultLocale) {
    return loadLanguageAsync(lang)
  }
  return true
}
