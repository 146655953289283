<template>
  <router-link
    tag="a"
    :to="{
      name: 'courses.details',
      params: {
        courseId: course.id,
      },
    }"
    class="c-card -clickable">
    <div class="c-card__header -imageFill -small -primaryBg">
      <img
        v-if="course.cover_image_url"
        :src="course.cover_image_url" />
      <img
        v-else-if="$store.getters['auth/config'].settings.course_default_image"
        :src="$store.getters['auth/config'].settings.course_default_image" />
      <img
        v-else
        src="/img/no-connection.svg"
        style="object-fit: contain" />
    </div>
    <div class="c-card__content">
      <div class="t-body3 t-fontWeightMedium t-clamp3 t-breakWords o-flex__item -grow">
        {{ course.title }}
      </div>

      <div
        v-if="course.participation"
        class="o-flex -row u-marginTop2">
        <ProgressBar
          :current="course.passed_contents"
          :total="course.contents_count"
          slim
          no-label />
      </div>
    </div>
  </router-link>
</template>

<script>
import ProgressBar from '@/components/ProgressBar.vue'

export default {
  props: {
    course: {
      required: true,
      type: Object,
    },
  },
  components: {
    ProgressBar,
  },
}
</script>
