<template>
  <div
    class="c-progress"
    data-cy="ProgressBar"
    :class="{
      '-slim': slim,
      '-noTitle': !title,
    }">
    <div
      v-if="title"
      class="c-progress__title">
      {{ title }} ({{ finished }}/{{ total }})
    </div>
    <div class="o-flex -alignCenter">
      <div class="o-flex__item -grow">
        <div class="c-progress__bar">
          <div
            v-for="(amount, index) in parts"
            :key="index"
            :style="{
              width: `${amount / total * 100}%`,
            }"
            class="c-progress__determinate" />
        </div>
      </div>
      <div
        v-if="!noLabel"
        :class="{
          'u-colorPrimary': finishedPercentage,
          'u-colorDarkLightEmphasis': !finishedPercentage,
        }"
        class="c-progress__percentage o-flex__item">
        {{ finishedPercentage }}%
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    current: [Number, Array], // either Number or Array with 3 Numbers
    title: {
      required: false,
      type: String,
    },
    noLabel: {
      required: false,
      default: false,
      type: Boolean,
    },
    total: {
      required: false,
      type: Number,
      default: 100,
    },
    slim: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  computed: {
    finished() {
      if (typeof this.current === 'number') {
        return this.current || 0
      }
      return this.current[0] || 0
    },
    finishedPercentage() {
      if (this.total === 0) {
        return 0
      }
      if (Array.isArray(this.current) && this.current.length > 1) {
        return this.multipartFinishedPercentage
      }
      return Math.round(this.finished / this.total * 100)
    },
    multipartFinishedPercentage() {
      // Calculates the percentage when this.current is an array, so we have to calculate differently
      let finished = 0
      this.current.forEach((count, idx) => {
        const box = this.current.length - idx
        // Add points depending on which box we are in
        // Earlier boxes give less points
        finished += count * (box / this.current.length)
      })
      return Math.round(finished / this.total * 100)
    },
    parts() {
      if (typeof this.current === 'number') {
        return [this.current]
      }
      return this.current
    },
  },
}
</script>
