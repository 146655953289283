<template>
  <div
    v-if="hasModule.tests && testsTodo && testsTodo.length"
    data-cy="TestsBlock">
    <div
      class="t-body3 u-colorDarkLightEmphasis u-marginBottom3"
      data-cy="TestsBlock__title">
      {{ $t('tests.tests_todo') }}
    </div>
    <TestsTodo one-line />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import TestsTodo from '@/modules/tests/components/TestsTodo.vue'

export default {
  created() {
    if (this.hasModule.tests) {
      this.$store.dispatch('tests/updateTests')
    }
  },
  computed: {
    ...mapGetters({
      hasModule: 'auth/hasModule',
      testsTodo: 'tests/testsTodo',
    }),
  },
  components: {
    TestsTodo,
  },
}
</script>
