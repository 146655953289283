import deepmerge from 'deepmerge'

import { getPersistedState } from '@/logic/statePersisting'

const defaultState = {
  news: null,
}

const persistedState = getPersistedState('news')

export default deepmerge(defaultState, persistedState)
