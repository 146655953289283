var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"o-flex -alignCenter t-caption1 t-fontWeightMedium",class:_vm.colorClass,attrs:{"data-cy":"AppointmentEntry"}},[_c('AppointmentIcon',{staticClass:"u-marginRight2",attrs:{"appointment":_vm.appointment}}),_c('div',{staticClass:"o-flex -wrap s-metaWrapper"},[_c('div',{class:{
          't-linethrough': _vm.appointment.is_cancelled,
        }},[_vm._v(" "+_vm._s(_vm._f("date")(_vm.appointment.start_date,null, 'eeeeee. dd.MM.yyyy · HH:mm'))+" ")]),(_vm.appointment.badge)?_c('div',{staticClass:"c-badge -text t-caption2 t-fontWeightMedium",class:{
          '-important': _vm.appointment.badgeType !== 'success',
          '-success': _vm.appointment.badgeType === 'success',
          '-neutral': _vm.appointment.badgeType === 'neutral',
        }},[(_vm.appointment.badgeType === 'success')?_c('SvgIcon',{attrs:{"type":"checkmark","tiny":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.appointment.badge)+" ")],1):_vm._e()])],1),_c('div',{staticClass:"t-body3 t-fontWeightMedium t-breakWords",class:{
      's-pullRight': !_vm.minimal,
      'u-marginTop1': _vm.minimal,
      'u-colorDarkMediumEmphasis': _vm.appointment.is_cancelled,
      'u-colorDark': !_vm.appointment.is_cancelled,
    },attrs:{"data-cy":"AppointmentEntry__title"}},[(_vm.appointment.is_cancelled)?[_vm._v(" "+_vm._s(_vm.$t('appointments.cancelled'))+": ")]:_vm._e(),_vm._v(" "+_vm._s(_vm.appointment.name)+" ")],2),(_vm.hasDayCountdown)?_c('DaysLeft',{staticClass:"u-colorImportant u-marginTop2",class:{
      's-pullRight': !_vm.minimal,
    },attrs:{"end-date":_vm.appointment.start_date}}):_vm._e(),(_vm.appointment.location && !_vm.appointment.is_cancelled)?_c('div',{staticClass:"t-longLink t-caption1 t-clamp2 u-colorDarkMediumEmphasis u-marginTop1",class:{
      's-pullRight': !_vm.minimal,
    }},[_vm._v(" "+_vm._s(_vm.appointment.location)+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }