import {
  addSeconds,
  formatISO,
  getUnixTime,
  parse,
  parseISO,
} from 'date-fns'

export default {
  currentDate: new Date(),
  dateUpdated: getUnixTime(new Date()),
  set(date) {
    this.currentDate = new Date(date)
    this.dateUpdated = getUnixTime(new Date())
  },
  mysqlNow() {
    const serverNow = this.now()
    return `${formatISO(serverNow, { representation: 'date' })} ${formatISO(serverNow, { representation: 'time' })}`
  },
  now() {
    return addSeconds(this.currentDate, getUnixTime(new Date()) - this.dateUpdated)
  },
  parseModelDate(date) {
    if (!date) {
      return null
    }
    if (date.endsWith('000000Z')) {
      return parseISO(date)
    }
    return parse(date, 'yyyy-MM-dd HH:mm:ss', this.now())
  },
}
