<template>
  <router-link
    tag="a"
    :to="{
      name: 'courses.details',
      params: {
        courseId: course.id,
      },
    }"
    :class="{
      '-important': isImportant,
    }"
    class="c-card -clickable">
    <div class="c-card__header -imageFill -primaryBg -small">
      <img
        v-if="course.cover_image_url"
        :src="course.cover_image_url" />
      <img
        v-else-if="$store.getters['auth/config'].settings.course_default_image"
        :src="$store.getters['auth/config'].settings.course_default_image" />
      <img
        v-else
        src="/img/no-connection.svg"
        style="object-fit: contain" />
      <div
        v-if="course.is_mandatory"
        class="c-card__headerBadges">
        <div class="c-badge -text u-colorWhite u-bgImportant t-caption1 u-marginLeft2">
          {{ $t('courses.mandatory_course') }}
        </div>
      </div>
    </div>
    <div class="c-card__content">
      <div
        v-if="categories"
        class="t-caption1 u-colorPrimar s-category">
        {{ categories }}
      </div>

      <div class="t-body2 t-fontWeightMedium t-clamp3 t-breakWords o-flex__item -grow">
        {{ course.title }}
      </div>

      <div
        v-if="course.participation"
        class="o-flex -row -alignCenter -spaceBetween u-marginTop2">
        <div class="t-caption1 u-colorPrimary u-marginRight1">
          {{ $t('courses.x_of_y_lessons_done', { x: course.passed_contents, y: course.contents_count }) }}
        </div>
        <ProgressBar
          :current="course.passed_contents"
          :total="course.contents_count"
          slim
          no-label />
      </div>

      <DaysLeft
        v-if="course.available_until && course.duration_type == $constants.COURSES.DURATION_TYPE_FIXED"
        :end-date="course.available_until"
        class="u-colorImportant u-marginTop2" />
      <DaysLeft
        v-if="course.duration_type == $constants.COURSES.DURATION_TYPE_DYNAMIC && dynamicEndDate"
        :end-date="dynamicEndDate"
        class="u-colorImportant u-marginTop2" />
    </div>
  </router-link>
</template>

<script>
import { differenceInDays, isFuture } from 'date-fns'

import DaysLeft from '@/components/DaysLeft.vue'
import ProgressBar from '@/components/ProgressBar.vue'
import DateManager from '@/logic/DateManager'
import { calculateParticipationEnd } from '@/modules/courses/helpers'

export default {
  props: {
    course: {
      required: true,
      type: Object,
    },
  },
  computed: {
    categories() {
      if (!this.course.categories) {
        return null
      }
      return this.course.categories.map((category) => category.name).join(', ')
    },
    isImportant() {
      const availableUntil = DateManager.parseModelDate(this.course.available_until)
      return isFuture(availableUntil) && differenceInDays(availableUntil, new Date()) < 7
    },
    dynamicEndDate() {
      return calculateParticipationEnd(this.course, this.course.participation)
    },
  },
  components: {
    DaysLeft,
    ProgressBar,
  },
}
</script>

<style lang="scss" scoped>
.s-category {
  margin-top: -4px;
}
</style>
