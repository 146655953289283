<template>
  <div
    class="o-mobileHeader"
    data-cy="MobileHeader">
    <router-link
      :to="{
        name: 'home',
      }">
      <img
        v-if="config.settings.app_logo"
        class="o-mobileHeader__logo"
        data-cy="MobileHeader__logo"
        :src="config.settings.app_logo"
        alt="app logo" />
    </router-link>
    <div class="u-spacer" />
    <router-link
      v-if="avatar"
      :to="{
        name: 'profile.profile',
      }"
      class="c-avatar -small u-marginLeft4">
      <CurrentUserAvatar
        class="c-avatar__photo"
        data-cy="MobileHeader__avatar" />
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      avatar: 'auth/avatar',
      config: 'auth/config',
    }),
  },
}
</script>
