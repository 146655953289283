import deepmerge from 'deepmerge'

import { getPersistedState } from '@/logic/statePersisting'

const defaultState = {
  likes: {},
}

const persistedState = getPersistedState('likes')

export default deepmerge(defaultState, persistedState)
