export default {
  latestNews(state, getters) {
    if (!state.news) {
      return state.news
    }
    const news = getters.allNews
    return news.slice(0, 4)
  },
  allNews(state) {
    if (!state.news) {
      return state.news
    }
    const news = Object.values(state.news)
    news.sort((a, b) => {
      if (a.published_at > b.published_at) {
        return -1
      }
      if (a.published_at < b.published_at) {
        return 1
      }
      return 0
    })
    return news
  },
  findNewsEntry(state) {
    if (!state.news) {
      return () => null
    }
    return (id) => state.news[id]
  },
}
