<template>
  <router-link
    :to="{
      name: 'powerlearning.category',
      params: {
        id: category.id,
      },
    }"
    class="c-card -clickable">
    <div class="c-card__header -imageFill">
      <img
        v-if="category.image_url"
        :src="category.image_url" />
      <img
        v-else
        src="@/assets/images/pruefungen/cards/weltraum.svg" />
    </div>
    <div class="c-card__content">
      <div
        v-if="categorygroup"
        class="t-caption2 t-uppercase t-fontWeightMedium u-colorPrimary">
        {{ categorygroup.name }}
      </div>
      <div class="t-body3 t-fontWeightMedium t-clamp2">
        {{ category.name }}
      </div>
      <ProgressBar
        class="u-marginTop1"
        no-label
        :current="[category.stats[3], category.stats[2], category.stats[1]]"
        :total="category.stats.total" />
    </div>
  </router-link>
</template>

<script>
import ProgressBar from '@/components/ProgressBar.vue'

export default {
  props: ['category', 'categorygroup'],
  components: {
    ProgressBar,
  },
}
</script>
