module.exports = {
  notifications: {
    AppointmentReminder: 'Termine: Terminerinnerung',
    AppointmentParticipationConfirmation: 'Termine: Anmeldebestätigung',
    AppointmentStartDateWasUpdated: 'Termine: Terminänderung',
    AppReminder: 'User: Wir vermissen dich',
    CommentDeletedForAuthor: 'Kommentar: Dein Kommentar wurde entfernt',
    CommentDeletedForReporter: 'Kommentar: Gemeldeter Kommentar wurde entfernt',
    CommentNotDeleted: 'Kommentar: Gemeldeter Kommentar wurde nicht entfernt',
    CompetitionInvitation: 'Gewinnspiel: Neues Gewinnspiel',
    CompetitionReminder: 'Gewinnspiel: Erinnerung',
    CompetitionResult: 'Gewinnspiel: Spielergebnis',
    CourseReminder: 'Kurse: Erinnerung an laufenden Kurs',
    ExpirationReminder: 'User: Dein Account wird bald gelöscht',
    GameAbort: 'Quiz-Challenge: Spielabbruch',
    GameDrawInfo: 'Quiz-Challenge: Spiel unentschieden',
    GameInvitation: 'Quiz-Challenge: Duell-Aufforderung durch anderen Spieler',
    GameLostInfo: 'Quiz-Challenge: Spiel verloren',
    GameReminder: 'Quiz-Challenge: Du bist am Zug',
    GameWonInfo: 'Quiz-Challenge: Spiel gewonnen',
    LearningMaterialsPublished: 'Mediathek: Neue Inhalte',
    NewAppointment: 'Termine: Neuer Termin',
    NewCourseNotification: 'Kurse: Neuer Kurs',
    NewsPublishedInfo: 'News: Neue Nachrichten',
    PassedCourse: 'Kurse: Kurs bestanden',
    QuizTeamAdd: 'Quiz-Challenge: Du wurdest zu einem Quiz-Team hinzugefügt',
    SubscriptionComment: 'Kommentar: Benachrichtigen bei neuen Antworten',
    TestPassed: 'Test: Test bestanden',
    TestReminder: 'Test: Erinnerung an Test-Teilnahme',
    WebinarReminder: 'Webinar: Meeting startet gleich',
    CommentReply: 'Kommentar: Benachrichtigen bei neuen Antworten',
  },
  description: 'Stelle ein, für welche Aktivitäten du E-Mail-Benachrichtigungen erhalten möchtest.',
  cant_deactivate: '<span>Diese E-Mail kannst du nicht deaktivieren. Wende dich bei Fragen an deinen <span class="underline">Administrator</span>.</span>',
  save: 'Speichern',
  save_settings_failed: 'Das Speichern der E-Mail Einstellungen ist fehlgeschlagen.',
  receive: 'aktiv',
  dont_receive: 'inaktiv',
  emails: 'E-Mail',
  push_notifications: 'Push',
  setting_emails: 'E-Mail-Benachrichtigungen',
  setting_push_notifications: 'Push-Notifications (Store-App)',
  settings_disabled: 'Der Admin hat diesen Bereich leider nicht freigegeben. <br /> Kontaktiere deinen Admin bei Fragen unter %{email}',
}
