<template>
  <div
    v-if="lastViewedContent.length"
    class="c-sidebarEntry"
    data-cy="SidebarLastViewed">
    <div
      class="t-body3 t-fontWeightMedium u-marginBottom3"
      data-cy="SidebarLastViewed__title">
      {{ $t('generic.last_learned') }}
    </div>
    <div
      v-for="(entry, index) in lastViewedContent"
      :key="index"
      class="s-lastViewedListEntry">
      <router-link
        tag="a"
        :to="entry.route"
        class="t-caption1 t-noLinkAppearance u-inlineBlock"
        data-cy="SidebarLastViewed__entry">
        <div class="t-caption1 u-colorPrimary t-uppercase">
          {{ entry.caption }}
        </div>
        <div class="t-caption1">
          {{ entry.title }}
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns'
import { mapGetters } from 'vuex'

import DateManager from '@/logic/DateManager'
import LocalPowerlearning from '@/modules/powerlearning/api/localPowerlearning'

export default {
  data() {
    return {
      powerlearningCategories: [],
    }
  },
  created() {
    LocalPowerlearning.getCategories().then((categories) => {
      this.powerlearningCategories = categories
    })
  },
  computed: {
    ...mapGetters({
      activeCourses: 'courses/activeCourses',
      activeQuizzes: 'quizzes/activeQuizzes',
      lastViewedLearningmaterials: 'learningmaterials/lastViewedLearningmaterials',
    }),
    lastViewedContent() {
      return []
        .concat(this.lastViewedLearningMaterialEntries)
        .concat(this.lastViewedPowerlearningCategoryEntries)
        .concat(this.lastViewedCourseEntries)
        .concat(this.lastViewedQuizEntries)
        .sort((entryA, entryB) => {
          const dateA = entryA.date || 0
          const dateB = entryB.date || 0
          return dateB - dateA
        })
        .slice(0, 5)
    },
    lastViewedCourseEntries() {
      return (this.activeCourses || [])
        .filter((course) => !!course.participation)
        .slice(0, 5)
        .map((course) => ({
          caption: this.$t('generic.courses'),
          date: course.participation ? parseInt(format(DateManager.parseModelDate(course.participation.updated_at), 'T'), 10) : 0,
          route: {
            name: 'courses.details',
            params: {
              courseId: course.id,
            },
          },
          title: course.title,
        }))
    },
    lastViewedLearningMaterialEntries() {
      return (this.lastViewedLearningmaterials)
        .map((learningMaterial) => ({
          caption: this.$t('generic.learningmaterials'),
          date: parseInt(format(DateManager.parseModelDate(learningMaterial.last_viewed_at), 'T'), 10),
          route: {
            name: 'learningmaterials.details',
            params: {
              id: learningMaterial.id,
            },
          },
          title: learningMaterial.title,
        }))
    },
    lastViewedPowerlearningCategoryEntries() {
      return this.powerlearningCategories
        .filter((category) => !!category.last_answered_at)
        .sort((a, b) => {
          const aLastAnswered = a.last_answered_at || 0
          const bLastAnswered = b.last_answered_at || 0
          if (!aLastAnswered && !bLastAnswered) {
            return b.created_at.localeCompare(a.created_at)
          }
          return bLastAnswered - aLastAnswered
        })
        .slice(0, 5)
        .map((category) => ({
          caption: this.$t('generic.powerlearning'),
          date: category.last_answered_at,
          route: {
            name: 'powerlearning.category',
            params: {
              id: category.id,
            },
          },
          title: category.name,
        }))
    },
    lastViewedQuizEntries() {
      return (this.activeQuizzes || [])
        .map((quizGame) => quizGame) // clone so we don't sort the original array...
        .sort((quizGameA, quizGameB) => quizGameB.updated_at.localeCompare(quizGameA.updated_at))
        .slice(0, 5)
        .map((quizGame) => ({
          caption: this.$t('generic.quiz_battle'),
          date: parseInt(format(DateManager.parseModelDate(quizGame.updated_at), 'T'), 10),
          route: {
            name: 'quizzes.details',
            params: {
              id: quizGame.id,
            },
          },
          title: `${this.$t('quizapp.vs')} ${quizGame.opponent}`,
        }))
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/config';

.s-lastViewedListEntry {
  border-bottom: 1px solid $color-soft_highlight;
  display: block;
  margin-bottom: $spacing-4;
  padding-bottom: $spacing-4;
  text-decoration: none;
}
</style>
