<template>
  <div class="c-notification__wrapper">
    <NotificationEntry
      v-for="notification in notifications"
      :key="notification.id"
      :notification="notification" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import NotificationEntry from '@/layouts/partials/NotificationEntry.vue'

export default {
  computed: {
    ...mapGetters({
      notifications: 'notifications/notifications',
    }),
  },
  components: {
    NotificationEntry,
  },
}
</script>
