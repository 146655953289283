import constants from '@/constants'

export default {
  webinars(state) {
    return state.webinars
  },
  recordings(state) {
    return state.recordings
  },
  webinarCount(state) {
    return state.webinars.filter((webinar) => webinar.type === constants.WEBINARS.TYPE_WEBINAR).length
  },
  learningroomCount(state) {
    return state.webinars.filter((webinar) => webinar.type === constants.WEBINARS.TYPE_LEARNINGROOM).length
  },
  findWebinar(state) {
    if (!state.webinars.length) {
      return () => null
    }
    return (id) => state.webinars.find((webinar) => webinar.id === parseInt(id, 10))
  },
  visibleWebinars(state, getters) {
    if (!getters.webinars) {
      return getters.webinars
    }
    let webinars = [...getters.webinars]

    if (!state.searchQuery) {
      if (state.filter === constants.WEBINARS.FILTER_WEBINARS) {
        webinars = webinars.filter((webinar) => webinar.type === constants.WEBINARS.TYPE_WEBINAR)
      }
      if (state.filter === constants.WEBINARS.FILTER_LEARNINGROOMS) {
        webinars = webinars.filter((webinar) => webinar.type === constants.WEBINARS.TYPE_LEARNINGROOM)
      }
    }

    if (state.searchQuery) {
      const query = state.searchQuery.toLowerCase()
      webinars = webinars.filter((webinar) => {
        if (webinar.topic.toLowerCase().indexOf(query) !== -1) {
          return true
        }
        return false
      })
    }

    webinars.sort((a, b) => {
      let descMultiplicator = 1
      if ([constants.WEBINARS.SORT_TOPIC_DESC, constants.WEBINARS.SORT_STARTS_AT_DESC].includes(state.sort)) {
        descMultiplicator = -1
      }
      let field = 'topic'
      if ([constants.WEBINARS.SORT_STARTS_AT_DESC, constants.WEBINARS.SORT_STARTS_AT_ASC].includes(state.sort)) {
        field = 'starts_at'
      }
      return (a[field] || '').localeCompare(b[field], undefined, { numeric: true }) * descMultiplicator
    })

    return webinars
  },
  visibleRecordings(state, getters) {
    if (!getters.recordings) {
      return getters.recordings
    }
    let recordings = [...getters.recordings]

    // Filter recordings which are from a still available webinar
    const webinarIds = state.webinars.map((webinar) => webinar.id)
    if (webinarIds) {
      recordings = recordings.filter((recording) => !webinarIds.includes(recording.webinar_id))
    }

    if (!state.searchQuery) {
      if (state.filter === constants.WEBINARS.FILTER_WEBINARS) {
        recordings = recordings.filter((recording) => recording.type === constants.WEBINARS.TYPE_WEBINAR)
      }
      if (state.filter === constants.WEBINARS.FILTER_LEARNINGROOMS) {
        recordings = recordings.filter((recording) => recording.type === constants.WEBINARS.TYPE_LEARNINGROOM)
      }
    }

    if (state.searchQuery) {
      const query = state.searchQuery.toLowerCase()
      recordings = recordings.filter((recording) => {
        if (recording.topic.toLowerCase().indexOf(query) !== -1) {
          return true
        }
        return false
      })
    }

    recordings.sort((a, b) => {
      let descMultiplicator = 1
      if ([constants.WEBINARS.SORT_TOPIC_DESC, constants.WEBINARS.SORT_STARTS_AT_DESC].includes(state.sort)) {
        descMultiplicator = -1
      }
      let field = 'topic'
      if ([constants.WEBINARS.SORT_STARTS_AT_DESC, constants.WEBINARS.SORT_STARTS_AT_ASC].includes(state.sort)) {
        field = 'created_at'
      }
      return a[field].localeCompare(b[field], undefined, { numeric: true }) * descMultiplicator
    })

    return recordings
  },
  filter(state) {
    return state.filter
  },
  sort(state) {
    return state.sort
  },
  searchQuery(state) {
    return state.searchQuery
  },
  searchOpen(state) {
    return state.searchOpen
  },
  isFiltered(state, getters) {
    return !!getters.searchQuery
  },
}
