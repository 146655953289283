<template>
  <div
    v-if="hasModule.courses && activeCourses && activeCourses.length"
    data-cy="CoursesBlock">
    <div
      class="t-body3 u-colorDarkLightEmphasis u-marginBottom3"
      data-cy="CoursesBlock__title">
      {{ $t('courses.courses_todo') }}
    </div>
    <ActiveCourses :row-count="rowCount" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import ActiveCourses from '@/modules/courses/components/ActiveCourses.vue'

export default {
  props: {
    settings: {
      type: Object,
      required: false,
    },
  },
  created() {
    if (this.hasModule.courses) {
      this.$store.dispatch('courses/updateCourses')
    }
  },
  computed: {
    ...mapGetters({
      activeCourses: 'courses/activeCourses',
      hasModule: 'auth/hasModule',
    }),
    rowCount() {
      if (this.settings && this.settings.rows) {
        return Number(this.settings.rows)
      }
      return this.$constants.HOMEPAGE.COURSES.DEFAULT_ROWS
    },
  },
  components: {
    ActiveCourses,
  },
}
</script>
