<template>
  <div
    v-if="hasModule.quiz"
    data-cy="QuizBlock">
    <h1
      v-if="hasNoActiveQuiz"
      class="t-h2 u-colorDark u-marginBottom3"
      data-cy="QuizBlock__title">
      {{ $t('quizapp.start_first_quiz') }}
    </h1>
    <div
      v-else
      class="t-body3 u-colorDarkLightEmphasis">
      {{ $t('generic.quiz_battle') }}
    </div>
    <ActiveQuizzes v-if="hasModule.quiz" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import ActiveQuizzes from '@/modules/quizzes/components/ActiveQuizzes.vue'

export default {
  computed: {
    ...mapGetters({
      hasModule: 'auth/hasModule',
      hasNoActiveQuiz: 'quizzes/hasNoActiveQuiz',
    }),
  },
  components: {
    ActiveQuizzes,
  },
}
</script>
