import constants from '@/constants'

const defaultState = {
  learningmaterialFolders: [],
  wbtEvents: {},
  userSelectionFunction: null, // Filters learning materials based on the currently opened page
  typeFilters: [],
  sort: constants.LEARNINGMATERIALS.SORT_NAME_ASC,
  searchQuery: null,
  searchOpen: false,
  foundLearningmaterials: [],
}

export default defaultState
