import deepmerge from 'deepmerge'

import { getPersistedState } from '@/logic/statePersisting'

const defaultState = {
  itemAnswers: {},
  todolists: null,
}
const persistedState = getPersistedState('todolists')

export default deepmerge(defaultState, persistedState)
