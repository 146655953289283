import Vue from 'vue'

import constants from '@/constants'

export default {
  setTests(state, tests) {
    Vue.set(state, 'tests', tests)
  },
  setTestDetails(state, { id, details }) {
    Vue.set(state.testDetails, id, details)
  },
  setSubmission(state, { id, submission }) {
    Vue.set(state.submissions, parseInt(id, 10), submission)
  },
  setFilter(state, filter) {
    state.filter = filter
  },
  setSubFilters(state, subFilters) {
    Vue.set(state, 'subFilters', subFilters)
  },
  toggleSubFilter(state, filter) {
    const index = state.subFilters.findIndex((filterEntry) => filterEntry === filter)
    if (index === -1) {
      state.subFilters.push(filter)
    } else {
      Vue.delete(state.subFilters, index)
    }
  },
  setSort(state, sort) {
    state.sort = sort
  },
  setSearchQuery(state, query) {
    state.searchQuery = query
  },
  setSearchOpen(state, value) {
    state.searchOpen = value
  },
  toggleSearchOpen(state) {
    state.searchOpen = !state.searchOpen
  },
  resetFilters(state) {
    state.searchQuery = null
    state.searchOpen = false
    state.typeFilters = []
    state.sort = constants.TESTS.SORT_NAME_ASC
  },
}
