import { differenceInDays, isFuture } from 'date-fns'

import constants from '@/constants'
import api from '@/logic/api'
import DateManager from '@/logic/DateManager'
import getI18n from '@/logic/i18nInstance'
import { calculateParticipationEnd } from '@/modules/courses/helpers'

export default {
  allAppointments(state, getters, rootState, rootGetters) {
    if (!state.appointments) {
      return null
    }
    const appointments = Object.values(state.appointments)
      .map((appointment) => {
        if (appointment.participation_management_enabled && !appointment.is_cancelled) {
          if (appointment.user_participates) {
            appointment.badge = api.i18n.t('appointments.youAreRegistered')
            appointment.badgeType = 'success'
          } else if (isFuture(DateManager.parseModelDate(appointment.end_date))) {
            if (!appointment.limit_participants) {
              appointment.badge = api.i18n.t('appointments.openSlots')
              appointment.badgeType = 'neutral'
            } else if (appointment.participants_count >= appointment.max_participants) {
              appointment.badge = api.i18n.t('appointments.noOpenSlots')
            } else {
              const openSlots = appointment.max_participants - appointment.participants_count
              appointment.badge = api.i18n.tc('appointments.nOpenSlots', openSlots, { openSlots })
              appointment.badgeType = 'neutral'
            }
          }
        }
        return appointment
      })
    // we're simulating relevant parts of the appointment model object here:
    // id: prefix with foreign model
    // cover_image_url: image of foreign model if available
    // created_at: unused
    // description: unused
    // end_date: null or calculated from foreign model
    // is_cancelled: false
    // location: unused
    // name: get from title
    // start_date: calculated from foreign model
    // type: depending on foreign model
    // updated_at: unused
    //
    // Additional props:
    // route: route parameters for resource
    // important: true if entry should be emphasized
    // resolved: true if the user already took care of it
    // badge: badge text to show next to date
    const competitionAppointments = (rootGetters['competitions/competitions'] || [])
      .map((competition) => ({
        id: `competition-${competition.id}`,
        cover_image_url: competition.cover_image_url,
        start_date: competition.end, // deadline
        is_cancelled: false,
        name: competition.title,
        route: {
          name: 'competitions.details',
          params: {
            id: competition.id,
          },
        },
        type: 'COMPETITION',
      }))
    const courseAppointments = (rootGetters['courses/allCourses'] || [])
      .filter((course) => ((
        (
          course.duration_type === constants.COURSES.DURATION_TYPE_FIXED
          && !!course.available_until
        )
        || (
          course.duration_type === constants.COURSES.DURATION_TYPE_DYNAMIC
          && !!course.participation
        )
      )))
      .map((course) => {
        let availableUntil = null
        if (course.duration_type === constants.COURSES.DURATION_TYPE_FIXED) {
          availableUntil = DateManager.parseModelDate(course.available_until)
        }
        if (course.duration_type === constants.COURSES.DURATION_TYPE_DYNAMIC) {
          availableUntil = calculateParticipationEnd(course, course.participation)
        }
        return {
          id: `course-${course.id}`,
          cover_image_url: course.cover_image_url,
          start_date: course.available_until, // deadline
          is_cancelled: false,
          name: course.title,
          route: {
            name: 'courses.details',
            params: {
              courseId: course.id,
            },
          },
          badge: course.is_mandatory ? getI18n().t('courses.mandatory_course') : null,
          resolved: course?.participation?.passed === 1,
          important: course?.participation?.passed !== 1 && isFuture(availableUntil) && differenceInDays(availableUntil, new Date()) < 7,
          type: 'COURSE',
        }
      })
    const testAppointments = (rootGetters['tests/tests'] || [])
      .filter((test) => !!test.active_until)
      .map((test) => {
        const activeUntil = DateManager.parseModelDate(test.active_until)
        return {
          id: `test-${test.id}`,
          cover_image_url: test.cover_image_url,
          start_date: test.active_until, // deadline
          is_cancelled: false,
          name: test.name,
          route: {
            name: 'tests.details',
            params: {
              testId: test.id,
            },
          },
          important: isFuture(activeUntil) && differenceInDays(activeUntil, new Date()) < 7,
          type: 'TEST',
        }
      })
    const webinarAppointments = (rootGetters['webinars/webinars'] || [])
      .filter((webinar) => ((
        (
          webinar.type === constants.WEBINARS.TYPE_WEBINAR
        )
      )))
      .map((webinar) => ({
        id: `webinar-${webinar.id}`,
        start_date: webinar.starts_at, // deadline
        is_cancelled: false,
        name: webinar.topic,
        route: {
          name: 'webinars.details',
          params: {
            id: webinar.id,
          },
        },
        type: 'WEBINAR',
      }))
    return appointments
      .concat(competitionAppointments)
      .concat(courseAppointments)
      .concat(testAppointments)
      .concat(webinarAppointments)
  },
  upcomingAppointments(state, getters) {
    if (!state.appointments) {
      return null
    }
    return getters.allAppointments
      .filter((appointment) => {
        if (appointment.type === 'COURSE' && appointment.resolved) {
          return false
        }
        return isFuture(DateManager.parseModelDate(appointment.end_date || appointment.start_date))
      })
      .sort((appointmentA, appointmentB) => {
        if (appointmentA.start_date > appointmentB.start_date) {
          return 1
        }
        if (appointmentA.start_date < appointmentB.start_date) {
          return -1
        }
        return 0
      })
  },
  getAppointment(state) {
    if (!state.appointments) {
      return () => null
    }
    return (id) => state.appointments[id]
  },
  elapsedAppointments(state, getters) {
    if (!state.appointments) {
      return null
    }
    return getters.allAppointments
      .filter((appointment) => {
        if (appointment.type === 'COURSE' && appointment.resolved) {
          return true
        }

        return !isFuture(DateManager.parseModelDate(appointment.end_date || appointment.start_date))
      })
      .sort((appointmentA, appointmentB) => {
        if (appointmentA.start_date > appointmentB.start_date) {
          return -1
        }
        if (appointmentA.start_date < appointmentB.start_date) {
          return 1
        }
        return 0
      })
  },
}
