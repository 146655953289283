<template>
  <div class="o-sidenav">
    <router-link
      :to="{
        name: 'home',
      }">
      <img
        v-if="logo"
        class="o-sidenav__logo"
        data-cy="DefaultSidenav__logo"
        :src="logo"
        alt="app logo" />
    </router-link>
    <div class="o-sidenav__links">
      <template v-for="(entry, index) in navigation">
        <hr
          v-if="entry.divider"
          :key="`divider-${index}`"
          class="o-sidenav__divider"
          data-cy="DefaultSidenav__divider" />
        <router-link
          v-else
          :key="`${entry.label}-route`"
          v-slot="{ href, navigate, isExactActive }"
          :to="entry.route"
          data-cy="DefaultSidenav__entry">
          <a
            class="o-sidenav__linkWrapper"
            :class="{ '-active': isExactActive }"
            :href="href"
            @click="navigate">
            <div class="o-sidenav__link">
              <SvgIcon
                v-if="isExactActive"
                :type="`menu_filled_${entry.icon}`" />
              <SvgIcon
                v-else
                :type="`menu_outline_${entry.icon}`" />
              <span class="t-caption1">{{ entry.label }}</span>
            </div>
          </a>
        </router-link>
      </template>
    </div>
    <div class="o-sidenav__spacer" />
    <div class="o-sidenav__bottom">
      <LanguageSelector
        data-cy="DefaultSidenav__languageSelector">
        <template #default="language">
          <div
            class="o-sidenav__linkWrapper">
            <div class="o-sidenav__link t-caption1">
              <SvgIcon
                type="language" />
              {{ language.label }}
              <SvgIcon
                type="arrow_dropdown"
                class="s-languageDropdownIcon u-marginRight0 t-caption1" />
            </div>
          </div>
        </template>
      </LanguageSelector>
      <router-link
        v-slot="{ href, navigate, isActive }"
        :to="{
          name: 'profile.profile',
        }"
        data-cy="DefaultSidenav__profile">
        <div
          class="o-sidenav__linkWrapper"
          :class="{ '-active': isActive }">
          <a
            class="o-sidenav__link"
            :href="href"
            @click="navigate">
            <SvgIcon
              v-if="isActive"
              type="menu_filled_user" />
            <SvgIcon
              v-else
              type="menu_outline_user" />
            <span class="t-caption1">{{ $t('pages.my_profile') }}</span>
          </a>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import navigationMixin from './navigationMixin'

export default {
  mixins: [navigationMixin],
  computed: {
    ...mapGetters({
      config: 'auth/config',
    }),
    logo() {
      const settings = this.config.settings
      return settings.app_logo_inverse ? settings.app_logo_inverse : settings.app_logo
    },
  },
}
</script>
