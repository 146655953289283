import Vue from 'vue'

export default {
  setPagesList(state, pagesList) {
    state.pagesList = pagesList
  },
  setPage(state, { data, id }) {
    if (state.pages === null) {
      state.pages = {}
    }
    Vue.set(state.pages, id, data)
  },
  setPublicPage(state, { data, id }) {
    if (state.publicPages === null) {
      state.publicPages = {}
    }
    Vue.set(state.publicPages, id, data)
  },
  setTos(state, { data }) {
    state.tos = data
  },
}
