<template>
  <ChallengingQuestions v-if="hasModule.quiz" />
</template>

<script>
import { mapGetters } from 'vuex'

import ChallengingQuestions from '@/modules/analytics/components/ChallengingQuestions.vue'

export default {
  computed: {
    ...mapGetters({
      hasModule: 'auth/hasModule',
    }),
  },
  components: {
    ChallengingQuestions,
  },
}
</script>
