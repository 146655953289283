import AnalyticsAPI from '@/modules/analytics/api/analytics'

export default {
  updateChallengingQuestions({ commit }) {
    return AnalyticsAPI.getChallengingQuestions().then((challengingQuestions) => {
      commit('setChallengingQuestions', challengingQuestions)
    })
  },
  updateTopPlayers({ commit }) {
    return AnalyticsAPI.getTopPlayers().then((topPlayers) => {
      commit('setTopPlayers', topPlayers)
    })
  },
  updateTopTeams({ commit }) {
    return AnalyticsAPI.getTopTeams().then((topTeams) => {
      commit('setTopTeams', topTeams)
    })
  },
  updateMyQuizStats({ commit }) {
    return AnalyticsAPI.getMyQuizStats().then((myQuizStats) => {
      commit('setMyQuizStats', myQuizStats)
    })
  },
}
