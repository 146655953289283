<template>
  <component
    :is="layout"
    v-if="layout" />
</template>

<script>
import AuthenticationLayout from '@/layouts/AuthenticationLayout.vue'
import BlankLayout from '@/layouts/BlankLayout.vue'
import DefaultLayout from '@/layouts/DefaultLayout.vue'

export default {
  computed: {
    layout() {
      if (this.$route.meta.layout === 'Authentication') {
        return AuthenticationLayout
      }
      if (this.$route.meta.layout === 'Blank') {
        return BlankLayout
      }
      // If we do not have a route yet, don't show the view yet
      if (this.$route.name === null && this.$route.matched.length === 0) {
        return null
      }
      return DefaultLayout
    },
  },
}
</script>

<style lang="scss">
:root {
  --primary: #19bfd4;
  --secondary: #ff4c81;
  --success: #00ca91;
  --error: #f74f4f;
}
@import '@/assets/scss/main';
</style>
