import constants from '@/constants'

export default {
  allCourses(state) {
    if (!state.courses) {
      return state.courses
    }
    const courses = Object.values(state.courses).sort((a, b) => {
      if (a.participation && !b.participation) {
        return -1
      }
      if (!a.participation && b.participation) {
        return 1
      }
      if (a.participation?.updated_at < b.participation?.updated_at) {
        return 1
      }
      if (a.participation?.updated_at > b.participation?.updated_at) {
        return -1
      }
      if (a.available_from < b.available_from) {
        return 1
      }
      if (a.available_from > b.available_from) {
        return -1
      }
      return 0
    })
    return courses
  },
  findCourse(state) {
    if (!state.courseDetails) {
      return () => null
    }
    return (id) => state.courseDetails[parseInt(id, 10)]
  },
  findParticipation(state) {
    return (id) => state.participations[parseInt(id, 10)]
  },
  allParticipationsContentAttempts(state) {
    const participations = {}
    Object.values(state.participations).forEach((participation) => {
      // We create a map of attempts, keyed by the content id, so access is fast
      const attempts = {}
      participation.content_attempts.forEach((attempt) => {
        if (typeof attempts[attempt.course_content_id] === 'undefined') {
          attempts[attempt.course_content_id] = attempt
        } else if (attempts[attempt.course_content_id].created_at <= attempt.created_at) {
          // If there are more than one attempt for a content, we choose the most recent one
          attempts[attempt.course_content_id] = attempt
        }
      })
      participations[participation.id] = attempts
    })
    return participations
  },
  hasAttemptsLeft(state, getters) {
    return (participationId, contentId, includingActive) => {
      contentId = parseInt(contentId, 10)
      const participation = getters.findParticipation(participationId)
      if (!participation) {
        return false
      }
      const course = getters.findCourse(participation.course_id)
      if (!course) {
        return false
      }
      const contents = getters.allContentsByCourse[course.id]
      if (!contents) {
        return false
      }
      const content = contents.find((c) => c.id === contentId)
      if (!content) {
        return false
      }
      if (content.repetitions === null) {
        return true
      }
      const attemptCount = participation.content_attempts
        .filter((attempt) => attempt.course_content_id === content.id && !attempt.voided && (includingActive || attempt.finished_at))
        .length
      return attemptCount < content.repetitions
    }
  },
  contentIsRepeatable(state, getters) {
    return (participationId, contentId) => getters.hasAttemptsLeft(participationId, contentId, true)
  },
  contentCanBeStarted(state, getters) {
    return (participationId, contentId) => getters.hasAttemptsLeft(participationId, contentId, false)
  },
  findContentAttempts(state, getters) {
    return (participationId) => getters.allParticipationsContentAttempts[parseInt(participationId, 10)]
  },
  allContentsByCourse(state) {
    const courses = {}
    if (state.courseDetails) {
      Object.values(state.courseDetails).forEach((course) => {
        courses[course.id] = course.chapters.reduce((contents, chapter) => {
          chapter.contents.forEach((content) => {
            contents.push(content)
          })
          return contents
        }, [])
      })
    }
    return courses
  },
  earnedCertificatesByParticipation(state, getters) {
    const contentsByCourse = getters.allContentsByCourse
    const participationsAttempts = getters.allParticipationsContentAttempts
    const data = {}
    Object.keys(participationsAttempts).forEach((participationId) => {
      const attempts = participationsAttempts[participationId]
      const participation = state.participations[participationId]
      const contents = contentsByCourse[participation.course_id]
        .filter((content) => {
          if (content.type !== constants.COURSES.TYPE_CERTIFICATE) {
            return false
          }
          const attempt = attempts[content.id] ?? null
          if (!attempt) {
            return false
          }
          return attempt.passed && attempt.certificate_download_url
        }).map((content) => {
          content = JSON.parse(JSON.stringify(content))
          content.attempt = attempts[content.id]
          return content
        })
      data[participationId] = contents
    })
    return data
  },
  progressByParticipation(state, getters) {
    const contentsByCourse = getters.allContentsByCourse
    const participationsAttempts = getters.allParticipationsContentAttempts
    const data = {}
    Object.keys(participationsAttempts).forEach((participationId) => {
      const attempts = participationsAttempts[participationId]
      const participation = state.participations[participationId]
      let total = 0
      let done = 0
      contentsByCourse[participation.course_id]
        .forEach((content) => {
          const attempt = attempts[content.id] ?? null
          total += 1
          if (attempt?.passed) {
            done += 1
          }
        })
      data[participationId] = {
        total,
        done,
      }
    })
    return data
  },
  activeContentByParticipation(state, getters) {
    const contentsByCourse = getters.allContentsByCourse
    const participationsAttempts = getters.allParticipationsContentAttempts
    const data = {}
    Object.keys(participationsAttempts).forEach((participationId) => {
      const attempts = participationsAttempts[participationId]
      const participation = state.participations[participationId]
      const contents = contentsByCourse[participation.course_id]

      if (contents.filter((content) => !attempts[content.id]?.passed).length === 0) {
        // The user already passed all contents
        data[participationId] = null
      } else {
        // Get the last passed content
        const lastPassedContent = contents.filter((content) => attempts[content.id]?.passed).pop()
        const lastPassedContentIndex = contents.indexOf(lastPassedContent)

        // finished last content, but there is prior unfinished content
        if (lastPassedContentIndex === contents.length - 1) {
          // Get the first content which is not passed yet, but the previous attempt is.
          const activeContent = contents.find((content, idx) => {
            if (idx === 0) {
              // This case is already handled when there are no passed attempts
              return false
            }
            return attempts[contents[idx - 1].id]?.passed && !attempts[content.id]?.passed
          })
          if (activeContent) {
            data[participationId] = activeContent
          } else {
            data[participationId] = null
          }
        } else {
          data[participationId] = contents[lastPassedContentIndex + 1]
        }
      }
      if (data[participationId] === null && !attempts[contents[0].id]?.passed) {
        // The user didn't pass the first content. The first content is the active one
        data[participationId] = contents[0]
      }
    })

    Object.keys(data).forEach((participationId) => {
      const content = data[participationId]
      if (content) {
        const attempts = participationsAttempts[participationId]
        const clonedContent = JSON.parse(JSON.stringify(content))
        clonedContent.attempt = attempts[clonedContent.id] ?? null
        data[participationId] = clonedContent
      }
    })

    return data
  },
  canStartContent(state, getters) {
    return (participation, content) => {
      if (!participation) {
        return false
      }
      const course = getters.findCourse(participation.course_id)
      if (course.can_navigate_freely) {
        return true
      }

      const contents = getters.allContentsByCourse[participation.course_id]
      const contentIndex = contents.indexOf(content)
      if (contentIndex === 0) {
        return true
      }
      const previousContent = contents[contentIndex - 1]
      const contentAttempts = getters.findContentAttempts(participation.id)
      if (!contentAttempts) {
        return false
      }
      const previousContentAttempt = contentAttempts[previousContent?.id]
      return !!previousContentAttempt?.passed
    }
  },
  getParticipationContent(state, getters) {
    return (participationId, contentId) => {
      const contentsByCourse = getters.allContentsByCourse
      const participation = state.participations[participationId]
      const contents = contentsByCourse[participation.course_id]
      const participationsAttempts = getters.allParticipationsContentAttempts
      const attempts = participationsAttempts[participationId]
      const content = contents.find((entry) => entry.id === contentId)
      const clonedContent = JSON.parse(JSON.stringify(content))
      clonedContent.attempt = attempts[clonedContent.id] ?? null
      return clonedContent
    }
  },
  activeCourses(state, getters) {
    if (!getters.allCourses) {
      return getters.allCourses
    }
    return getters.allCourses.filter((course) => {
      const hasNotFinished = course.participation === null || course.participation.passed === null
      if (hasNotFinished && !course.preview) {
        return true
      }
      return false
    }).sort((courseA, courseB) => {
      if (!courseA.participation && !courseB.participation) {
        return courseB.id - courseA.id
      }
      if (!courseA.participation) {
        return 1
      }
      if (!courseB.participation) {
        return -1
      }
      return courseB.participation.updated_at.localeCompare(courseA.participation.updated_at)
    })
  },
  visibleCourses(state, getters) {
    if (!getters.allCourses) {
      return getters.allCourses
    }
    let courses = [...getters.allCourses]

    if (!state.searchQuery) {
      if (state.filter === constants.COURSES.FILTER_ACTIVE) {
        courses = courses.filter((course) => course.participation === null || course.participation.passed === null)
      }
      if (state.filter === constants.COURSES.FILTER_FINISHED) {
        courses = courses.filter((course) => course.participation !== null && course.participation.passed !== null)
      }
      if (state.filter === constants.COURSES.FILTER_PREVIEW) {
        courses = courses.filter((course) => course.preview)
      }
      if (state.filter === constants.COURSES.FILTER_MANDATORY) {
        courses = courses.filter((course) => (course.participation === null || course.participation.passed === null) && course.is_mandatory)
      }
      if (state.categoryFilters.length) {
        courses = courses.filter((course) => {
          if (!course.categories) {
            return false
          }
          return course.categories.some((category) => state.categoryFilters.includes(category.id))
        })
      }
    }

    if (state.searchQuery) {
      const query = state.searchQuery.toLowerCase()
      courses = courses.filter((course) => {
        if (course.title.toLowerCase().indexOf(query) !== -1) {
          return true
        }
        if (course.description.toLowerCase().indexOf(query) !== -1) {
          return true
        }

        return course.contents.some((content) => {
          if (content.title.toLowerCase().indexOf(query) !== -1) {
            return true
          }
          return false
        })
      })
    }

    courses.sort((a, b) => {
      if (!a.participation && b.participation) {
        return 1
      }
      if (a.participation && !b.participation) {
        return -1
      }
      // Show preview courses below normal courses when default sort is is applied
      if ([constants.COURSES.SORT_NAME_DESC, constants.COURSES.SORT_NAME_ASC].includes(state.sort)) {
        if (a.preview && !b.preview) {
          return 1
        }
        if (!a.preview && b.preview) {
          return -1
        }
      }
      let descMultiplicator = 1
      if ([constants.COURSES.SORT_NAME_DESC, constants.COURSES.SORT_AVAILABLE_FROM_DESC].includes(state.sort)) {
        descMultiplicator = -1
      }
      let field = 'title'
      if ([constants.COURSES.SORT_AVAILABLE_FROM_DESC, constants.COURSES.SORT_AVAILABLE_FROM_ASC].includes(state.sort)) {
        field = 'available_from'
      }
      return (a[field] || '').localeCompare(b[field], undefined, { numeric: true }) * descMultiplicator
    })

    return courses
  },
  availableCategories(state) {
    if (!state.courses) {
      return []
    }
    return Object.values(state.courses).reduce((categories, course) => {
      if (!course.categories) {
        return categories
      }
      course.categories.forEach((category) => {
        if (!categories.some((c) => c.id === category.id)) {
          categories.push(category)
        }
      })
      return categories
    }, [])
  },
  activeCategoryFilters(state) {
    return state.categoryFilters
  },
  filter(state) {
    return state.filter
  },
  findContentWBTEvents(state) {
    return (contentId) => state.contentWBTEvents[parseInt(contentId, 10)] || []
  },
  sort(state) {
    return state.sort
  },
  searchQuery(state) {
    return state.searchQuery
  },
  searchOpen(state) {
    return state.searchOpen
  },
  isFiltered(state, getters) {
    return !!getters.searchQuery
  },
  hasPreviewCourses(state, getters) {
    if (!getters.allCourses) {
      return false
    }
    return getters.allCourses.some((course) => course.preview)
  },
}
