import constants from '@/constants'
import NewsAPI from '@/modules/news/api/news'

export default {
  updateNews({ commit }) {
    return NewsAPI.getNews().then((data) => {
      const keyedNews = {}
      data.news.forEach((newsEntry) => {
        keyedNews[newsEntry.id] = newsEntry
        commit('likes/setLikesIt', {
          foreignType: constants.LIKES.TYPE_NEWS,
          foreignId: newsEntry.id,
          likesIt: newsEntry.likes_it,
        }, {
          root: true,
        })
      })
      commit('setAllNews', keyedNews)
    })
  },
  findNewsEntry({ commit }, id) {
    return NewsAPI.findNewsEntry(id).then((data) => {
      commit('setNewsEntry', data)
    })
  },
}
