export default {
  activeQuizzes: (state) => state.activeQuizzes,
  hasNoActiveQuiz: (state, getters) => getters.activeQuizzes && getters.activeQuizzes.length === 0,
  getGame(state) {
    if (!state.games) {
      return () => null
    }
    return (id) => state.games[id]
  },
  bots: (state) => state.bots,
  history: (state) => state.history,
}
