import api from '@/logic/api'

export default {
  getFeedback: () => api.get('/feedback/ratingStatus'),
  sendFeedback: (rating, message, type, id) => api.post('/feedback/send', {
    subject: '',
    message,
    rating,
    type,
    id,
  }),
}
