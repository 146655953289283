export default {
  pagesList(state) {
    if (!state.pagesList) {
      return state.pagesList
    }
    const pages = Object.values(state.pagesList)
    pages.sort((a, b) => a.title.localeCompare(b.title))
    return pages
  },
  footerPagesList(state, getters) {
    if (!getters.pagesList) {
      return getters.pagesList
    }
    return getters.pagesList.filter((page) => page.show_in_footer)
  },
  findPage(state) {
    if (!state.pages) {
      return () => null
    }
    return (id) => state.pages[id]
  },
  findPublicPage(state) {
    if (!state.publicPages) {
      return () => null
    }
    return (id) => state.publicPages[id]
  },
  tos(state) {
    return state.tos
  },
}
