<template>
  <div
    v-if="hasModule.news"
    data-cy="NewsBlock">
    <RecentNews />
    <router-link
      :to="{
        name: 'news.news',
      }"
      class="t-body3 u-colorPrimary t-fontWeightMedium t-noLinkAppearance u-aboveCard u-inlineBlock u-marginTop1"
      data-cy="NewsBlock__openNews">
      {{ $t('news.show_all_news') }}
      <SvgIcon
        type="chevron_right"
        tiny />
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import RecentNews from '@/modules/news/components/RecentNews.vue'

export default {
  computed: {
    ...mapGetters({
      hasModule: 'auth/hasModule',
    }),
  },
  components: {
    RecentNews,
  },
}
</script>
