import Vue from 'vue'

import constants from '@/constants'

export default {
  setLearningmaterialFolders(state, folders) {
    Vue.set(state, 'learningmaterialFolders', folders)
  },
  setLearningmaterial(state, learningmaterial) {
    const folderIndex = state.learningmaterialFolders.findIndex((folder) => folder.id === learningmaterial.learning_material_folder_id)
    if (folderIndex === -1) {
      state.learningmaterialFolders.push({
        ...learningmaterial.learning_material_folder,
        materials: [learningmaterial],
      })
    } else {
      const folderMaterialIndex = state.learningmaterialFolders[folderIndex].materials.findIndex((material) => material.id === learningmaterial.id)
      if (folderMaterialIndex === -1) {
        state.learningmaterialFolders[folderIndex].materials.push(learningmaterial)
      } else {
        Vue.set(state.learningmaterialFolders[folderIndex].materials, folderMaterialIndex, learningmaterial)
      }
    }
  },
  setWBTEvent(state, { learningmaterialId, events }) {
    Vue.set(state.wbtEvents, learningmaterialId, events)
  },
  setWBTEvents(state, events) {
    events.forEach((event) => {
      if (typeof state.wbtEvents[event.learning_material_id] === 'undefined') {
        Vue.set(state.wbtEvents, event.learning_material_id, [])
      }
      state.wbtEvents[event.learning_material_id].push(event)
    })
  },
  setTypeFilters(state, typeFilters) {
    Vue.set(state, 'typeFilters', typeFilters)
  },
  removeTypeFilter(state, filter) {
    const index = state.typeFilters.findIndex((filterEntry) => filterEntry === filter)
    Vue.delete(state.typeFilters, index)
  },
  setSort(state, sort) {
    state.sort = sort
  },
  setSearchQuery(state, query) {
    state.searchQuery = query
  },
  setFoundLearningmaterials(state, query) {
    state.foundLearningmaterials = query
  },
  setSearchOpen(state, value) {
    state.searchOpen = value
  },
  toggleSearchOpen(state) {
    state.searchOpen = !state.searchOpen
  },
  setUserSelectionFunction(state, userSelectionFunction) {
    state.userSelectionFunction = userSelectionFunction
  },
  resetFilters(state) {
    state.searchQuery = null
    state.searchOpen = false
    state.typeFilters = []
    state.sort = constants.LEARNINGMATERIALS.SORT_NAME_ASC
  },
}
