export default {
  advertisementsForPosition(state) {
    if (!state.advertisements) {
      return () => state.advertisements
    }
    return (position) => state.advertisements
      .filter((advertisement) => advertisement.positions.includes(position))
      .sort((a, b) => b.id.toString().localeCompare(a.id.toString()))
  },
}
