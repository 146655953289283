import TodolistsAPI from '@/modules/todolists/api/todolists'

export default {
  setItemAnswer({ commit }, { isDone, itemId }) {
    return TodolistsAPI.setItemAnswer(itemId, isDone).then((data) => {
      commit('setItemAnswer', {
        itemId,
        isDone: data.itemAnswer.is_done,
      })
    })
  },
  updateItemAnswers({ commit }) {
    return TodolistsAPI.getItemAnswers().then((data) => {
      commit('setItemAnswers', data.answers)
    })
  },
  updateTodolists({ commit }) {
    return TodolistsAPI.getTodolists().then((data) => {
      commit('setTodolists', data.todolists)
    })
  },
}
