<template>
  <portal-target
    v-show="isVisible"
    class="c-sidebar"
    data-cy="DefaultSidebar"
    name="sidebar"
    @change="handleUpdate" />
</template>

<script>
export default {
  data() {
    return {
      isVisible: false,
    }
  },
  methods: {
    handleUpdate(hasNewContent) {
      this.isVisible = hasNewContent
    },
  },
}
</script>
