import deepmerge from 'deepmerge'

import { getPersistedState } from '@/logic/statePersisting'

const defaultState = {
  notificationSettings: null,
  tags: [],
  vouchers: [],
}

const persistedState = getPersistedState('profile')

export default deepmerge(defaultState, persistedState)
