<template>
  <router-link
    tag="a"
    :to="{
      name: 'tests.details',
      params: {
        testId: test.id,
      },
    }"
    class="c-card -clickable">
    <div class="c-card__content">
      <div class="o-flex">
        <img
          v-if="test.icon_url"
          :src="test.icon_url"
          class="c-card__previewImage" />
        <img
          v-else
          src="@/assets/images/pruefungen/cards/coding_1.svg"
          class="c-card__previewImage" />
        <div>
          <div
            v-if="test.minutes"
            class="t-caption1 u-colorPrimary">
            <SvgIcon
              type="timer"
              tiny />
            {{ $tc('tests.duration', test.minutes, { minutes: test.minutes })}}
          </div>
          <div class="t-body2 t-fontWeightMedium o-flex__item -grow">
            {{ test.name }}
          </div>
          <div
            v-if="test.submissionAvailable"
            class="t-caption1">
            <div
              v-if="test.active_until && !endsSoon"
              class="u-colorDarkMediumEmphasis">
              {{ $t('tests.available_until') }} {{ test.active_until | date }}
            </div>
            <div
              v-if="test.active_until && endsSoon"
              class="u-colorSecondary t-fontWeightMedium">
              {{ $tc('tests.only_x_days_left', daysLeft, { x: daysLeft }) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import testActiveUntilMixin from '@/modules/tests/mixins/testActiveUntilMixin'

export default {
  mixins: [testActiveUntilMixin],
  props: {
    test: {
      required: true,
      type: Object,
    },
  },
}
</script>
