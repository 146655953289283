var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.hasModule.advertisements)?_c('div',{staticClass:"c-visualhighlight__wrapper",class:{
    '-centered': _vm.centered,
    '-centered@tabletPortrait': !_vm.onlyRectangle && _vm.advertisement.leaderboard_image_url,
    '-desktopRow': _vm.desktopRow,
  },attrs:{"data-cy":"AdCard"}},[(_vm.advertisement.is_ad || _vm.advertisement.rectangle_image_url)?_c('div',{staticClass:"c-visualhighlight",class:{
      '-textOnly': !_vm.advertisement.rectangle_image_url && _vm.advertisement.description,
    }},[(_vm.advertisement.is_ad)?_c('div',{staticClass:"c-visualhighlight__infotext"},[_vm._v(" "+_vm._s(_vm.$t('generic.ad'))+" ")]):_vm._e(),(_vm.advertisement.rectangle_image_url)?_c(_vm.wrapperTag,_vm._b({tag:"component",staticClass:"c-visualhighlight__image"},'component',_vm.wrapperAttributes,false),[_c('picture',[(!_vm.onlyRectangle && _vm.advertisement.leaderboard_image_url)?_c('source',{attrs:{"media":"(min-width: 768px)","srcset":_vm.advertisement.leaderboard_image_url}}):_vm._e(),_c('img',{attrs:{"src":_vm.advertisement.rectangle_image_url,"data-cy":"AdCard__image","alt":"Advertisement"}})])]):_vm._e()],1):_vm._e(),(_vm.advertisement.description)?_c('div',{staticClass:"c-visualhighlight__text c-card -fullWidth"},[_c('div',{directives:[{name:"internallinks",rawName:"v-internallinks"}],staticClass:"c-card__content o-textbody",domProps:{"innerHTML":_vm._s(_vm.advertisement.description)}})]):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }