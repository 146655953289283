<template>
  <div
    v-if="hasModule.advertisements"
    class="c-visualhighlight__wrapper"
    :class="{
      '-centered': centered,
      '-centered@tabletPortrait': !onlyRectangle && advertisement.leaderboard_image_url,
      '-desktopRow': desktopRow,
    }"
    data-cy="AdCard">
    <div
      v-if="advertisement.is_ad || advertisement.rectangle_image_url"
      class="c-visualhighlight"
      :class="{
        '-textOnly': !advertisement.rectangle_image_url && advertisement.description,
      }">
      <div
        v-if="advertisement.is_ad"
        class="c-visualhighlight__infotext">
        {{ $t('generic.ad') }}
      </div>
      <component
        :is="wrapperTag"
        v-if="advertisement.rectangle_image_url"
        class="c-visualhighlight__image"
        v-bind="wrapperAttributes">
        <picture>
          <source
            v-if="!onlyRectangle && advertisement.leaderboard_image_url"
            media="(min-width: 768px)"
            :srcset="advertisement.leaderboard_image_url" />
          <img
            :src="advertisement.rectangle_image_url"
            data-cy="AdCard__image"
            alt="Advertisement" />
        </picture>
      </component>
    </div>
    <div
      v-if="advertisement.description"
      class="c-visualhighlight__text c-card -fullWidth">
      <div
        v-internallinks
        class="c-card__content o-textbody"
        v-html="advertisement.description" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    advertisement: {
      required: true,
      type: Object,
    },
    onlyRectangle: {
      required: false,
      type: Boolean,
      default: false,
    },
    centered: {
      required: false,
      type: Boolean,
      default: false,
    },
    desktopRow: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      hasModule: 'auth/hasModule',
    }),
    wrapperTag() {
      if (this.advertisement.link) {
        return 'a'
      }
      return 'div'
    },
    wrapperAttributes() {
      if (!this.advertisement.link) {
        return {}
      }
      return {
        href: this.advertisement.link,
        target: '_blank',
        rel: 'noopener',
      }
    },
  },
}
</script>
