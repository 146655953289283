import { getSentryInstance } from '@/logic/init/sentry'
import AuthAPI from '@/modules/auth/api/auth'

let notificationsAreSetup = false

function setupTokenHandler(store) {
  window.Capacitor.Plugins.PushNotifications.addListener('registration', (data) => {
    const token = data.value
    store.commit('auth/setFCMToken', token)
    AuthAPI.setFCMToken(token)
  })
  window.Capacitor.Plugins.PushNotifications.addListener('registrationError', (error) => {
    /* eslint-disable no-console */
    console.log('got notification error')
    console.log(error)
    /* eslint-enable no-console */
  })
}

function setupNotificationHandler(store, router) {
  window.Capacitor.Plugins.PushNotifications.addListener('pushNotificationActionPerformed', (event) => {
    const notification = event.notification
    // Update the route if we got a new url from the message
    const data = notification.data
    if (typeof data.open_url !== 'undefined') {
      if (data.open_url[0] === '/') {
        router.push(data.open_url)
      }
    }
  })
  window.Capacitor.Plugins.PushNotifications.addListener('pushNotificationReceived', (notification) => {
    // Update the route if we got a new url from the message
    const data = notification.data
    let route = null
    if (typeof data.open_url !== 'undefined') {
      if (data.open_url[0] === '/') {
        route = data.open_url
      }
    }

    if (!data.title) {
      window.console.warn('Suppressed empty push notification', notification)
      const Sentry = getSentryInstance()
      if (Sentry) {
        Sentry.captureException('Suppressed empty push notification', notification)
      }
      return
    }

    store.commit('notifications/addNotification', {
      title: data.title,
      route,
    })
  })
}

export default async function (store, i18n, router) {
  if (!window.Capacitor) {
    return
  }
  if (notificationsAreSetup) {
    return
  }
  if (!store.getters['auth/isLoggedIn']) {
    return
  }
  // TODO: Maybe setup notifications only after asking the user if they want to do it
  let permStatus = await window.Capacitor.Plugins.PushNotifications.checkPermissions()

  if (permStatus.receive === 'prompt') {
    permStatus = await window.Capacitor.Plugins.PushNotifications.requestPermissions()
  }
  if (permStatus.receive !== 'granted') {
    return
  }
  setupTokenHandler(store)
  setupNotificationHandler(store, router)
  window.Capacitor.Plugins.PushNotifications.register()
  notificationsAreSetup = true
}
