module.exports = {
  currently_no_todolists_visible: 'Hier gibt es aktuell keine Aufgaben.',
  filters: {
    active: 'Aktiv',
    done: 'Abgeschlossen',
  },
  task_details: 'Aufgabendetails',
  tasks: 'Aufgaben',
  todolist: 'Aufgabenliste',
  x_of_y_done: '{x}/{y} erledigt',
  x_tasks: '{x} Aufgaben',
  need_to_upload_at_least_one_file: 'Du musst mindestens eine Datei hochladen um diese Aufgabe abzuschließen.',
  preview_comments_disabled: 'In der Vorschau ist die Kommentarfunktion deaktiviert.',
}
