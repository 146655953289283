<template>
  <div v-if="activeCourses && activeCourses.length">
    <div class="o-grid -equalHeight">
      <div
        v-for="course in coursesAsBigCards"
        :key="course.id"
        class="o-grid__cell -c12 -c4@tabletMini -c3@desktopSmall">
        <MiniCourseCard :course="course" />
      </div>
      <div
        v-for="course in coursesAsSmallCards"
        :key="course.id"
        class="o-grid__cell -c6 -c3@tabletMini -c2@desktopSmall">
        <TinyCourseCard :course="course" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import constants from '@/constants'
import MiniCourseCard from '@/modules/courses/components/MiniCourseCard.vue'
import TinyCourseCard from '@/modules/courses/components/TinyCourseCard.vue'

export default {
  props: {
    rowCount: {
      type: Number,
      required: false,
      default: constants.HOMEPAGE.COURSES.DEFAULT_ROWS,
    },
  },
  computed: {
    ...mapGetters({
      activeCourses: 'courses/activeCourses',
      screenSize: 'screenSize',
    }),
    courseCountInBigCard() {
      if (this.screenSize.lt.tabletMini) {
        return this.rowCount > 1 ? 2 : 1
      }
      if (this.screenSize.lt.desktopSmall) {
        return 3
      }
      return this.$constants.HOMEPAGE.COURSES.ITEMS_PER_ROW
    },
    courseCountInSmallCard() {
      if (this.rowCount === 1) {
        return 0
      }
      if (this.screenSize.lt.tabletMini) {
        return (this.rowCount - 2) * 2
      }
      if (this.screenSize.lt.desktopSmall) {
        return (this.rowCount - 1) * 4
      }
      return (this.rowCount - 1) * 6
    },
    coursesAsBigCards() {
      if (!this.activeCourses) {
        return this.activeCourses
      }
      return this.activeCourses.slice(0, this.courseCountInBigCard)
    },
    coursesAsSmallCards() {
      if (!this.activeCourses) {
        return this.activeCourses
      }
      if (!this.courseCountInSmallCard) {
        return []
      }
      return this.activeCourses.slice(this.courseCountInBigCard, this.courseCountInBigCard + this.courseCountInSmallCard)
    },
  },
  components: {
    MiniCourseCard,
    TinyCourseCard,
  },
}
</script>
