import CoursesAPI from '@/modules/courses/api/courses'

export default {
  async updateContentWBTEvents({ commit }, { courseId, contentId }) {
    const result = await CoursesAPI.getContentWBTEvents(courseId, contentId)
    commit('setContentWBTEvents', {
      contentId,
      events: result.events,
    })
  },
  updateCourses({ commit }) {
    return CoursesAPI.getCourses().then((data) => {
      const keyedCourses = {}
      Object.values(data).forEach((course) => {
        keyedCourses[course.id] = course
      })
      commit('setAllCourses', keyedCourses)
    })
  },
  updateCourseDetails({ commit }, id) {
    return CoursesAPI.getCourseDetails(id).then((data) => {
      commit('setCourseDetails', data.course)
    })
  },
  async startParticipation({ dispatch }, id) {
    const participation = await CoursesAPI.startParticipation(id)
    await dispatch('updateCourseDetails', id)
    return participation
  },
  async updateParticipation({ commit }, { courseId, participationId }) {
    const participation = await CoursesAPI.getParticipation(courseId, participationId)
    commit('setParticipation', participation)
    return participation
  },
  async markContentAsPassed({ commit }, { courseId, participationId, contentId }) {
    const contentAttempt = await CoursesAPI.markContentAsPassed(courseId, participationId, contentId)
    commit('updateParticipationData', contentAttempt.course_participation)
    commit('setContentAttempt', { participationId, contentAttempt })
    return contentAttempt
  },
  async answerQuestion({ commit }, {
    courseId,
    participationId,
    contentId,
    questionId,
    answerId,
  }) {
    const data = await CoursesAPI.answerQuestion(courseId, participationId, contentId, questionId, answerId)
    commit('updateParticipationData', data.attempt.course_participation)
    commit('setContentAttempt', { participationId, contentAttempt: data.attempt })
    return data
  },
  async submitForm({ commit }, {
    courseId,
    participationId,
    contentId,
    formId,
    answerFields,
  }) {
    const data = await CoursesAPI.submitForm(courseId, participationId, contentId, formId, answerFields)
    commit('updateParticipationData', data.attempt.course_participation)
    commit('setContentAttempt', { participationId, contentAttempt: data.attempt })
    return data
  },
  async repeatAttempt({ commit }, { courseId, participationId, contentId }) {
    const contentAttempt = await CoursesAPI.repeatAttempt(courseId, participationId, contentId)

    commit('updateParticipationData', contentAttempt.course_participation)
    commit('setContentAttempt', { participationId, contentAttempt })
    return contentAttempt
  },
}
