<template>
  <button
    :style="notificationStyle"
    class="o-unstyled c-notification"
    data-cy="NotificationEntry"
    @touchstart="handleTouchstart"
    @touchmove="handleTouchmove"
    @click="handleClick">
    <div class="c-notification__header">
      <div class="c-notification__title">
        <div
          v-if="notification.avatar"
          class="c-avatar -tiny u-marginRight1">
          <img
            :src="notification.avatar"
            class="c-avatar__photo" />
        </div>
        <div class="c-notification__topic">{{ topic }}</div>
      </div>
      <div class="c-notification__close">
        <SvgIcon
          type="close"
          small
          @click.stop="closeNotification" />
      </div>
    </div>
    <div
      class="c-notification__content t-preWrap"
      v-text="notification.title" />
    <div
      v-if="hasActions"
      class="c-notification__actions">
      <button
        v-for="(actionCallback, actionLabel) in notification.actions"
        :key="actionLabel"
        class="c-button -outline -primary"
        @click="actionCallback">
        {{ actionLabel }}
      </button>
    </div>
  </button>
</template>

<script>
export default {
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      startX: 0,
      translateX: 0,
      destroyTimeout: null,
    }
  },
  created() {
    window.addEventListener('touchend', this.handleTouchend)
    this.destroyTimeout = window.setTimeout(this.closeNotification, 10000)
  },
  destroyed() {
    window.removeEventListener('touchend', this.handleTouchend)
    if (this.destroyTimeout) {
      window.clearTimeout(this.destroyTimeout)
    }
  },
  computed: {
    notificationStyle() {
      return {
        transform: `translateX(${this.translateX}px)`,
      }
    },
    hasActions() {
      return typeof this.notification.actions !== 'undefined'
    },
    topic() {
      if (typeof this.notification.topic === 'undefined') {
        return this.$t('notifications.info')
      }
      return this.notification.topic
    },
  },
  methods: {
    closeNotification() {
      this.$store.commit('notifications/removeNotification', this.notification)
    },
    handleClick() {
      if (this.notification.route) {
        this.$router.push(this.notification.route)
      }
      if (typeof this.notification.callback === 'function') {
        this.notification.callback()
      }
      this.closeNotification()
    },
    handleTouchstart(e) {
      this.startX = e.touches[0].clientX
      this.translateX = 0
    },
    handleTouchmove(e) {
      this.translateX = e.touches[0].clientX - this.startX
    },
    handleTouchend() {
      if (Math.abs(this.translateX) > 100) {
        this.closeNotification()
      } else {
        this.translateX = 0
      }
    },
  },
}
</script>
