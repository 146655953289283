import Vue from 'vue'

import { highlightKeywords } from '@/modules/keywords/logic/keywordHighlighting'

let localRouter = null
let localStore = null

function handleLinkClick(e) {
  const element = e.target.closest('a')
  if (!element) {
    return true
  }
  // Ignore external links, # Links, Pdf Links
  const target = element.getAttribute('target')
  const href = element.getAttribute('href')
  if (target === '_blank' || href.startsWith('#') || href.endsWith('.pdf') || e.metaKey || e.ctrlKey || e.altKey || e.shiftKey) {
    return true
  }

  // Ignore non same-host links
  const targetUrl = new URL(href)

  if (!localStore.getters['auth/config'].app_domains.includes(targetUrl.host)) {
    return true
  }

  e.stopPropagation()
  e.preventDefault()

  localRouter.push(targetUrl.pathname)

  return false
}

export default function (store, router) {
  localRouter = router
  localStore = store
  Vue.directive('internallinks', {
    // When the bound element is inserted into the DOM...
    inserted(el) {
      el.addEventListener('click', handleLinkClick)
    },
  })
  Vue.directive('highlightKeywords', {
    // When the bound element is inserted into the DOM...
    async inserted(el) {
      if (!store.getters['auth/config'].settings.module_keywords || !store.getters['auth/isLoggedIn']) {
        return
      }
      await highlightKeywords(el, store)
    },
  })
  Vue.directive('click-outside', {
    bind(element, binding, vnode) {
      element.clickOutsideEvent = function (event) {
        if (element !== event.target && !element.contains(event.target)) {
          vnode.context[binding.expression](event)
        }
      }
      document.body.addEventListener('click', element.clickOutsideEvent)
    },
    unbind(element) {
      document.body.removeEventListener('click', element.clickOutsideEvent)
    },
  })
}
