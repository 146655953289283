function handleKeywordClick(keywordId, store) {
  store.commit('keywords/setSelectedKeywordId', keywordId)
}

const foundKeywordIds = new Set()

/**
 * Create a document fragment containing the text written in front of the keyword, the highlighted keyword span and the text written after the keyword
 * E.g. in pseudocode for the keyword "bread":
 * "I like bread, it's tasty"
 * becomes:
 * [
 *   'I like ' (text node)
 *   '<span class="c-keyword">bread</span>' (html span element)
 *   ', it's tasty' (text node)
 * ]
 * @param keyword
 * @param keywordIndex
 * @param store
 * @param originalContent
 */
function highlightKeyword(keyword, keywordIndex, store, originalContent) {
  // A document fragment is basically just a list of nodes
  const fragment = new DocumentFragment()
  // Text in front of the keyword
  const preText = keywordIndex > 0 ? originalContent.substring(0, keywordIndex) : null
  // Text after the keyword
  const postText = keywordIndex < originalContent.length ? originalContent.substring(keywordIndex + keyword.name.length) : null

  if (preText) {
    fragment.appendChild(document.createTextNode(preText))
  }

  const keywordNode = document.createElement('span')
  keywordNode.classList.add('c-keyword')
  keywordNode.addEventListener('click', () => handleKeywordClick(keyword.id, store))
  keywordNode.textContent = originalContent.substring(keywordIndex, keywordIndex + keyword.name.length)
  fragment.appendChild(keywordNode)

  if (postText) {
    fragment.appendChild(document.createTextNode(postText))
  }

  return fragment
}

/**
 * Takes an html node and makes sure all keywords are highlighted with a span and
 * have a click event handler
 */
function insertKeywordNodes(node, store) {
  if (!node) {
    return node
  }
  // We don't want to replace any keywords in an existing keyword
  if (node.tagName === 'SPAN' && node.classList.contains('c-keyword')) {
    return node
  }
  // We don't want to replace any keywords in links
  if (node.tagName === 'A') {
    return node
  }
  // Only text nodes have actual text, so we only need to look for keywords in those.
  if (node.nodeType === node.TEXT_NODE) {
    const originalContent = node.textContent
    const lowerCaseContent = originalContent.toLowerCase()
    let foundKeyword = false

    store.getters['keywords/allKeywords'].forEach((keyword) => {
      if (foundKeyword) {
        return
      }
      const keywordIndex = lowerCaseContent.indexOf(keyword.name.toLocaleLowerCase())
      if (keywordIndex > -1) {
        foundKeyword = true
        foundKeywordIds.add(keyword.id)
        // Set the node to the new fragment, because
        // we iterate over the node's children in the next step
        // which will take care of finding (other) keywords
        // in the text before and after the keyword.
        node = highlightKeyword(keyword, keywordIndex, store, originalContent)
      }
    })
  }
  if (node.childNodes) {
    node.childNodes.forEach((childNode) => {
      // Replace each child with the node that is returned by the insertKeywordNodes function
      // If no keyword is found, this will effectively do nothing, otherwise it will insert the
      // nodes that are created by the `highlightKeyword` function.
      node.replaceChild(insertKeywordNodes(childNode, store), childNode)
    })
  }
  return node
}

/**
 * We don't initially load all keyword descriptions to reduce traffic if there are a lot of keywords
 * When we detect a keyword being used, we preload those descriptions, so that they are available once the admin clicks on them
 */
function preloadMissingKeywordDescriptions(store) {
  const keywordsWithoutDescription = []
  foundKeywordIds.forEach((keywordId) => {
    if (!store.getters['keywords/descriptionExists'](keywordId)) {
      keywordsWithoutDescription.push(keywordId)
    }
  })
  if (keywordsWithoutDescription.length > 0) {
    store.dispatch('keywords/updateDescriptions', keywordsWithoutDescription)
  }
}

/**
 * Takes an html element and makes sure that all keywords in it are replaced with a span that highlights
 * the keyword and has an appropriate click event.
 */
export async function highlightKeywords(el, store) {
  const keywords = store.getters['keywords/allKeywords']
  if (!keywords) {
    await store.dispatch('keywords/updateKeywords')
  }
  foundKeywordIds.clear()
  const outputNode = insertKeywordNodes(el, store)
  preloadMissingKeywordDescriptions(store)
  return outputNode
}
