<template>
  <GenericModal
    v-model="isOpen"
    large>
    <div class="c-modal__header">
      <div
        v-if="keyword"
        class="c-modal__title">
        {{ keyword.name }}
      </div>
      <button
        class="c-modal__close"
        @click="close">
        <SvgIcon
          type="close"
          small />
      </button>
    </div>
    <div
      v-if="keyword"
      v-internallinks
      class="c-modal__content o-textbody"
      v-html="keyword.description" />
  </GenericModal>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      keywords: 'keywords/allKeywords',
      selectedKeywordId: 'keywords/selectedKeywordId',
    }),
    keyword() {
      if (!this.selectedKeywordId) {
        return null
      }
      return this.keywords.find((keyword) => keyword.id === this.selectedKeywordId)
    },
    isOpen: {
      get() {
        return this.keyword !== null
      },
      set(value) {
        if (!value) {
          this.$store.commit('keywords/setSelectedKeywordId', null)
        }
      },
    },
  },
  methods: {
    close() {
      this.isOpen = false
    },
  },
}
</script>
