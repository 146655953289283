import api from '@/logic/api'

export default {
  getKeywords() {
    return api.get('/keywords')
  },
  getDescriptions(keywords) {
    return api.get('/keywords/descriptions', {
      keywords,
    })
  },
}
