var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{staticClass:"c-card -clickable",class:{
    '-important': _vm.isImportant,
  },attrs:{"tag":"a","to":{
    name: 'courses.details',
    params: {
      courseId: _vm.course.id,
    },
  }}},[_c('div',{staticClass:"c-card__header -imageFill -primaryBg -small"},[(_vm.course.cover_image_url)?_c('img',{attrs:{"src":_vm.course.cover_image_url}}):(_vm.$store.getters['auth/config'].settings.course_default_image)?_c('img',{attrs:{"src":_vm.$store.getters['auth/config'].settings.course_default_image}}):_c('img',{staticStyle:{"object-fit":"contain"},attrs:{"src":"/img/no-connection.svg"}}),(_vm.course.is_mandatory)?_c('div',{staticClass:"c-card__headerBadges"},[_c('div',{staticClass:"c-badge -text u-colorWhite u-bgImportant t-caption1 u-marginLeft2"},[_vm._v(" "+_vm._s(_vm.$t('courses.mandatory_course'))+" ")])]):_vm._e()]),_c('div',{staticClass:"c-card__content"},[(_vm.categories)?_c('div',{staticClass:"t-caption1 u-colorPrimar s-category"},[_vm._v(" "+_vm._s(_vm.categories)+" ")]):_vm._e(),_c('div',{staticClass:"t-body2 t-fontWeightMedium t-clamp3 t-breakWords o-flex__item -grow"},[_vm._v(" "+_vm._s(_vm.course.title)+" ")]),(_vm.course.participation)?_c('div',{staticClass:"o-flex -row -alignCenter -spaceBetween u-marginTop2"},[_c('div',{staticClass:"t-caption1 u-colorPrimary u-marginRight1"},[_vm._v(" "+_vm._s(_vm.$t('courses.x_of_y_lessons_done', { x: _vm.course.passed_contents, y: _vm.course.contents_count }))+" ")]),_c('ProgressBar',{attrs:{"current":_vm.course.passed_contents,"total":_vm.course.contents_count,"slim":"","no-label":""}})],1):_vm._e(),(_vm.course.available_until && _vm.course.duration_type == _vm.$constants.COURSES.DURATION_TYPE_FIXED)?_c('DaysLeft',{staticClass:"u-colorImportant u-marginTop2",attrs:{"end-date":_vm.course.available_until}}):_vm._e(),(_vm.course.duration_type == _vm.$constants.COURSES.DURATION_TYPE_DYNAMIC && _vm.dynamicEndDate)?_c('DaysLeft',{staticClass:"u-colorImportant u-marginTop2",attrs:{"end-date":_vm.dynamicEndDate}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }