import deLocale from 'date-fns/locale/de'

import store from '@/store'

const languageIdToLocaleFolder = {
  al: 'sq',
  bg: 'bg',
  cs: 'cs',
  de_formal: 'de',
  de: 'de',
  en: 'en-US',
  es: 'es',
  fr: 'fr',
  hr: 'hr',
  hu: 'hu',
  it: 'it',
  jp: 'ja',
  nl: 'nl-BE',
  no: 'nn',
  pl: 'pl',
  pt: 'pt',
  ro: 'ro',
  ru: 'ru',
  sr: 'sr-Latn',
  tr: 'tr',
  zh: 'zh-CN',
}

const defaultLocaleFolder = 'de'
const loadedLocales = {
  de: deLocale,
}

function webpackImportByFolder(localeFolder) {
  switch (localeFolder) {
    // eslint-disable-next-line default-case-last
    default:
    case 'bg':
      return import(/* webpackChunkName: "datefns-locale-bg" */ 'date-fns/locale/bg/index')
    case 'cs':
      return import(/* webpackChunkName: "datefns-locale-cs" */ 'date-fns/locale/cs/index')
    case 'de':
      return import(/* webpackChunkName: "datefns-locale-de" */ 'date-fns/locale/de/index')
    case 'en-US':
      return import(/* webpackChunkName: "datefns-locale-en-US" */ 'date-fns/locale/en-US/index')
    case 'es':
      return import(/* webpackChunkName: "datefns-locale-es" */ 'date-fns/locale/es/index')
    case 'fr':
      return import(/* webpackChunkName: "datefns-locale-fr" */ 'date-fns/locale/fr/index')
    case 'hr':
      return import(/* webpackChunkName: "datefns-locale-hr" */ 'date-fns/locale/hr/index')
    case 'it':
      return import(/* webpackChunkName: "datefns-locale-it" */ 'date-fns/locale/it/index')
    case 'ja':
      return import(/* webpackChunkName: "datefns-locale-ja" */ 'date-fns/locale/ja/index')
    case 'nl-BE':
      return import(/* webpackChunkName: "datefns-locale-nl-BE" */ 'date-fns/locale/nl-BE/index')
    case 'nn':
      return import(/* webpackChunkName: "datefns-locale-nn" */ 'date-fns/locale/nn/index')
    case 'pl':
      return import(/* webpackChunkName: "datefns-locale-pl" */ 'date-fns/locale/pl/index')
    case 'pt':
      return import(/* webpackChunkName: "datefns-locale-pt" */ 'date-fns/locale/pt/index')
    case 'ro':
      return import(/* webpackChunkName: "datefns-locale-ro" */ 'date-fns/locale/ro/index')
    case 'ru':
      return import(/* webpackChunkName: "datefns-locale-ru" */ 'date-fns/locale/ru/index')
    case 'sq':
      return import(/* webpackChunkName: "datefns-locale-sq" */ 'date-fns/locale/sq/index')
    case 'sr-Latn':
      return import(/* webpackChunkName: "datefns-locale-sr-Latn" */ 'date-fns/locale/sr-Latn/index')
    case 'tr':
      return import(/* webpackChunkName: "datefns-locale-tr" */ 'date-fns/locale/tr/index')
    case 'zh-CN':
      return import(/* webpackChunkName: "datefns-locale-zh-CN" */ 'date-fns/locale/zh-CN/index')
  }
}

export function loadDateFnsLocaleAsync(languageId) {
  let localeFolder = languageIdToLocaleFolder[languageId]
  if (!localeFolder) {
    localeFolder = defaultLocaleFolder
  }
  if (localeFolder in loadedLocales) {
    return Promise.resolve()
  }
  return webpackImportByFolder(localeFolder).then(
    (messages) => {
      loadedLocales[localeFolder] = messages.default
    },
  )
}

export default function getDateFnsLocale() {
  let localeFolder = languageIdToLocaleFolder[store.getters['auth/locale']]
  if (!localeFolder) {
    localeFolder = defaultLocaleFolder
  }
  if (!(localeFolder in loadedLocales)) {
    return loadedLocales[defaultLocaleFolder]
  }
  return loadedLocales[localeFolder]
}
