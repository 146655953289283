<template>
  <div
    class="c-input__wrapper"
    data-cy="UserSearch">
    <SvgIcon
      v-if="user"
      type="checkmark_circle"
      class="c-input__icon -pre u-colorPrimary"
      @click="focus" />
    <v-autocomplete
      ref="autocomplete"
      v-model="user"
      :items="users"
      :get-label="getLabel"
      :component-item="template"
      :placeholder="placeholder"
      :input-class="[
        'c-input',
        '-fullwidth',
        '-hasPostIcon',
        user ? '-hasPreIcon' : '',
      ].join(' ')"
      :min-len="2"
      @update-items="updateItems"
      @input="handleInput" />
    <button
      v-if="!user"
      class="o-unstyled"
      @click="focus">
      <SvgIcon
        type="search"
        class="c-input__icon"
        data-cy="UserSearch__searchIcon" />
    </button>
    <button
      v-else
      class="o-unstyled"
      @click="clear">
      <SvgIcon
        type="input_delete_content"
        class="c-input__icon"
        data-cy="UserSearch__inputDeleteIcon" />
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import ItemTemplate from '@/components/UserSearchResult.vue'
import UsersAPI from '@/modules/users/api/users'

export default {
  props: {
    checkConcurrentGames: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      user: null,
      users: [],
      template: ItemTemplate,
    }
  },
  computed: {
    ...mapGetters({
      config: 'auth/config',
    }),
    placeholder() {
      if (this.config.settings.hide_emails_frontend) {
        return this.$t('generic.user_search_placeholder_wo_email')
      }
      return this.$t('generic.user_search_placeholder')
    },
  },
  methods: {
    getLabel(item) {
      if (!item) {
        return null
      }
      return item.displayname
    },
    updateItems(text) {
      UsersAPI.findUsers(text, this.checkConcurrentGames ? 1 : 0).then((users) => {
        this.users = users
      })
    },
    focus() {
      this.$refs.autocomplete.$el.getElementsByClassName('c-input')[0].focus()
    },
    clear() {
      this.user = null
      this.$refs.autocomplete.searchText = ''
      this.focus()
    },
    handleInput() {
      this.$emit('input', this.user)
    },
  },
}
</script>
