export default [
  {
    component: () => import('@/modules/competitions/views/CompetitionDisplay.vue'),
    name: 'details',
    path: '/competitions/:id',
    meta: {
      requiresModule: 'competitions',
    },
  },
]
