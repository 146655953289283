var render = function render(_c,_vm){return _c('router-link',{staticClass:"c-card -clickable",attrs:{"tag":"a","to":{
    name: 'competitions.details',
    params: {
      id: _vm.props.competition.id,
    },
  }}},[_c('div',{staticClass:"c-card__content"},[_c('div',{staticClass:"o-flex"},[_c('img',{staticClass:"u-marginRight4",attrs:{"src":"/img/placeholder.svg"}}),_c('div',{staticClass:"o-flex__item -grow"},[_c('div',{staticClass:"o-flex -row -spaceBetween"},[(!_vm.props.competition.hasEnded)?_c('div',{staticClass:"u-colorPrimary t-caption1"},[_vm._v(" "+_vm._s(_vm.parent.$t('generic.from'))+" "+_vm._s(_vm._f("date")(_vm.props.competition.start))+" ")]):_c('div',{staticClass:"u-colorDarkMediumEmphasis t-caption1"},[_vm._v(" "+_vm._s(_vm.parent.$t('competitions.ended'))+" ")])]),_c('div',{staticClass:"body3"},[_vm._v(_vm._s(_vm.props.competition.title))]),_c('div',{staticClass:"t-caption1 u-colorDarkMediumEmphasis"},[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.props.competition.description))+" ")])]),_c('div',{staticClass:"t-alignRight"},[(_vm.parent.$store.getters['auth/config'].settings.enable_social_features)?_c('div',{staticClass:"o-flex -alignCenter"},[_c('SvgIcon',{class:{
              'u-colorDarkLightEmphasis': !_vm.props.competition.likes_it,
              'u-colorLove': _vm.props.competition.likes_it,
            },attrs:{"type":_vm.props.competition.likes_it ? 'like_filled' : 'like_outline',"medium":""}}),_c('span',{staticClass:"t-fontWeightMedium t-caption1 u-colorDarkLightEmphasis u-marginLeft1"},[_vm._v(" "+_vm._s(_vm.props.competition.likes_count)+" ")])],1):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }