import Vue from 'vue'

import BackLink from '@/components/BackLink.vue'
import CurrentUserAvatar from '@/components/CurrentUserAvatar.vue'
import DropdownSelect from '@/components/DropdownSelect.vue'
import ExternalA from '@/components/ExternalA.vue'
import GenericModal from '@/components/GenericModal.vue'
import LanguageSelector from '@/components/LanguageSelector.vue'
import SlideOnMobile from '@/components/SlideOnMobile.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import UserSearch from '@/components/UserSearch.vue'
import SidebarProfile from '@/layouts/partials/SidebarProfile.vue'

export default function () {
  Vue.component('SvgIcon', SvgIcon)
  Vue.component('GenericModal', GenericModal)
  Vue.component('SidebarProfile', SidebarProfile)
  Vue.component('SlideOnMobile', SlideOnMobile)
  Vue.component('UserSearch', UserSearch)
  Vue.component('BackLink', BackLink)
  Vue.component('DropdownSelect', DropdownSelect)
  Vue.component('LanguageSelector', LanguageSelector)
  Vue.component('CurrentUserAvatar', CurrentUserAvatar)
  Vue.component('ExternalA', ExternalA)
}
