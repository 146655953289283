import api from '@/logic/api'

export default {
  getAdvertisements() {
    const appId = api.store.getters['auth/appId']
    return api.get('/visual-highlights', {
      appId,
    })
  },
}
