export default {
  setAppointments(state, appointments) {
    state.appointments = appointments
  },
  setRegistered(state, appointmentId) {
    if (!state.appointments) {
      // eslint-disable-next-line
      console.error('no appointments loaded')
      return
    }
    const appointment = state.appointments[parseInt(appointmentId)]
    if (!appointment) {
      // eslint-disable-next-line
      console.error('appointment not found')
      return
    }

    appointment.participants_count++
    appointment.user_participates = true
  },
  setUnregistered(state, appointmentId) {
    if (!state.appointments) {
      // eslint-disable-next-line
      console.error('no appointments loaded')
      return
    }
    const appointment = state.appointments[parseInt(appointmentId)]
    if (!appointment) {
      // eslint-disable-next-line
      console.error('appointment not found')
      return
    }

    appointment.participants_count--
    appointment.user_participates = false
  },
}
