import Vue from 'vue'

export default {
  setAllCompetitions(state, competitions) {
    state.competitions = competitions
  },
  setRequiredCredentials(state, credentials) {
    state.requiredCredentials = credentials
  },
  setCompetition(state, competition) {
    if (!state.competitions) {
      state.competitions = {}
    }
    Vue.set(state.competitions, competition.id, competition)
  },
}
