import KeywordsAPI from '@/modules/keywords/api/keywords'

export default {
  updateKeywords({ commit, rootGetters }) {
    if (!rootGetters['auth/isLoggedIn']) {
      commit('setAllKeywords', [])
      return Promise.resolve()
    }
    return KeywordsAPI.getKeywords().then((data) => {
      const keywords = data.keywords.sort((a, b) => b.name.length - a.name.length)
      commit('setAllKeywords', keywords)
    })
  },
  updateDescriptions({ commit, rootGetters }, keywords) {
    if (!rootGetters['auth/isLoggedIn']) {
      return Promise.resolve()
    }
    return KeywordsAPI.getDescriptions(keywords).then((data) => {
      data.keywords.forEach((keyword) => {
        commit('setKeywordDescription', {
          keywordId: keyword.id,
          description: keyword.description,
        })
      })
    })
  },
}
