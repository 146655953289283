import {
  format, utcToZonedTime, zonedTimeToUtc,
} from 'date-fns-tz'

import BaseAPI from '@/logic/api'
import LocalPowerlearning from '@/modules/powerlearning/api/localPowerlearning'
import PowerlearningAPI from '@/modules/powerlearning/api/powerlearning'

let onlineSyncEnabled = false

export default {
  store: null,
  syncingPromise: null,
  /**
   * This method is called once when the app is initialized
   */
  setup(store) {
    this.store = store
    if (!onlineSyncEnabled) {
      onlineSyncEnabled = true
      // Sync the data when the device gets online
      window.addEventListener('online', () => {
        this.sync()
      })
    }
    // Initial sync when nothing else is left to do
    if ('requestIdleCallback' in window) {
      window.requestIdleCallback(() => {
        this.sync()
      }, {
        timeout: 5000,
      })
    } else {
      this.sync()
    }
  },
  /**
   * This method is called whenever we want to sync the current state
   */
  sync() {
    if (this.syncingPromise) {
      return this.syncingPromise
    }
    if (!this.store.getters['auth/isLoggedIn']) {
      return Promise.resolve()
    }
    this.syncingPromise = LocalPowerlearning.submitAnswers().then(() => {
      try {
        return PowerlearningAPI.getLearningData().then((data) => this.updateLocalData(data).then(() => {
          this.setSyncSuccessful(true)
        }).catch(() => {
          this.setSyncSuccessful(false)
        }).finally(() => {
          this.syncingPromise = null
        }))
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
        this.setSyncSuccessful(false)
        this.syncingPromise = null
        return Promise.reject()
      }
    })
    return this.syncingPromise
  },
  /**
   * Fetch the latest data from the server and save it to indexedDB
   *
   * @param {any} remoteData
   */
  updateLocalData(remoteData) {
    // We don't precache media currently. We might let the user do that manually later on
    return Promise.all([
      // this.precacheMedia(remoteData),
      LocalPowerlearning
        .getDB()
        .transaction('rw', ['questions', 'categories', 'categorygroups', 'settings'], () => {
          this.updateDatabase(remoteData).catch((e) => {
            // eslint-disable-next-line no-console
            console.log(e)
          })
        }),
    ])
  },
  setSyncSuccessful(wasSuccessful) {
    this.store.commit('powerlearning/setIsSyncedSuccessfully', wasSuccessful)
  },
  getTimezone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  },
  /**
   * Updates the indexedDB with the remote data
   *
   * @param {any} remoteData
   */
  updateDatabase(remoteData) {
    const db = LocalPowerlearning.getDB()
    // It's important to use window.Promise here, because Dexie replaces it with it's own implementation
    return new window.Promise((resolve, reject) => {
      window.Promise.all([db.questions.clear(), db.categories.clear(), db.categorygroups.clear(), db.settings.clear()]).then(() => {
        const inserts = []

        const categoriesById = {}
        for (let i = 0; i < remoteData.categoriesLength(); i++) {
          const category = remoteData.categories(i)
          categoriesById[category.id()] = {
            active: category.active(),
            categorygroup_id: category.categorygroupId(),
            color: category.color(),
            created_at: category.createdAt(),
            icon_url: category.iconUrl(),
            id: category.id(),
            image_url: category.imageUrl(),
            name: category.name(),
            points: category.points(),
            updated_at: category.updatedAt(),
            last_answered_at: 0,
          }
        }

        const categoryGroups = []
        for (let i = 0; i < remoteData.categoryGroupsLength(); i++) {
          const categoryGroup = remoteData.categoryGroups(i)
          categoryGroups.push({
            created_at: categoryGroup.createdAt(),
            id: categoryGroup.id(),
            name: categoryGroup.name(),
            updated_at: categoryGroup.updatedAt(),
          })
        }

        const questions = []
        for (let i = 0; i < remoteData.questionsLength(); i++) {
          const question = remoteData.questions(i)
          // Convert from our server side timezone to the users local zone
          const enteredAtUTC = zonedTimeToUtc(question.boxEnteredAt(), 'Europe/Berlin')
          const enteredAtLocal = utcToZonedTime(enteredAtUTC, this.getTimezone())
          const questionData = {
            box: question.box(),
            box_entered_at: parseInt(format(enteredAtLocal, 'T'), 10),
            category_id: question.categoryId(),
            difficulty: question.difficulty(),
            id: question.id(),
            title: question.title(),
            type: question.type(),
            answers: [],
            attachments: [],
            updated: 0,
            category: categoriesById[question.categoryId()].name,
          }
          if (questionData.box > 0) {
            categoriesById[questionData.category_id].last_answered_at = Math.max(questionData.box_entered_at, categoriesById[questionData.category_id].last_answered_at || 0)
          }

          if (question.answersLength() > 0) {
            for (let j = 0; j < question.answersLength(); j++) {
              const answer = question.answers(j)
              questionData.answers.push({
                content: answer.content(),
                correct: answer.correct(),
                feedback: answer.feedback(),
                id: answer.id(),
                language: answer.language(),
                question_id: answer.questionId(),
              })
            }
          }
          if (question.attachmentsLength() > 0) {
            for (let k = 0; k < question.attachmentsLength(); k++) {
              const attachment = question.attachments(k)
              const attachmentData = {
                id: attachment.id(),
                question_id: attachment.questionId(),
                type: attachment.type(),
                url: attachment.url(),
                attachment_url: attachment.attachmentUrl(),
                labels: [],
                is_bunny_video: attachment.isBunnyVideo(),
              }
              for (let ll = 0; ll < attachment.labelsLength(); ll++) {
                const label = attachment.labels(ll)
                attachmentData.labels.push({
                  text: label.text(),
                  left: label.left(),
                  top: label.top(),
                })
              }
              questionData.attachments.push(attachmentData)
            }
          }
          questions.push(questionData)
        }
        const settings = []
        for (let i = 0; i < remoteData.settingsLength(); i++) {
          const setting = remoteData.settings(i)
          settings.push({
            key: setting.key(),
            value: setting.value(),
          })
        }

        // Insert questions
        inserts.push(db.questions.bulkPut(questions))
        // Insert categories
        inserts.push(db.categories.bulkPut(Object.values(categoriesById)))
        // Insert category groups
        inserts.push(db.categorygroups.bulkPut(categoryGroups))
        // Insert settings
        inserts.push(db.settings.bulkPut(settings))

        window.Promise.all(inserts).then(() => {
          resolve()
        }).catch(reject)
      }).catch(reject)
    })
  },
  /**
   * Saves all media for offline use
   *
   * @param {any} remoteData
   */
  precacheMedia(remoteData) {
    const db = LocalPowerlearning.getDB()
    const media = []
    for (let i = 0; i < remoteData.questionsLength(); i++) {
      const question = remoteData.questions(i)
      if (question.attachmentsLength() > 0) {
        for (let j = 0; j < question.attachmentsLength(); j++) {
          const attachment = question.attachments(j)
          if (attachment.type() === 0 || attachment.type() === 1) {
            media.push(attachment.url())
          }
        }
      }
    }
    for (let i = 0; i < remoteData.categoriesLength(); i++) {
      const category = remoteData.categories(i)
      if (category.iconUrl()) {
        media.push(category.iconUrl())
      }
    }
    if (media.length === 0) {
      return Promise.resolve()
    }
    const promises = []
    media.forEach((url) => {
      promises.push(new Promise((resolve, reject) => {
        db.media.get(url).then((mediaEntry) => {
          if (!mediaEntry) {
            this.saveMedia(url).then(resolve, reject)
          } else {
            resolve()
          }
        }).catch(reject)
      }))
    })
    return Promise.all(promises)
  },
  fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.addEventListener('load', () => {
        resolve(reader.result)
      })
      reader.addEventListener('error', (error) => {
        reject(error)
      })
    })
  },
  saveMedia(url, attempt) {
    const db = LocalPowerlearning.getDB()
    if (typeof attempt === 'undefined') {
      attempt = 0
    }
    return new Promise((resolve, reject) => {
      BaseAPI.getFile(url).then((blob) => {
        this.fileToBase64(blob).then((data) => {
          db.media.put({
            url,
            image: data,
          }).then(resolve, reject)
        }).catch(reject)
      }).catch(() => {
        if (attempt >= 2) {
          // The download failed too often, abort
          reject()
          return
        }
        // Try again after 0.5 - 1s
        // We try again after some time, because the connection might have been interrupted
        window.setTimeout(() => {
          this.saveMedia(url, attempt + 1).then(resolve, reject)
        }, 500 + (Math.random() * 500))
      })
    })
  },
  getMediaURL(url) {
    const db = LocalPowerlearning.getDB()
    return new Promise((resolve) => {
      db.media.get(url).then((media) => {
        if (media) {
          if (typeof media.image === 'string') {
            // Here we stored a base64 representation of the image (which is now the default, since iOS >= 11 doesn't support file blobs in indexeddb)
            resolve(media.image)
          } else {
            // Here we stored a blob (which isn't supported in iOS >= 11)
            resolve(URL.createObjectURL(media.image))
          }
        } else {
          resolve(url)
        }
      }).catch(() => {
        resolve(url)
      })
    })
  },
}
