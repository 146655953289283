<template>
  <router-link
    :to="appointment.route || {
      name: 'appointments.details',
      params: {
        appointmentId: appointment.id,
      },
    }"
    :class="{
      '-important': !!appointment.important,
    }"
    class="c-card -clickable"
    data-cy="AppointmentCard">
    <div class="c-card__content">
      <AppointmentEntry :appointment="appointment" />
    </div>
  </router-link>
</template>

<script>
import AppointmentEntry from '@/modules/appointments/components/AppointmentEntry.vue'

export default {
  props: {
    appointment: {
      type: Object,
      required: true,
    },
  },
  components: {
    AppointmentEntry,
  },
}
</script>
