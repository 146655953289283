export default {
  isOffline: false,
  isMenuOpen: false,
  isLoading: false,
  installPromptEvent: null,
  updateAvailable: false, // It's important that this is false when the app starts, otherwise we would reload everytime the user clicks a link
  screenSize: {
    lt: {
      smartphone: false,
      tabletMini: false,
      tabletPortrait: false,
      tableLandscape: false,
      desktopSmall: false,
      desktopLarge: false,
      desktopXlarge: false,
      desktopXxlarge: false,
    },
    gt: {
      smartphone: false,
      tabletMini: false,
      tabletPortrait: false,
      tableLandscape: false,
      desktopSmall: false,
      desktopLarge: false,
      desktopXlarge: false,
      desktopXxlarge: false,
    },
  },
}
