<template>
  <div
    v-if="config.settings.module_comments"
    class="o-flex -alignCenter u-colorDarkMediumEmphasis"
    data-cy="CommentCount">
    <SvgIcon
      type="comment_outline"
      :class="{
        's-negativeMarginLeft': !noOffset,
      }"
      data-cy="CommentCount__icon"
      :large="small"
      :medium="tiny" />
    <span
      class="t-fontWeightMedium"
      :class="{
        't-caption1': small || tiny,
        'u-marginLeft1': small || tiny,
      }">
      {{ count }}
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    count: {
      type: Number,
      required: false,
      default: 0,
    },
    noOffset: {
      type: Boolean,
      required: false,
      default: false,
    },
    small: {
      required: false,
      type: Boolean,
      default: false,
    },
    tiny: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      config: 'auth/config',
    }),
  },
}
</script>

<style lang="scss" scoped>
.s-negativeMarginLeft {
  margin-left: -10%;
}
</style>
