module.exports = {
  submit: 'Feedback & Bewertung senden',
  feedback_is_send_to: 'Dein Feedback wird an %{appName} gesendet.',
  contact: 'Kontakt',
  your_feedback: 'Dein Feedback',
  rating: 'Bewertung',
  thanks_for_rating_us: 'Vielen Dank für dein Feedback!',
  we_will_consider_it: 'Wir werden uns dein Feedback in Ruhe ansehen und berücksichtigen!',
  title: 'Feedback / App bewerten',
  title_item_feedback: 'Frage / Antwort melden',
  submit_item_feedback: 'Senden',
  your_feedback_item_feedback: 'Deine Nachricht',
  description_item_feedback: 'Bitte teile uns dein Feedback zu diesem Inhalt mit. Wenn du möchtest, kannst du auch gerne Links zu entsprechenden Nachweisen hinzufügen.',
  feedback_item: {
    text: 'Du möchtest uns etwas mitteilen?',
    link: 'Schick uns dein Feedback >',
  },
}
