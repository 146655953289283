export default [
  {
    component: () => import('@/modules/auth/views/SelectApp.vue'),
    name: 'select-app',
    path: '/auth/select-app',
    meta: {
      layout: 'Authentication',
      requiresAuth: false,
    },
  },
  {
    component: () => import('@/modules/auth/views/LoginPanel.vue'),
    name: 'login',
    path: '/auth/login/:status?/:message?',
    meta: {
      layout: 'Authentication',
      requiresAuth: false,
      divided: true,
    },
  },
  {
    component: () => import('@/modules/auth/views/OpenId.vue'),
    name: 'openid',
    path: '/auth/openid/:loginToken',
    meta: {
      layout: 'Authentication',
      requiresAuth: false,
    },
  },
  {
    component: () => import('@/modules/auth/views/LogoutProcessing.vue'),
    name: 'logout',
    path: '/auth/logout',
    meta: {
      requiresAuth: true,
      layout: 'Authentication',
    },
  },
  {
    component: () => import('@/modules/auth/views/SignupPanel.vue'),
    name: 'signup',
    path: '/auth/signup',
    meta: {
      layout: 'Authentication',
      requiresAuth: false,
      divided: true,
    },
  },
  {
    component: () => import('@/modules/auth/views/TestaccountSignupPanel.vue'),
    name: 'testaccount',
    path: '/auth/testaccount',
    meta: {
      layout: 'Authentication',
      requiresAuth: false,
      divided: true,
    },
  },
  {
    component: () => import('@/modules/auth/views/ResetPassword.vue'),
    name: 'resetpassword',
    path: '/auth/reset-password',
    meta: {
      layout: 'Authentication',
      requiresAuth: false,
      divided: true,
    },
  },
  {
    component: () => import('@/modules/auth/views/ResetPasswordSuccess.vue'),
    name: 'resetpasswordsuccess',
    path: '/auth/reset-password-success',
    meta: {
      layout: 'Authentication',
      requiresAuth: false,
      divided: false,
    },
  },
  {
    component: () => import('@/modules/auth/views/TermsOfService.vue'),
    name: 'tos',
    path: '/auth/tos',
    meta: {
      layout: 'Authentication',
      requiresAuth: true,
    },
  },
  {
    component: () => import('@/modules/auth/views/ActivateAccount.vue'),
    name: 'activate',
    path: '/auth/activate',
    meta: {
      layout: 'Authentication',
      requiresAuth: true,
    },
  },
  {
    component: () => import('@/modules/auth/views/ForcePasswordReset.vue'),
    name: 'forcepasswordreset',
    path: '/auth/force-password-reset',
    meta: {
      layout: 'Authentication',
      requiresAuth: true,
    },
  },
  {
    component: () => import('@/modules/auth/views/InternalSSOProcessing.vue'),
    name: 'internalsso',
    path: '/auth/internal-sso/:token',
    meta: {
      layout: 'Authentication',
      requiresAuth: false,
    },
  },
]
