export default [
  {
    component: () => import('@/modules/feedback/views/FeedbackCreate.vue'),
    name: 'create',
    path: '/feedback',
  },
  {
    component: () => import('@/modules/feedback/views/ItemFeedback.vue'),
    name: 'create-for-item',
    path: '/feedback/:type/:id',
  },
  {
    component: () => import('@/modules/feedback/views/FeedbackSent.vue'),
    name: 'sent',
    path: '/feedbackSent',
  },
]
