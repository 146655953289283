export default {
  allKeywords(state) {
    return state.keywords
  },
  descriptionExists(state) {
    return (keywordId) => {
      const keyword = state.keywords.find((entry) => entry.id === keywordId)
      return keyword.description !== undefined
    }
  },
  selectedKeywordId(state) {
    return state.selectedKeywordId
  },
}
