import getI18n from '@/logic/i18nInstance'
import { getServiceworker } from '@/logic/init/registerServiceWorker'

export function setColors(colors) {
  const root = document.documentElement
  Object.keys(colors).forEach((colorName) => {
    Object.keys(colors[colorName]).forEach((colorPart) => {
      root.style.setProperty(`--${colorName}-${colorPart}`, colors[colorName][colorPart])
    })
  })
}

export function setName(name) {
  document.title = name
}

export function applyConfig(config) {
  const serviceworker = getServiceworker()
  if (serviceworker) {
    serviceworker.postMessage({
      event: 'setConfig',
      data: config,
    })

    window.setTimeout(() => {
      // Reload svgs
      Array.from(document.querySelectorAll('img[src*=".svg"]')).forEach((img) => {
        img.src = `${img.src}?${(new Date()).getTime()}`
      })
    }, 500)
  }

  // Set css variables
  setColors(config.colors)

  // Set meta info
  setName(config.name)
  document.querySelector('meta[name="theme-color"]').setAttribute('content', `rgb(${config.colors.primary.r},${config.colors.primary.g},${config.colors.primary.b})`)
  let link = document.getElementById('favicon')
  if (!link) {
    link = document.createElement('link')
    link.rel = 'icon'
    link.id = 'favicon'
    link.type = 'image/png'
    link.sizes = '512x512'
    document.head.appendChild(link)
  }
  link.href = config.settings.app_icon
  const touchIcon = document.getElementById('touchIcon')
  touchIcon.href = config.settings.app_icon_no_transparency ? config.settings.app_icon_no_transparency : config.settings.app_icon

  document.querySelector('link[rel="manifest"]').setAttribute('href', `${process.env.VUE_APP_API_URL}/appconfig/${config.settings.slug}/manifest.webmanifest`)

  // Set translations
  if (getI18n()) {
    getI18n().mergeLocaleMessage(config.translations_language, config.translations)
  }
}
