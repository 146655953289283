import {
  format, formatRelative, parse, parseISO,
} from 'date-fns'
import linkifyHtml from 'linkify-html'
import Vue from 'vue'

import dateFnsLocale from '../dateFnsLocale'

export default function () {
  // We use the ASCII table to get a char from the index
  // 65 is the "A"
  Vue.filter('alphaLetter', (value) => String.fromCharCode(value + 65))

  Vue.filter('date', (date, inputFormat = null, outputFormat = 'dd.MM.yyyy') => {
    if (!date) {
      return ''
    }
    let inputDate = date
    if (typeof date === 'string') {
      try {
        if (!inputFormat) {
          if (date.endsWith('000000Z')) {
            inputFormat = 'iso'
          } else {
            inputFormat = 'yyyy-MM-dd HH:mm:ss'
          }
        }
        if (inputFormat === 'iso') {
          inputDate = parseISO(date)
        } else {
          inputDate = parse(date, inputFormat, new Date())
        }
      } catch {
        inputDate = parse(date, 'yyyy-MM-dd', new Date())
      }
    }
    try {
      return format(inputDate, outputFormat, {
        locale: dateFnsLocale(),
        weekStartsOn: 1,
      })
    } catch {
      return format(inputDate, 'dd.MM.yyyy')
    }
  })

  Vue.filter('linkify', (value) => linkifyHtml(value, {
    defaultProtocol: 'https',
    target: '_blank',
  }))

  Vue.filter('relativeTime', (date, inputFormat) => {
    if (!date) {
      return ''
    }
    try {
      return formatRelative(parse(date, inputFormat || 'yyyy-MM-dd HH:mm:ss', new Date()), new Date(), {
        locale: dateFnsLocale(),
        weekStartsOn: 1,
      })
    } catch {
      return date
    }
  })

  Vue.filter('truncate', (input, length) => {
    if (!input) {
      return input
    }
    if (!length) {
      length = 100
    }
    // Remove html tags
    const div = document.createElement('div')
    div.innerHTML = input
    input = div.textContent || div.innerText || ''
    const ending = '...'
    // Shorten the input if necessary
    if (input.length > length) {
      return input.substring(0, length - ending.length) + ending
    }
    return input
  })

  Vue.filter('percentage', (input, decimalDigits = 0) => {
    const operator = 10 ** decimalDigits
    return Math.round(input * operator) / operator
  })

  Vue.filter('numberFormat', (input, decimalDigits = 0) => {
    const formatter = new Intl.NumberFormat('de-DE', {
      minimumFractionDigits: decimalDigits,
      maximumFractionDigits: decimalDigits,
    })
    return formatter.format(input)
  })

  Vue.filter('getPositionColorClasses', (position) => {
    const classes = ['u-bgSoftHighlight', 'u-colorDark']
    if (position === 1) {
      classes[0] = 'u-bgGold'
      classes[1] = 'u-colorWhite'
    }
    if (position === 2) {
      classes[0] = 'u-bgSilver'
      classes[1] = 'u-colorWhite'
    }
    if (position === 3) {
      classes[0] = 'u-bgBronze'
      classes[1] = 'u-colorWhite'
    }
    return classes
  })

  Vue.filter('kb2human', (value) => {
    if (value >= 1024) {
      const valueInMb = Math.round((value / 1024) * 10) / 10
      return `${valueInMb} MB`
    }
    return `${value} KB`
  })
}
