<template>
  <div>
    <div class="o-grid -equalHeight">
      <div
        v-for="test in tests"
        :key="test.id"
        class="o-grid__cell -c12 -c6@tabletPortrait -c4@desktopSmall">
        <MiniTestCard :test="test" />
      </div>
    </div>
    <router-link
      v-if="hasMoreTests"
      :to="{ name: 'tests.dashboard' }"
      class="t-body3 u-colorPrimary u-aboveCard t-fontWeightMedium t-noLinkAppearance u-inlineBlock u-marginTop2">
      {{ $t('tests.show_all_tests') }}
      <SvgIcon
        type="chevron_right"
        tiny />
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import MiniTestCard from '@/modules/tests/components/MiniTestCard.vue'

export default {
  props: {
    oneLine: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      testsTodo: 'tests/testsTodo',
    }),
    tests() {
      if (!this.hasMoreTests) {
        return this.testsTodo
      }
      return this.testsTodo.slice(0, 3)
    },
    hasMoreTests() {
      if (!this.testsTodo) {
        return false
      }
      if (!this.oneLine) {
        return false
      }
      return this.testsTodo.length > 3
    },
  },
  components: {
    MiniTestCard,
  },
}
</script>
