<template>
  <DropdownSelect
    v-if="availableLanguages.length > 1"
    v-model="language"
    :options="availableLanguages"
    v-bind="$attrs"
    class="s-languageSelector">
    <slot :label="label">
      <div class="s-currentValue">
        <SvgIcon
          type="language"
          class="s-languageIcon" />
        {{ label }}
        <SvgIcon
          type="arrow_dropdown"
          class="s-languageDropdownIcon u-marginRight0" />
      </div>
    </slot>
  </DropdownSelect>
</template>

<script>
import { mapGetters } from 'vuex'

import { loadLanguageAsync } from '@/logic/i18n'
import profileApi from '@/modules/profile/api/profile'

export default {
  props: {
    noReload: {
      type: Boolean,
      default: false,
    },
    showAll: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      config: 'auth/config',
      isLoggedIn: 'auth/isLoggedIn',
    }),
    availableLanguages() {
      if (this.showAll) {
        return this.$constants.LANGUAGES
      }
      return this.$constants.LANGUAGES.filter((language) => this.config.languages.includes(language.id))
    },
    language: {
      get() {
        return this.$store.getters['auth/locale']
      },
      set(language) {
        const languagePromises = [
          loadLanguageAsync(language),
          this.$store.commit('auth/setLocaleManuallySelected', true),
        ]
        if (this.isLoggedIn) {
          languagePromises.push(profileApi.setLanguage(language))
        }
        Promise.all(languagePromises).then(() => {
          if (!this.noReload) {
            window.location.reload()
          }
        })
      },
    },
    label() {
      if (this.language === 'de_formal') {
        return 'DE (Sie)'
      }
      return this.language.toUpperCase()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/config';

.s-languageSelector::v-deep .c-dropdown {
  overflow-y: auto;
}

.s-currentValue {
  white-space: nowrap;
  cursor: pointer;
}

.s-languageIcon {
  margin-right: $spacing-2;
}

.s-languageDropdownIcon {
  font-size: 18px;
  height: auto;
  margin-left: -8px;
}
</style>
