import { addDays, isBefore } from 'date-fns'

import constants from '@/constants'
import DateManager from '@/logic/DateManager'

export default {
  tests(state) {
    return state.tests
  },
  findTest(state) {
    if (!state.tests.length) {
      return () => null
    }
    return (id) => state.tests.find((test) => test.id === parseInt(id, 10))
  },
  findDetails(state) {
    return (id) => state.testDetails[parseInt(id, 10)]
  },
  findSubmission(state) {
    return (id) => state.submissions[parseInt(id, 10)]
  },
  testsTodo(state, getters) {
    if (!getters.tests) {
      return getters.tests
    }
    const tests = [...getters.tests]
    function getLatestSubmissionDate(test) {
      if (!test.submissions.length) {
        return null
      }
      return test.submissions.reduce((latestDate, submission) => {
        if (submission.created_at > latestDate) {
          return submission.created_at
        }
        return latestDate
      }, '1970-01-01 00:00:00')
    }
    return tests.filter((test) => test.submissionAvailable && !test.hasPassed).sort((a, b) => {
      const submissionDateA = getLatestSubmissionDate(a)
      const submissionDateB = getLatestSubmissionDate(b)
      if (submissionDateA && submissionDateB) {
        return submissionDateB.localeCompare(submissionDateA)
      }
      if (submissionDateA) {
        return -1
      }
      if (submissionDateB) {
        return 1
      }
      return b.created_at.localeCompare(a.created_at)
    })
  },
  visibleTests(state, getters) {
    if (!getters.tests) {
      return getters.tests
    }
    let tests = [...getters.tests]

    if (state.subFilters.length) {
      const now = DateManager.now()
      const in5Days = addDays(DateManager.now(), 5)
      const filterFunctions = {
        [constants.TESTS.FILTER_PASSED](test) {
          return test.hasPassed
        },
        [constants.TESTS.FILTER_NOT_PASSED](test) {
          return !test.hasPassed
        },
        [constants.TESTS.FILTER_EXPIRES_SOON](test) {
          return test.active_until && isBefore(now, test.active_until) && isBefore(test.active_until, in5Days)
        },
        [constants.TESTS.FILTER_HAS_CERTIFICATE](test) {
          return test.has_certificate
        },
      }
      state.subFilters.forEach((filter) => {
        tests = tests.filter(filterFunctions[filter])
      })
    }

    if (!state.searchQuery) {
      if (state.filter === constants.TESTS.FILTER_AVAILABLE) {
        tests = tests.filter((test) => test.submissionAvailable)
      }
      if (state.filter === constants.TESTS.FILTER_UNAVAILABLE) {
        tests = tests.filter((test) => !test.submissionAvailable)
      }
    }

    if (state.searchQuery) {
      const query = state.searchQuery.toLowerCase()
      tests = tests.filter((test) => {
        if (test.name.toLowerCase().indexOf(query) !== -1) {
          return true
        }
        return false
      })
    }

    tests.sort((a, b) => {
      let descMultiplicator = 1
      if ([constants.TESTS.SORT_NAME_DESC, constants.TESTS.SORT_PUBLISHED_AT_DESC].includes(state.sort)) {
        descMultiplicator = -1
      }
      let field = 'name'
      if ([constants.TESTS.SORT_PUBLISHED_AT_DESC, constants.TESTS.SORT_PUBLISHED_AT_ASC].includes(state.sort)) {
        field = 'created_at'
      }
      return (a[field] || '').localeCompare(b[field], undefined, { numeric: true }) * descMultiplicator
    })

    return tests
  },
  filter(state) {
    return state.filter
  },
  subFilters(state) {
    return state.subFilters
  },
  sort(state) {
    return state.sort
  },
  searchQuery(state) {
    return state.searchQuery
  },
  searchOpen(state) {
    return state.searchOpen
  },
  isFiltered(state, getters) {
    return getters.subFilters.length || getters.searchQuery
  },
}
