module.exports = {
  box_3_title: '3-mal-richtig-Box (gelernt)',
  box_2_title: '2-mal-richtig-Box',
  box_1_title: '1-mal-richtig-Box',
  box_0_title: 'Frische Fragen Box',
  how_does_it_work: 'Wie funktioniert das Powerlearning?',
  question_learned: 'Super. Frage gelernt!',
  go_to_powerlearning: 'Zum Powerlearning',
  learning: {
    congratulations: 'Glückwunsch!',
    all_questions_done: 'Du hast alle Fragen in dieser Box bearbeitet.',
  },
  to_box_overview: 'Zur Box-Übersicht',
  currently_no_questions: 'Für diese Kategorie befinden sich im Moment keine Fragen in der Box. Beantworte die Fragen in den darunter liegenden Boxen um Fragen auf das nächste Level zu bringen:',
  explanation1: 'Im Trainingsmodus muss eine Frage drei mal richtig beantwortet werden, um als "gelernt" zu gelten.',
  explanation2: 'Das System stellt nur Fragen, die noch nicht als gelernt markiert sind. Es gelten keine zeitlichen Limits oder Beschränkungen.',
  explanation3: "Wird eine Frage falsch beantwortet, wird die Frage wieder in die 'Frische Fragen Box' einsortiert",
  how_does_it_work_short: 'Wie funktioniert Powerlearning?',
  intro_text: 'Powerlearning verstärkt dein Wissen durch mehrfache Wiederholung der Lerninhalte.',
  choose_box: 'Box wählen',
  close: 'Powerlearning beenden',
  n_questions: '1 Frage | %{n} Fragen',
  n_questions_remaining: '1 Frage übrig | %{n} Fragen übrig',
  n_questions_learned: '1 gelernte Frage | %{n} gelernte Fragen',
  n_questions_remaining_in_box: 'noch 1 Frage in dieser Box | noch %{n} Fragen in dieser Box',
  n_questions_in_box: '1 Frage in dieser Box | %{n} Fragen in dieser Box',
  x_of_y_questions_learned: '%{x} von %{y} Fragen gelernt',
}
