import api from '@/logic/api'

export default {
  /**
   * Returns all learning data
   * @returns App.Flatbuffers.Learningdata
   */
  getLearningData: () => {
    const learningdataPromise = api.getFlatbuffer('/learning')
    const flatbuffersConfigPromise = import('@/modules/powerlearning/api/flatbuffers/learningdata_generated')
    return Promise.all([
      learningdataPromise,
      flatbuffersConfigPromise,
    ]).then(([learningdata, flatbuffersConfig]) => {
      try {
        learningdata = flatbuffersConfig.Learningdata.getRootAsLearningdata(learningdata)
      } catch (e) {
        alert(e)
      }
      return learningdata
    })
  },
  saveLearningData: (data) => api.post('/learning/save', { data }),
}
