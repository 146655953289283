import { isBefore, parseISO } from 'date-fns'

import DateManager from '@/logic/DateManager'
import TestsAPI from '@/modules/tests/api/tests'

function submissionAvailable(test) {
  if (test.active_until && isBefore(parseISO(test.active_until), DateManager.now())) {
    return false
  }
  if (!test.repeatable_after_pass && test.hasPassed) {
    return false
  }

  if (test.submissionsLeft === 0) {
    return false
  }
  return true
}

function submissionsLeft(test) {
  if (!test.attempts) {
    return null
  }
  const completedSubmissionCount = test.submissions.reduce((prev, submission) => {
    if (submission.result !== null) {
      prev += 1
    }
    return prev
  }, 0)
  return test.attempts - completedSubmissionCount
}

function mapTest(test) {
  test.hasPassed = test.submissions.find((submission) => submission.result === 1)
  test.submissionsLeft = submissionsLeft(test)
  test.submissionAvailable = submissionAvailable(test)

  return test
}

export default {
  updateTests({ commit }) {
    return TestsAPI.getTests().then((data) => {
      const tests = data.tests.map(mapTest)
      commit('setTests', tests)
    })
  },
  updateTestDetails({ commit }, id) {
    return TestsAPI.getTestDetails(id).then((details) => {
      commit('setTestDetails', { id, details })
    })
  },
  updateSubmission({ commit }, id) {
    return TestsAPI.getSubmission(id).then((submission) => {
      commit('setSubmission', { id, submission })
    })
  },
}
