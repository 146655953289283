export default {
  challengingQuestions: (state) => state.challengingQuestions,
  myQuizStats: (state) => state.myQuizStats,
  topPlayers: (state) => state.topPlayers,
  topTeams: (state) => state.topTeams,
  top3Players(state) {
    if (!state.topPlayers) {
      return state.topPlayers
    }
    return state.topPlayers.slice(0, 2)
  },
  top3Teams(state) {
    if (!state.topTeams) {
      return state.topTeams
    }
    return state.topTeams.slice(0, 2)
  },
}
