export default {
  activeTodolists(state) {
    if (!state.todolists) {
      return state.todolists
    }
    return state.todolists.filter((todolist) => !todolist.meta.finished)
  },
  finishedTodolists(state) {
    if (!state.todolists) {
      return state.todolists
    }
    return state.todolists.filter((todolist) => !!todolist.meta.finished)
  },
  getItemAnswer(state) {
    if (!state.itemAnswers) {
      return () => null
    }
    return (itemId) => !!state.itemAnswers[itemId]
  },
  getTodolist(state) {
    if (!state.todolists) {
      return () => null
    }
    return (todolistId) => state.todolists.find((todolist) => todolist.id === todolistId)
  },
  todolists(state) {
    return state.todolists
  },
}
