export default async function (store) {
  if (store.getters['auth/isLoggedIn']) {
    return
  }
  // See if we have an auth token from the old (pre 2020) app and use that to update the profile data and login the user
  const oldToken = window.localStorage.getItem('api_token')
  if (oldToken) {
    try {
      store.commit('auth/setToken', oldToken)
      await store.dispatch('auth/updateProfile')
    } catch (e) {
      store.dispatch('auth/logout')
    }
  }
}
