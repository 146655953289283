<template>
  <div
    class="o-flex -alignCenter t-caption1 t-fontWeightMedium"
    :class="{
      'u-colorDarkLightEmphasis': !liked,
      'u-colorLove': liked,
    }"
    data-cy="LikeCount">
    <SvgIcon
      :type="liked ? 'like_filled' : 'like_outline'"
      data-cy="LikeCount__icon"
      :large="!medium"
      :medium="medium" />
    <span class="u-marginLeft1">
      {{ count }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    count: {
      required: true,
      type: Number,
    },
    liked: {
      default: false,
      type: Boolean,
    },
    medium: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
}
</script>
