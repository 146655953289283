export default [
  {
    component: () => import('@/modules/tests/views/TestDashboard.vue'),
    name: 'dashboard',
    path: '/tests',
    meta: {
      requiresModule: 'tests',
    },
  },
  {
    component: () => import('@/modules/tests/views/TestDisplay.vue'),
    name: 'details',
    path: '/tests/:testId',
    meta: {
      requiresModule: 'tests',
    },
  },
  {
    component: () => import('@/modules/tests/views/TakeTest.vue'),
    name: 'take',
    path: '/tests/:testId/take',
    meta: {
      noMobileFooter: true,
      noMobileMenus: true,
      requiresModule: 'tests',
    },
  },
  {
    component: () => import('@/modules/tests/views/TestSubmission.vue'),
    name: 'submission',
    path: '/tests/:testId/submissions/:submissionId',
    meta: {
      requiresModule: 'tests',
    },
  },
  {
    component: () => import('@/modules/tests/views/SubmissionAnswers.vue'),
    name: 'submissionAnswers',
    path: '/tests/:testId/submissions/:submissionId/answers/:idx',
    meta: {
      requiresModule: 'tests',
    },
  },
]
