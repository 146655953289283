/**
 * Store the given target url, so that we can redirect the user there after they've logged in
 *
 * @param {string} target
 */
export function setLoginRedirect(target) {
  window.sessionStorage.setItem('login-redirect', target)
}

/**
 * Return the url where we want to redirect the user to after they've logged in
 *
 * @returns string|object
 */
export function getLoginRedirect() {
  const target = window.sessionStorage.getItem('login-redirect')
  if (target) {
    return target
  }
  return { name: 'home' }
}

/**
 * Clears the login redirect url
 */
export function resetLoginRedirect() {
  window.sessionStorage.removeItem('login-redirect')
}
