<template>
  <div>
    <div class="t-body3 u-marginBottom2 u-colorDarkLightEmphasis">{{ $t('news.news') }}</div>
    <SlideOnMobile class="u-marginTop">
      <div class="o-grid -equalHeight">
        <template v-if="latestNews !== null">
          <div
            v-for="news in latestNews"
            :key="news.id"
            class="s-newsCell o-grid__cell -c10 -c4@tabletPortrait -c3@desktopSmall">
            <NewsCard :news="news" />
          </div>
        </template>
      </div>
    </SlideOnMobile>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import NewsCard from '@/modules/news/components/NewsCard.vue'

export default {
  created() {
    this.$store.dispatch('news/updateNews')
  },
  computed: {
    ...mapGetters({
      latestNews: 'news/latestNews',
    }),
  },
  components: {
    NewsCard,
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/config';

// We only show 3 news cards on smaller desktop screens
@media screen and (min-width: #{$mq-tablet-landscape-size}) and (max-width: $mq-desktop-small-size - 1) {
  .s-newsCell:nth-child(4) {
    display: none;
  }
}
</style>
