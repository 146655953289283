export default {
  setOffline(state, isOffline) {
    state.isOffline = isOffline
  },
  setIsMenuOpen(state, isMenuOpen) {
    state.isMenuOpen = isMenuOpen
  },
  startLoading(state) {
    state.isLoading = true
  },
  stopLoading(state) {
    state.isLoading = false
  },
  setInstallPromptEvent(state, event) {
    state.installPromptEvent = event
  },
  setUpdateAvailable(state, isAvailable) {
    state.updateAvailable = isAvailable
  },
  setScreenSize(state, screenSize) {
    state.screenSize = screenSize
  },
}
