module.exports = {
  redeem: 'Einlösen',
  'redeem-voucher': 'Voucher einlösen',
  endless: 'Unbegrenzt',
  redeemed_vouchers: 'Bereits eingelöste Voucher-Codes',
  valid_until: 'Gültig bis:',
  redeemed_at: 'Eingelöst: %{date}',
  please_enter_code: 'Bitte gebe einen Voucher-Code ein',
  voucher_redeemed: 'Der Voucher wurde eingelöst.',
  voucher_failed_to_redeem: 'Dein Voucher konnte leider nicht eingelöst werden',
  invalid_voucher_description: 'Bitte überprüfe die Schreibweise. Wende dich an %{adminEmail} falls es weiterhin nicht klappen sollte.',
}
