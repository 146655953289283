export default [
  {
    component: () => import('@/modules/teams/views/MyTeams.vue'),
    name: 'mine',
    path: '/teams',
    meta: {
      requiresModule: 'quiz_teams',
    },
  },
  {
    component: () => import('@/modules/teams/views/TeamDisplay.vue'),
    name: 'details',
    path: '/team/:id',
    meta: {
      requiresModule: 'quiz_teams',
    },
  },
]
