import TeamsAPI from '@/modules/teams/api/teams'

export default {
  updateMyTeams({ commit }) {
    return TeamsAPI.getMyTeams().then((data) => {
      const myTeams = {}
      data.quizTeams.forEach((team) => {
        myTeams[team.id] = team
      })
      commit('setMyTeams', myTeams)
    })
  },
  updateTeam({ commit }, teamId) {
    return TeamsAPI.getTeam(teamId).then((data) => {
      commit('setTeam', data.team)
    })
  },
}
