<template>
  <ActiveCompetitions
    v-if="hasModule.competitions"
    class="u-marginBottom3 u-marginBottom7@tabletPortrait" />
</template>

<script>
import { mapGetters } from 'vuex'

import ActiveCompetitions from '@/modules/competitions/components/ActiveCompetitions.vue'

export default {
  computed: {
    ...mapGetters({
      hasModule: 'auth/hasModule',
    }),
  },
  components: {
    ActiveCompetitions,
  },
}
</script>
